<template>
  <div class="consult-con">
    <div class="mySteps">
      <v-stepper v-model="e6" vertical>
        <v-stepper-step :complete="e6 > 1" step="1">
          选择您遇到的问题类型
          <small>我们将根据您的选择做出精准分析</small>
        </v-stepper-step>
        <div></div>
        <v-stepper-content step="1">
            <v-textarea
              clearable
              clear-icon="cancel"
              label="请针对您的问题进行具体描述"
              outlined
              solo
              no-resize
            ></v-textarea>
          <v-btn color="primary" @click="e6 = 2">下一步</v-btn>
          <!-- <v-btn text>上一步</v-btn> -->
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 2" step="2">Configure analytics for this app</v-stepper-step>

        <v-stepper-content step="2">
          <v-card color="grey lighten-1" class="mb-12" height="200px"></v-card>
          <v-btn color="primary" @click="e6 = 3">下一步</v-btn>
          <v-btn text @click="e6 = 1">上一步</v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 3" step="3">Select an ad format and name ad unit</v-stepper-step>

        <v-stepper-content step="3">
          <v-card color="grey lighten-1" class="mb-12" height="200px"></v-card>
          <v-btn color="primary" @click="e6 = 4">下一步</v-btn>
          <v-btn text @click="e6 = 2">上一步</v-btn>
        </v-stepper-content>

        <v-stepper-step step="4">View setup instructions</v-stepper-step>
        <v-stepper-content step="4">
          <v-card color="grey lighten-1" class="mb-12" height="200px"></v-card>
          <v-btn color="primary" @click="showDialog">提交</v-btn>
          <v-btn text @click="e6 = 3">上一步</v-btn>
        </v-stepper-content>
      </v-stepper>
    </div>
    <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          <v-icon color="success" style="font-size: 30px;">done</v-icon>
          提交成功！
        </v-card-title>

        <v-card-text>
          <p style="padding-top: 20px;">您的申请已提交成功，我们将第一时间做出回复。</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            好的
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    e6: 1,
    dialog: false,
  }),
  methods: {
    showDialog() {
      this.dialog = true
    },
  }
}
</script>

<style>
.consult-con {
  min-height: 500px;
  max-width: 1600px;
  margin: 0 auto;
}
.mySteps {
  padding: 30px 0;
}
</style>