<template>
  <div style="background-color: #f4f4f4;" class="mainSheet">
    <div class="account-con">
      <div class="con-left">
        <account-nav :index="3"></account-nav>
      </div>
      <div class="con-right">
        <div class="right-over">
          <v-row>
            <v-col lg="12" md="12" sm="12">
              <material-card color="secondary" icon="account_circle" inline title="个人信息">
                <div class="rcard request">
                  <v-card flat>
                    <v-divider></v-divider>
                    
                    <div class="cb">
                      <v-row>
                        <v-col lg="2" md="2" sm="12">
                          <span class="lb">姓名</span>
                        </v-col>
                        <v-col v-if="userInfo.personal">
                          {{ userInfo.personal.name }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col lg="2" md="2" sm="12">
                          <span class="lb">员工编号</span>
                        </v-col>
                        <v-col v-if="userInfo.employer">
                          {{ userInfo.employer && userInfo.employer.employeeNo ? userInfo.employer.employeeNo : '无' }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col lg="2" md="2" sm="12">
                          <span class="lb">企业</span>
                        </v-col>
                        <v-col v-if="userInfo.employer">
                          {{ userInfo.employer.ownerId.name }}
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </div>
              </material-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="12" md="12" sm="12">
              <material-card color="secondary" icon="email" inline title="电子邮箱">
              <div class="rcard request">
                <v-card flat>
                  <v-divider></v-divider>
                  <div class="cb">
                    <v-row>
                      <v-col lg="2" md="2" sm="12">
                        <span class="lb">电子邮箱</span>
                      </v-col>
                      <v-col>
                        {{ userInfo.username }}
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </div>
              </material-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="12" md="12" sm="12">
              <material-card color="secondary" icon="phone" inline title="手机号码">
              <div class="rcard request">
                <v-card flat>
                  <v-divider></v-divider>
                  
                  <div class="cb">
                    <v-row>
                      <v-col lg="2" md="2" sm="12">
                        <span class="lb">手机号码</span>
                      </v-col>
                      <v-col>
                        {{ userInfo.phone }}
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </div>
              </material-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CHECK_AUTH } from '@/store/actions.type'
import AccountNav from '@/components/accountnav'
export default {
  data: () => ({
    userInfo: '',
    headers: [
      {
        text: 'Dessert (100g serving)',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      { text: 'Calories', value: 'calories' },
      { text: 'Fat (g)', value: 'fat' },
      { text: 'Carbs (g)', value: 'carbs' },
      { text: 'Protein (g)', value: 'protein' },
      { text: 'Iron (%)', value: 'iron' },
    ],
    desserts: [
      {
        name: 'Frozen Yogurt',
        calories: 159,
        fat: 6.0,
        carbs: 24,
        protein: 4.0,
        iron: '1%',
      },
      {
        name: 'Ice cream sandwich',
        calories: 237,
        fat: 9.0,
        carbs: 37,
        protein: 4.3,
        iron: '1%',
      },
      {
        name: 'Eclair',
        calories: 262,
        fat: 16.0,
        carbs: 23,
        protein: 6.0,
        iron: '7%',
      },
      {
        name: 'Cupcake',
        calories: 305,
        fat: 3.7,
        carbs: 67,
        protein: 4.3,
        iron: '8%',
      },
      {
        name: 'Gingerbread',
        calories: 356,
        fat: 16.0,
        carbs: 49,
        protein: 3.9,
        iron: '16%',
      },
      {
        name: 'Jelly bean',
        calories: 375,
        fat: 0.0,
        carbs: 94,
        protein: 0.0,
        iron: '0%',
      },
      {
        name: 'Lollipop',
        calories: 392,
        fat: 0.2,
        carbs: 98,
        protein: 0,
        iron: '2%',
      },
      {
        name: 'Honeycomb',
        calories: 408,
        fat: 3.2,
        carbs: 87,
        protein: 6.5,
        iron: '45%',
      },
      {
        name: 'Donut',
        calories: 452,
        fat: 25.0,
        carbs: 51,
        protein: 4.9,
        iron: '22%',
      },
      {
        name: 'KitKat',
        calories: 518,
        fat: 26.0,
        carbs: 65,
        protein: 7,
        iron: '6%',
      },
    ],
  }),
  created() {
    this.getUserInfo()
  },
  components: {
    AccountNav,
  },
  methods: {
    getColor (calories) {
      if (calories > 400) return 'red'
      else if (calories > 200) return 'orange'
      else return 'green'
    },
    getUserInfo() {
      this.$store.dispatch(CHECK_AUTH).then(res=>{
        this.userInfo = res
      })
    },
  },
}
</script>