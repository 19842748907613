<template>
  <div>
    <second-bar title="诊断服务"></second-bar>
    <div class="dia-con">
      <div class="dia-head">
        <v-list>
          <v-list-item style="padding: 0;">
            <v-list-item-avatar>
              <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
            <v-list-item-title class="font-weight-light">尼古拉斯赵四</v-list-item-title>
            <v-list-item-subtitle class="font-weight-light">现在</v-list-item-subtitle>
          </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <div class="apply-input" v-if="showInput">
        <v-row>
          <v-col lg="6" md="6" sm="12">
            <v-select
              v-model="mySelect"
              :items="selectitems"
              label="请选择问题分类"
              value="0"
              outlined
            ></v-select>
          </v-col>
          <v-col lg="6" md="6" sm="12">
            <v-select
              v-model="mySelect2"
              :items="selectitems2"
              label="请选择问题子类"
              value="1"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <div>
          <v-textarea
            outlined
            v-model="submitInput"
            name="input-7-4"
            label="请描述您的问题"
            no-resize
          ></v-textarea>
        </div>
        <div>
          <v-file-input show-size label="上传附件" solo outlined flat @change="changeFile"></v-file-input>
        </div>
        <div>
          <v-row justify="end" style="margin: 0;">
            <v-btn color="primary" large :disabled="submitBtn" @click="submitQuestion">提交</v-btn>
          </v-row>
        </div>
      </div>
      <div v-if="showQuestion">
        <v-card color="#f4f4f4">
          <div class="cardcon">
            <div class="quesTxt">问题分类：{{ mySelect }}</div>
            <div class="quesTxt">问题子类：{{ mySelect2 }}</div>
            <div class="quesTxt my-4">具体描述：</div>
            <div>{{ question }}</div>
            <div class="mt-4">
              <v-img :src="require('@/assets/dia.png')"></v-img>
            </div>
          </div>
        </v-card>
        <!-- <v-btn @click="showAnswer = true">下一步</v-btn> -->
      </div>
      <div class="answers" v-if="showAnswer">
        <v-divider class="my-8"></v-divider>
        <h4>工程师回复：</h4>
        <div class="dia-head">
          <v-list>
            <v-list-item style="padding: 0;">
              <v-list-item-avatar>
                <v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
              <v-list-item-title class="font-weight-light">迈克尔刘能</v-list-item-title>
              <v-list-item-subtitle class="font-weight-light">2分钟前</v-list-item-subtitle>
            </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <div>
          <v-card color="#f4f4f4">
            <div class="cardcon">
              <div style="color: #8e8e8e; margin-bottom: 10px;">解决方案</div>
              <div style="font-style: italic;">{{ question }}</div>
              <div class="mt-4">
                <v-img :src="require('@/assets/answer.jpg')"></v-img>
              </div>
            </div>
          </v-card>
        </div>
        <v-row justify="end" style="margin: 0;" v-if="showContinue">
          <v-btn class="mr-4" width="80" color="primary" @click="showInput2 = true, showContinue = false, showSecond = true">追问</v-btn>
          <v-btn width="80" color="secondary">已解决</v-btn>
        </v-row>
      </div>
      <div v-if="showSecond">
        <v-divider class="mt-8 mb-6"></v-divider>
        <div class="dia-head">
          <h4>追问：</h4>
          <v-list>
            <v-list-item style="padding: 0;">
              <v-list-item-avatar>
                <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
              <v-list-item-title class="font-weight-light">尼古拉斯赵四</v-list-item-title>
              <v-list-item-subtitle class="font-weight-light">现在</v-list-item-subtitle>
            </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <div class="apply-input" v-if="showInput2">
          <v-row>
            <v-col lg="6" md="6" sm="12">
              <v-select
                v-model="mySelect"
                :items="selectitems"
                label="请选择问题分类"
                outlined
              ></v-select>
            </v-col>
            <v-col lg="6" md="6" sm="12">
              <v-select
                v-model="mySelect2"
                :items="selectitems2"
                label="请选择问题子类"
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <div>
            <v-textarea
              outlined
              v-model="submitInput2"
              name="input-7-4"
              label="请描述您的问题"
              no-resize
            ></v-textarea>
          </div>
          <div>
            <v-file-input show-size label="上传附件" solo outlined flat @change="changeFile"></v-file-input>
          </div>
          <div>
            <v-row justify="end" style="margin: 0;">
              <v-btn color="primary" large :disabled="submitBtn2" @click="submitQuestion2">提交</v-btn>
            </v-row>
          </div>
        </div>
      </div>
      <div v-if="showQuestion2">
        <v-card color="#f4f4f4">
          <div class="cardcon">
            <div class="quesTxt">问题分类：{{ mySelect }}</div>
            <div class="quesTxt">问题子类：{{ mySelect2 }}</div>
            <div class="quesTxt my-4">具体描述：</div>
            <div>{{ question2 }}</div>
            <div class="mt-4">
              <v-img :src="require('@/assets/dia.png')"></v-img>
            </div>
          </div>
        </v-card>
        <!-- <v-btn @click="showAnswer2 = true">下一步</v-btn> -->
      </div>
      <div class="answers" v-if="showAnswer2">
        <v-divider class="my-8"></v-divider>
        <h4>工程师回复：</h4>
        <div class="dia-head">
          <v-list>
            <v-list-item style="padding: 0;">
              <v-list-item-avatar>
                <v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
              <v-list-item-title class="font-weight-light">迈克尔刘能</v-list-item-title>
              <v-list-item-subtitle class="font-weight-light">2分钟前</v-list-item-subtitle>
            </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <div>
          <v-card color="#f4f4f4">
            <div class="cardcon">
              <div style="color: #8e8e8e; margin-bottom: 10px;">解决方案</div>
              <div style="font-style: italic;">{{ question2 }}</div>
              <div class="mt-4">
                <v-img :src="require('@/assets/answer.jpg')"></v-img>
              </div>
            </div>
          </v-card>
        </div>
        <v-row justify="end" style="margin: 0;" v-if="showBtn">
          <v-btn class="mr-4" width="80" color="primary">追问</v-btn>
          <v-btn width="80" color="secondary" @click="showStar">已解决</v-btn>
        </v-row>
        <v-row justify="end" align="center" style="margin: 0;" v-else>
          <span class="body-2">已评分！</span>
          <v-rating v-model="rating" :readonly="rateOnly"></v-rating>
        </v-row>
      </div>
    </div>
    <v-snackbar
      v-model="snackbar"
      top
      color="white"
    >
      <span class="black--text">{{ txt }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          关闭
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import SecondBar from '@/components/secondbar'
export default {
  data: () => ({
    submitInput: '',
    submitInput2: '',
    submitBtn: false,
    submitBtn2: false,
    question: '我的账号被冻结了，我该怎么办？',
    question2: '我的账号被冻结了，我该怎么办？',
    showInput: false,
    showInput2: false,
    showQuestion: true,
    showQuestion2: true,
    fileInput: '',
    snackbar: false,
    showAnswer: true,
    showAnswer2: true,
    showContinue: false,
    showBtn: false,
    showSecond: true,
    rating: 3,
    rateOnly: true,
    txt: '',
    mySelect: '解决方案支持',
    mySelect2: '硬件解决方案',
    selectitems: ['解决方案支持', '软件技术支持', '硬件技术支持', '设备管理服务'],
    selectitems2: ['软件解决方案', '硬件解决方案', '系统解决方案'],
  }),
  watch: {
    submitInput(val) {
      if (val.length > 8) {
        this.submitBtn = false
      }else {
        this.submitBtn = true
      }
    },
    submitInput2(val) {
      if (val.length > 8) {
        this.submitBtn2 = false
      }else {
        this.submitBtn2 = true
      }
    },
    rating(val) {
      if (val != 0) {
        this.rateOnly = true
        this.txt = '评价成功！'
        this.snackbar = true
      }
    },
  },
  components: {
    SecondBar,
  },
  methods: {
    submitQuestion() {
      this.question = this.submitInput
      this.showInput = false
      this.showQuestion = true
      this.txt = '问题提交成功！'
      this.snackbar = true
    },
    submitQuestion2() {
      this.question2 = this.submitInput2
      this.showInput2 = false
      this.showQuestion2 = true
      this.txt = '问题提交成功！'
      this.snackbar = true
    },
    changeFile(e) {
      console.log(e)
    },
    showStar() {
      this.showBtn = false
    },
  },
}
</script>

<style scoped>
.dia-con {
  max-width: 960px;
  min-height: 800px;
  margin: 0 auto;
  padding: 40px 0;
}
.apply-input {
  margin-top: 20px;
}
.cardcon {
  padding: 20px;
  margin: 20px 0;
}
.cardcon div {
  /* font-style: italic; */
}
.quesTxt {
  color: #8e8e8e;
}
</style>