<template>
  <v-sheet color="#f4f4f4" class="mainSheet">
    <div class="account-con">
      <div class="con-left">
        <account-nav :index="3"></account-nav>
      </div>
      <div class="con-right">
        <div class="right-over">
          <v-container>
            <v-card>
              <v-toolbar color="secondary" dark flat>
                <v-toolbar-title>企业报表</v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <v-autocomplete
                  autocomplete="off"
                  v-model="query.enterprise"
                  :items="monthlyEnterpriseList"
                  @change="doAction('search_enterprise')"
                  label="企业"
                  hint="请选择一家企业"
                  item-text="name"
                  item-value="_id"
                  outlined
                  flat
                  dense
                  dark
                  hide-details
                  clearable
                  class="mx-4"
                  prepend-inner-icon="search"
                  solo-inverted
                ></v-autocomplete> -->
              </v-toolbar>
              <!-- <v-alert dense color="grey lighten-3" class="ma-2" dismissible>
                <v-subheader
                  >企业报表是为企业提供月度统计信息的报告。只有经过经销商人员编辑后且发布的报告才会在企业端呈现。<span
                    class="secondary--text"
                    >月度报告每个月第一天0点系统自动生成</span
                  ></v-subheader
                >
              </v-alert> -->
              <v-data-table
                :headers="listHeader"
                :items="monthlyList"
                :options.sync="list_options"
                :server-items-length="monthlyCount"
                :loading="monthlyLoading"
                loading-text="数据读取中... 请稍后"
                :footer-props="{
                  'items-per-page-options': [5, 10, 15, 30, 50, 100],
                }"
                item-key="_id"
                transition
              >
                <template v-slot:item.enterprise="{ item }">
                  <td
                    nowrap="true"
                    v-if="item.enterprise"
                    @click="doAction('open_enterprise', item.enterprise)"
                    class="v-list-item--link"
                  >
                    {{ item.enterprise.name }}
                  </td>
                </template>
                <template v-slot:item.year="{ item }">
                  <td nowrap="true" width="25px">
                    <widgets-monthly-report :getId="item._id" v-on:Close="closeItem">
                      {{item.month ? item.year : item.year - 1}}
                    </widgets-monthly-report>
                  </td>
                </template>
                <template v-slot:item.month="{ item }">
                  <td nowrap="true" width="25px">
                    <widgets-monthly-report :getId="item._id" v-on:Close="closeItem">
                      {{item.month ? item.month : 12}}
                    </widgets-monthly-report>
                  </td>
                </template>
                <template v-slot:item.service="{ item }">
                  <td nowrap="true">
                    {{ item.service === "service" ? "设备管理" : "设备维修" }}
                  </td>
                </template>
                <template v-slot:item.isRelease="{ item }">
                  <td nowrap="true">{{ item.isRelease ? "是" : "否" }}</td>
                </template>
                <template v-slot:item.isRead="{ item }">
                  <td nowrap="true">{{ item.isRead ? "是" : "否" }}</td>
                </template>
                <template v-slot:item.updateTime="{ item }">
                  <td nowrap="true">{{ item.updateTime | formatTime }}</td>
                </template>
                <template v-slot:item.actions="{ item }">
                  <td nowrap="true" width="50px">
                    <widgets-monthly-report
                      :getId="item._id"
                      v-on:Close="closeItem"
                    >
                      <v-icon small>search</v-icon>
                    </widgets-monthly-report>
                  </td>
                </template>
              </v-data-table>
            </v-card>
            <v-dialog v-model="dlgInfo" persistent scrollable max-width="600px">
              <widgets-enterprise-dialogs
                v-if="activeType === 'enterprise'"
                :id="activeId"
                :key="activeId"
                v-on:Close="doAction('close_info')"
              ></widgets-enterprise-dialogs>
            </v-dialog>
          </v-container>
        </div>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import AccountNav from "@/components/accountnav";
import store from "@/store";
import { FETCH_MONTHLY_REPORT_LIST } from "@/store/actions.type";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      query: {
        enterprise: "",
      },
      defaultQuery: {
        enterprise: "",
      },
      list_options: {},
      listHeader: [
        { text: '年份', value: 'year' },
        { text: '月度', value: 'month' },
        { text: '企业', value: 'enterprise' },
        { text: "类型", value: "service" },
        { text: "是否已阅", value: "isRead" },
        { text: "更新时间", value: "updateTime" },
        { text: "操作", value: "actions", sortable: false },
      ],
      dlgInfo: false,
      activeType: "",
      activeId: null,
      hasEnterpriseList: [],
    };
  },
  components: {
    AccountNav,
  },
  created() {
    this.creatEnterpriseList();
    this.getMonthlyList();
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentEmployer",
      "monthlyLoading",
      "monthlyList",
      "monthlyCount",
      "monthlyEnterpriseList",
    ]),
  },
  watch: {
    list_options: {
      handler() {
        this.getMonthlyList(this.query);
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    creatEnterpriseList() {
      let j = {};
      j.name = this.currentUser.employer.ownerId.name;
      j._id = this.currentUser.employer.ownerId._id;
      this.hasEnterpriseList.push(j);
      this.query.enterprise = this.hasEnterpriseList[0]._id;
    },
    getMonthlyList(query = {}) {
      // 获取分页信息
      const { sortBy, sortDesc, page, itemsPerPage } = this.list_options;
      let sort = "";
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = "-" + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = "";
      if (query.enterprise) urlQuery += "&enterprise=" + query.enterprise;
      if (Object.keys(this.list_options).length > 0) {
        store.dispatch(
          FETCH_MONTHLY_REPORT_LIST,
          "page=" + page + "&limit=" + itemsPerPage + "&sort=" + sort + urlQuery
        );
      }
    },
    closeItem() {
      this.getMonthlyList(this.query);
    },
    doAction(action, item = {}) {
      switch (action) {
        case "search_enterprise": {
          if (this.query.enterprise !== "") {
            this.getMonthlyList({ enterprise: this.query.enterprise });
          } else {
            this.getMonthlyList();
          }
          break;
        }
        case "open_enterprise": {
          this.activeType = "enterprise";
          this.activeId = item._id;
          this.dlgInfo = true;
          break;
        }
        case "close_info": {
          this.dlgInfo = false;
          break;
        }
      }
    },
  },
};
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
