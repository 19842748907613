<template>
  <v-sheet color="#f4f4f4" class="mainSheet">
    <div class="account-con">
      <div class="con-left">
        <account-nav :index="2"></account-nav>
      </div>
      <div class="con-right">
        <div class="right-over">
          <v-dialog
              :value="assetServiceLoading ||
      assetRepairLoading ||
      consultingServiceLoading ||
      orderPurchaseLoading"
              hide-overlay
              persistent
              width="300"
          >
            <v-card color="primary" dark>
              <v-card-text>
                数据加载中...
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-container>
            <v-card>
              <v-toolbar class="secondary" dark>
                <v-toolbar-title>服务请求</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-row class="pa-4">
                <draggable v-model="cardOrder" class="row" v-bind="dragOptions">
                  <v-col cols="6" v-for="(layouts, i) in cardOrder" :key="layouts.index">
                    <draggable :list="layouts.items" :group="{ name: 'card' }" v-bind="dragOptions" :move="changeCard" @end="endCard">
                      <v-col cols="12" v-for="(card, j) in layouts.items.filter(l => l.code !== 'delivery')" :key="card.code">
                        <v-card outlined :loading="
                          card.code === 'maintain' ? assetRepairLoading
                          : card.code === 'service' ? assetServiceLoading
                          : card.code === 'advisory' ? consultingServiceLoading
                          : card.code === 'rentoffer' ? rentOfferLoading
                          : orderPurchaseLoading">
                          <v-row no-gutters align="start">
                            <v-col cols="2"><v-icon small style="cursor: move">drag_indicator</v-icon></v-col>
                            <v-col cols="8"><span class="subtitle-2" v-if="card.close">{{ card.name }}</span></v-col>
                            <v-col cols="2" class="text-right"><v-btn icon x-small @click="foldCard([i,j])"><v-icon small>{{card.close ? 'open_in_full' : 'close_fullscreen'}}</v-icon></v-btn></v-col>
                          </v-row>
                          <div v-if="!card.close">
                            <v-row align="center" justify="space-between">
                              <v-col cols="6">
                                <v-card-title>{{ card.name }}</v-card-title>
                                <v-card-text>{{ card.text }}</v-card-text>
                              </v-col>
                              <v-col cols="6">
                                <v-img :src="card.image" max-width="450px" height="150px" contain class="ma-2"></v-img>
                              </v-col>
                            </v-row>
                            <template v-for="list in card.data.data">
                              <v-divider :key="list._id +'_hr'"></v-divider>
                              <v-list-item link :key="list._id" @click="$router.push({ path: card.path +'?'+ converPath(card.code) + list._id +'&enterprise='+ query.enterprise})">
                                <v-list-item-content>
                                  <v-list-item-title>{{ list.name }}</v-list-item-title>
                                </v-list-item-content>
                                <v-spacer></v-spacer>
                                <v-list-item-content>
                                  <v-list-item-title>{{ list.count }}</v-list-item-title>
                                </v-list-item-content>
                                <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
                              </v-list-item>
                            </template>
                            <v-divider></v-divider>
                            <v-list-item>
                              <v-icon class="mr-2 grey--text" small>schedule</v-icon>
                              <span class="caption grey--text font-weight-light">更新时间：{{card.data.createTime | fromDate}}</span>
                              <v-icon v-if="card.group" class="ml-2" small @click="syncData(card.group, [i,j])">sync</v-icon>
                              <v-spacer></v-spacer>
                              <v-btn text plain @click="$router.push({ path: card.path +'?enterprise='+ query.enterprise})">查看全部({{card.data.count}})<v-icon right>keyboard_arrow_right</v-icon></v-btn>
                            </v-list-item>
                          </div>
                        </v-card>
                      </v-col>
                    </draggable>
                  </v-col>
                </draggable>
              </v-row>
            </v-card>
          </v-container>
        </div>
      </div>
    </div>
  </v-sheet>
</template>
<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {
  FETCH_REPORT_CASEGROUP_COUNT, FETCH_REPORT_ORDER_PURCHASE_STATUS,
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
import draggable from "vuedraggable";
import AccountNav from "@/components/accountnav";

export default {
  components: {
    AccountNav,
    draggable
  },
  data() {
    return {
      assetServiceLoading: true,
      assetRepairLoading: true,
      consultingServiceLoading: true,
      rentOfferLoading: true,
      orderPurchaseLoading: true,
      query: {
        enterprise: '',
      },
      cardOrder: [
        { index: 'left', items: []},
        { index: 'right', items: []},
      ],
      maxRow: 2,
    }
  },
  created() {
    this.getList();
  },
  mounted() {},
  computed: {
    ...mapGetters(["currentUser"]),
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost"
      };
    },
  },
  watch: {},
  methods: {
    async getList() {
      if (this.currentUser && this.currentUser.hasAdmin.includes('enterprise')) {
        let urlQuery = '&source=cache'
        if (Object.keys(this.$route.query).length > 0){
          this.query = Object.assign(this.query, this.$route.query);
          if (this.currentUser && this.currentUser.employer && this.currentUser.employer.ownerId._id) urlQuery += '&enterprise='+ this.currentUser.employer.ownerId._id
        }

        let localCard = localStorage.getItem('esp_workbench_29')
        if (localCard) this.cardOrder = JSON.parse(localCard)

        await store.dispatch(FETCH_REPORT_CASEGROUP_COUNT, '?groupname=asset_service'+ urlQuery)
            .then((data) => {
              let numLayout = this.cardOrder[0].items.length < this.maxRow ? 0 : 1
              let objCard = {
                code: 'service',
                name: '设备服务',
                text: '设备管理服务汇总信息',
                data,
                image: '/static/workflow/asset_service.svg',
                image_width: 450,
                path: '/workbench/equip',
                group: 'asset_service',
                list: 'assetServiceList'
              }
              let leftIndex = this.cardOrder[0].items.findIndex(edit => edit.code === 'service')
              let rightIndex = this.cardOrder[1].items.findIndex(edit => edit.code === 'service')
              if (leftIndex > -1) {
                Object.assign(this.cardOrder[0].items[leftIndex], objCard)
              } else if (rightIndex > -1){
                Object.assign(this.cardOrder[1].items[rightIndex], objCard)
              } else {
                this.cardOrder[numLayout].items.push(objCard)
              }
              this.assetServiceLoading = false
            })

        await store.dispatch(FETCH_REPORT_CASEGROUP_COUNT, '?groupname=asset_repair'+ urlQuery)
            .then((data) => {
              let numLayout = this.cardOrder[0].items.length < this.maxRow ? 0 : 1
              let objCard = {
                code: 'maintain',
                name: '维修服务',
                text: '设备维修服务汇总信息',
                data,
                image: '/static/workflow/maintain.svg',
                image_width: 450,
                path: '/workbench/repair',
                group: 'asset_repair',
                list: 'assetRepairList'
              }
              let leftIndex = this.cardOrder[0].items.findIndex(edit => edit.code === 'maintain')
              let rightIndex = this.cardOrder[1].items.findIndex(edit => edit.code === 'maintain')
              if (leftIndex > -1) {
                Object.assign(this.cardOrder[0].items[leftIndex], objCard)
              } else if (rightIndex > -1){
                Object.assign(this.cardOrder[1].items[rightIndex], objCard)
              } else {
                this.cardOrder[numLayout].items.push(objCard)
              }
              this.assetRepairLoading = false
            })

        await store.dispatch(FETCH_REPORT_CASEGROUP_COUNT, '?groupname=consulting_service'+ urlQuery)
            .then((data) => {
              let numLayout = this.cardOrder[0].items.length < this.maxRow ? 0 : 1
              let objCard = {
                code: 'advisory',
                name: '咨询服务',
                text: '企业用户咨询汇总信息',
                data,
                image: '/static/workflow/advisory.svg',
                image_width: 450,
                path: '/workbench/advisory',
                group: 'consulting_service',
                list: 'consultingServiceList'
              }
              let leftIndex = this.cardOrder[0].items.findIndex(edit => edit.code === 'advisory')
              let rightIndex = this.cardOrder[1].items.findIndex(edit => edit.code === 'advisory')
              if (leftIndex > -1) {
                Object.assign(this.cardOrder[0].items[leftIndex], objCard)
              } else if (rightIndex > -1){
                Object.assign(this.cardOrder[1].items[rightIndex], objCard)
              } else {
                this.cardOrder[numLayout].items.push(objCard)
              }
              this.consultingServiceLoading = false
            })

        await store.dispatch(FETCH_REPORT_ORDER_PURCHASE_STATUS, '?'+ urlQuery)
            .then((data) => {
              let numLayout = this.cardOrder[0].items.length < this.maxRow ? 0 : 1
              let objCard = {
                code: 'order',
                name: '采购订单',
                text: '设备管理服务相关订单信息汇总',
                data,
                image: '/static/workflow/order.svg',
                image_width: 450,
                path: '/workbench/order',
                group: 'purchase_order',
                list: 'purchaseList'
              }
              let leftIndex = this.cardOrder[0].items.findIndex(edit => edit.code === 'order')
              let rightIndex = this.cardOrder[1].items.findIndex(edit => edit.code === 'order')
              if (leftIndex > -1) {
                Object.assign(this.cardOrder[0].items[leftIndex], objCard)
              } else if (rightIndex > -1){
                Object.assign(this.cardOrder[1].items[rightIndex], objCard)
              } else {
                this.cardOrder[numLayout].items.push(objCard)
              }
              this.orderPurchaseLoading = false
            })

      } else {
        this.assetServiceLoading = false
        this.assetRepairLoading = false
        this.consultingServiceLoading = false
        this.orderPurchaseLoading = false
      }
    },
    syncData(group = 'asset_service', listIndex) {
      let urlQuery = ''
      urlQuery = '&source=cache'
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
        if (this.currentUser && this.currentUser.employer && this.currentUser.employer.ownerId._id) urlQuery += '&enterprise='+ this.currentUser.employer.ownerId._id
      }
      if (group === 'purchase_order') {
        store.dispatch(FETCH_REPORT_ORDER_PURCHASE_STATUS, '?' + urlQuery)
            .then((data) => {
              store.commit(SET_ERROR, {msg: '数据更新成功。', color: 'primary'});
              this.cardOrder[listIndex[0]].items[listIndex[1]].data = data
            })
      } else {
        store.dispatch(FETCH_REPORT_CASEGROUP_COUNT, '?groupname=' + group + urlQuery)
            .then((data) => {
              store.commit(SET_ERROR, {msg: '数据更新成功。', color: 'primary'});
              this.cardOrder[listIndex[0]].items[listIndex[1]].data = data
            })
      }
    },
    changeCard: function(evt){
      const lastItem = this.cardOrder.find(element => element.items.length === 1)
      const moveItem = evt.draggedContext.element
      if (lastItem && lastItem.items.find(item => item.code === moveItem.code)) {
        return false
      } else {
        return
      }
    },
    converPath(code) {
      return code === 'advisory' ? 'group='
          : code === 'order' ? 'status='
              : code === 'rentoffer' ? 'status='
                  : 'service='
    },
    endCard() {
      let objLocal = [
        {
          index: 'left',
          items: this.cardOrder[0].items
        },
        {
          index: 'right',
          items: this.cardOrder[1].items
        }
      ]
      localStorage.setItem('esp_workbench_29', JSON.stringify(objLocal))
    },
    foldCard(listIndex) {
      this.cardOrder[listIndex[0]].items[listIndex[1]].close = !this.cardOrder[listIndex[0]].items[listIndex[1]].close;
      this.endCard();
      this.$forceUpdate();
    },
  },
};
</script>
