<template>
  <div>
    <second-bar title="知识库"></second-bar>
    <div class="base-con">
      <v-row justify="space-between">
        <v-col cols="3">
          <v-treeview
            :active.sync="activeCatalog"
            :items="KBCatalogList"
            :loading-icon="KBCatalogLoading ? 'hourglass_top' : ''"
            color="secondary"
            class="text-button"
            :open.sync="deptOpenTree"
            activatable
            open-on-click
            transition
            shaped
            hoverable
            dense
          >
            <!-- <template v-slot:append="{ item }">
              {{ countKnowledge(item) }}
            </template> -->
          </v-treeview>
        </v-col>
        <v-divider vertical style="margin-left: -1px;"></v-divider>
        <v-col cols="9">
          <v-scroll-y-transition mode="out-in">
            <div
              v-if="!activeCatalog.length"
              class="title grey--text text--lighten-1 font-weight-light"
              style="align-self: center"
            >
              <!-- <h2 class="text-center my-16 text-h4">热门话题</h2> -->
              <div class="subject-card mt-4">
                <h4 class="text-h5 text-center black--text">最新发布</h4>
                <v-list class="mt-4" color="transparent">
                  <!-- <v-divider></v-divider> -->
                  <template v-for="(item, i) in newList">
                    <v-list-item
                      :key="i"
                      @click="$router.push('/detail/' + item._id)"
                    >
                      <v-list-item-avatar>
                        <v-icon>school</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                        <v-list-item-subtitle>
                          更新：{{ item.updateTime | fromDate }}
                          <span class="ml-2">{{ item.catalog.name }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text>
                          <v-icon small color="grey">visibility</v-icon>
                          <span class="caption ml-1">{{
                            item.views_count
                          }}</span>
                        </v-list-item-action-text>
                        <span>
                          <v-icon small color="grey">thumb_up_alt</v-icon>
                          <span class="caption ml-1">{{
                            item.useful_account.length
                          }}</span>
                        </span>
                      </v-list-item-action>
                    </v-list-item>
                    <!-- <v-divider :key="i"></v-divider> -->
                  </template>
                </v-list>
                <v-divider class="my-16"></v-divider>
              </div>
              <div class="subject-card">
                <h4 class="text-h5 text-center black--text">最多浏览</h4>
                <v-list class="mt-4" color="transparent">
                  <!-- <v-divider></v-divider> -->
                  <template v-for="(item, i) in viewsList">
                    <v-list-item
                      :key="i"
                      @click="$router.push('/detail/' + item._id)"
                    >
                      <v-list-item-avatar>
                        <v-icon>school</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                        <v-list-item-subtitle>
                          更新：{{ item.updateTime | fromDate }}
                          <span class="ml-2">{{ item.catalog.name }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text>
                          <v-icon small color="grey">visibility</v-icon>
                          <span class="caption ml-1">{{
                            item.views_count
                          }}</span>
                        </v-list-item-action-text>
                        <span>
                          <v-icon small color="grey">thumb_up_alt</v-icon>
                          <span class="caption ml-1">{{
                            item.useful_account.length
                          }}</span>
                        </span>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
              </div>
            </div>
            <v-data-table
              v-else
              :headers="headers"
              :items="knowledgeList"
              :options.sync="optionsItem"
              :server-items-length="knowledgeCount"
              :loading="knowledgeLoading"
              loading-text="数据读取中... 请稍后"
              :footer-props="{
                'items-per-page-options': [5, 10, 15, 30, 50, 100],
              }"
              item-key="_id"
              return-object="false"
              transition
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title v-if="activeCatalog.length"
                    ><v-icon class="mr-4">description</v-icon
                    >知识列表</v-toolbar-title
                  >
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
              <template v-slot:item.title="{ item }">
                <td class="d-inline-block text-truncate" width="400px">
                    <span class="v-list-item--link" @click="goToDetail(item._id)">
                      {{ item.title }}
                    </span>
                </td>
              </template>
              <template v-slot:item.updateTime="{ item }">
                <td nowrap="true" width="80px">
                  {{ item.updateTime | fromDate }}
                </td>
              </template>
            </v-data-table>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
      <v-divider class="my-16"></v-divider>
      <div>
        <h2 class="text-center my-16 text-h4">搜索更多话题</h2>
        <div class="searchInputBox">
          <v-text-field
            v-model="searchInput"
            flat
            clearable
            hide-details
            label="搜索客户支持"
            prepend-inner-icon="search"
            outlined
            color="secondary"
            @keydown.enter="goToSup"
          ></v-text-field>
        </div>
        <v-divider class="my-16"></v-divider>
      </div>
      <div class="text-center mb-16">
        <div class="sub-tit mb-10">联系支持</div>
        <h2 class="mb-10 text-h4">我们能为您提供什么帮助？</h2>
        <div class="subtitle-1 font-weight-light mb-6">
          通过发起问题咨询的方式获得支持。
        </div>
          <v-btn outlined color="secondary" @click="doAction('select_catalog', '选择咨询类别')">
            提交申请
          </v-btn>
      </div>
    </div>
    <v-dialog v-model="dlgCatalog" persistent max-width="600px">
      <v-form v-model="valid" @submit.prevent="submit('catalog')">
        <v-card>
          <v-card-title>
            <span class="headline">{{ dlgTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              autocomplete="off"
              v-model="editedCase.group"
              :items="groupList"
              @change="doAction('set_catalog_list')"
              :rules="[rules.selected]"
              label="支持分类"
              hint="请选择一个支持分类"
              item-text="name"
              item-value="_id"
              outlined
              dense
            ></v-autocomplete>
            <v-autocomplete
              autocomplete="off"
              v-model="editedCase.catalog"
              :items="catalogList"
              label="支持目录"
              hint="请选择一个支持目录"
              item-text="name"
              item-value="_id"
              outlined
              dense
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dlgCatalog = false">取消</v-btn>
            <v-btn color="secondary" text @click="submit('catalog')" :disabled="!valid">确定</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store";
import {
  FETCH_KNOWLEDGE_CATALOG_LIST,
  FETCH_KNOWLEDGE_LIST,
  AUTH_KNOWLEDGE,
  TOP_KNOWLEDGE,
  DELETE_KNOWLEDGE,
} from "@/store/actions.type";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import SecondBar from "@/components/secondbar";
export default {
  data: () => ({
    searchInput: "",
    items: [
      {
        src: require("../assets/nav/n5.jpg"),
      },
      {
        src: require("../assets/nav/n4.jpg"),
      },
      {
        src: require("../assets/nav/n3.jpg"),
      },
      {
        src: require("../assets/nav/n2.jpg"),
      },
    ],
    searchListItem: [
      {
        title: "回收被挂起的解决方法",
        time: "一天前",
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      },
      {
        title: "如何查看我的设备回收进展",
        time: "2小时前",
        avatar: "https://cdn.vuetifyjs.com/images/john.png",
      },
      {
        title: "收到回寄设备后该如何处理",
        time: "一个月前",
        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
      },
      {
        title: "回收被挂起的解决方法",
        time: "十年前",
        avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
      },
      {
        title: "如何查看我的设备回收进展",
        time: "1分钟前",
        avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
      },
    ],
    menuItem: [
      { title: "诊断", icon: "pageview", link: "/sheet" },
      { title: "维修", icon: "help", link: "/sheet" },
      { title: "回收", icon: "settings_applications", link: "/sheet" },
      { title: "激活", icon: "help", link: "/sheet" },
      { title: "发放", icon: "help", link: "/sheet" },
      { title: "部署", icon: "help", link: "/sheet" },
      { title: "运维", icon: "help", link: "/sheet" },
    ],
    newList: [],
    viewsList: [],
    query: {
      key: "",
      catalog: "",
      isTop: null,
      isUseful: null,
    },
    defaultQuery: {
      key: "",
      catalog: "",
      isTop: null,
      isUseful: null,
    },
    activeCatalog: [],
    headersAll: [
      { text: "标题", value: "title" },
      { text: "查看次数", value: "views_count" },
      { text: "有用次数", value: "useful_account" },
      { text: "所属目录", value: "catalog" },
      { text: "更新时间", value: "updateTime" },
    ],
    headers: [
      { text: "标题", value: "title" },
      { text: "查看次数", value: "views_count" },
      { text: "有用次数", value: "useful_account.length" },
      { text: "更新时间", value: "updateTime" },
    ],
    optionsItem: {},
    showAllKB: false,
    rules: {
      required: value => (value === 0 || !!value) || '请输入内容.',
      phonenum: value => {
        if (value) {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        } else {
          return true;
        }
      },
      selected: value => {
        if (!value || value.length < 1) {
          return '请至少选择一个选项。'
        } else {
          return true;
        }
      },
      email: value => {
        if (value) {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        } else {
          return true;
        }
      },
    },
    valid: true,
    groupList: [],
    catalogList: [],
    dlgCatalog: false,
    dlgTitle: '',
    editedCase: {
      enterprise: '',
      group: '',
      catalog: '',
    },
    defaultCase: {
      enterprise: '',
      group: '',
      catalog: '',
    },
    deptOpenTree: [],
  }),
  components: {
    SecondBar,
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([store.dispatch(FETCH_KNOWLEDGE_CATALOG_LIST)]).then(() => {
      next();
    });
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "KBCatalogLoading",
      "knowledgeList",
      "knowledgeCount",
      "knowledgeLoading",
      "KBCatalogList",
    ]),
  },
  watch: {
    activeCatalog() {
      this.getList()
    },
    optionsItem: {
      handler() {
        this.getList(this.query);
      },
      deep: true,
    },
  },
  created() {
    this.getTreeNode()
    this.getTopList();
  },
  methods: {
    getTreeNode() {
      if (this.$route.query.pId) this.activeCatalog = [this.$route.query.pId]
      if (this.$route.query.open) this.deptOpenTree = this.$route.query.open.split(',')
    },
    goToDetail(id) {
      this.$router.push('/detail/' + id + '?pId=' + this.activeCatalog[0] + '&open=' + this.deptOpenTree.join(','))
    },
    doAction(action, additional) {
      switch (action) {
        case 'select_catalog': {
          store.dispatch(FETCH_KNOWLEDGE_CATALOG_LIST, '&list=group').then((data) => {
            this.groupList = data.catalog
            this.dlgCatalog = true
            this.dlgTitle = additional
          })
          break
        }
        case 'set_catalog_list': {
          if (this.editedCase.group) {
            store.dispatch(FETCH_KNOWLEDGE_CATALOG_LIST, '&group='+ this.editedCase.group).then((data) => {
              this.catalogList = data.catalog
            })
          } else {
            this.catalogList = []
          }
          break
        }
        case 'cancel_header': {
          this.getHeader('consultHeader')
          this.$forceUpdate()
          break
        }
        case 'default_header': {

          break
        }
        case 'set_header': {
          this.getHeader('consultHeader')
          this.$forceUpdate()
          break
        }
        case 'search_item': {
          this.getList(this.query)
          break
        }
        default: {

          break
        }
      }
    },
    submit(subject) {
      if (subject === 'catalog') this.$router.push({path:'/workbench/advisory/edit?enterprise='+ this.currentUser.employer.ownerId._id +'&group='+ this.editedCase.group +'&catalog='+ this.editedCase.catalog})
    },
    goToSup() {
      if (this.searchInput != "")
        // this.$router.push('/support?' + encodeURI(this.searchInput))
        this.$router.push({
          name: "support",
          params: { keyword: encodeURI(this.searchInput) },
        });
    },
    getTopList() {
      store.dispatch(FETCH_KNOWLEDGE_LIST, "limit=5").then((res) => {
        this.newList = res.knowledge;
      });
      store
        .dispatch(FETCH_KNOWLEDGE_LIST, "sort=-views_count&limit=5")
        .then((res) => {
          this.viewsList = res.knowledge;
        });
      // store.dispatch(FETCH_KNOWLEDGE_LIST, 'limit=5').then((res) => {
      //   this.newList = res.knowledge
      // })
    },
    searchKey() {
      if (this.query.key) {
        this.showAllKB = true;
        this.getList();
      } else {
        this.showAllKB = false;
      }
    },
    countKnowledge(obj, countKey = "kb_count") {
      if (obj.parentId) {
        return obj[countKey];
      } else {
        return sumChildren(obj);
      }
    },
    checkAuditPower(catalogId = [], account = {}) {
      let power = false;
      let account_id = account._id;
      if (catalogId[0].parentId) {
        let editor = catalogId[0].parentId ? catalogId[0].parentId.editor : {};
        let catalog_managers = editor.managers ? editor.managers : [];
        if (catalog_managers.includes(account_id)) power = true;
      }
      return power;
    },
    checkCreatePower(catalogId = [], account = {}) {
      let power = false;
      let account_depts = account.employer.department.map((item) => item._id);
      if (catalogId[0].parentId) {
        let catalog_dept = catalogId[0].parentId.editor._id;
        if (account_depts.includes(catalog_dept)) power = true;
      }
      return power;
    },
    getList() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItem;
      let sort = "";
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = "-" + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      if (this.activeCatalog && this.activeCatalog.length)
        this.query.catalog = this.activeCatalog[0];
      let strQuery = "";
      if (this.query.key) strQuery += "&key=" + this.query.key;
      if (this.query.catalog) strQuery += "&catalog=" + this.query.catalog;
      if (this.query.isTop !== null && this.query.isTop !== undefined)
        strQuery += "&isTop=" + this.query.isTop;
      if (this.query.isUseful !== null && this.query.isUseful !== undefined)
        strQuery += "&isUseful=" + this.isUseful.isTop;
      if (this.query.page) strQuery += "&page=" + this.query.page;
      if (this.query.limit) strQuery += "&limit=" + this.query.limit;
      if (Object.keys(this.optionsItem).length > 0) {
        store.dispatch(FETCH_KNOWLEDGE_LIST, "page=" + page +"&limit=" + itemsPerPage +"&sort=" + sort +strQuery)
      }
    },
    createKnowledge(catalog) {
      this.$router.push({
        path:
          "/dealer/knowledge/edit/0?group=" +
          catalog[0].parentId._id +
          "&catalog=" +
          catalog[0]._id,
      });
    },
    reloadPage() {
      this.$router.go(0);
    },
    setTop(objItem, reply) {
      store
        .dispatch(TOP_KNOWLEDGE, { _id: objItem._id, reply })
        .then(() => {
          store.commit(SET_ERROR, { msg: "操作成功", color: "primary" });
          this.getList(this.query);
        })
        .catch((err) => {
          store.commit(SET_ERROR, { msg: err });
        });
    },
    setAuth(objItem, reply) {
      store
        .dispatch(AUTH_KNOWLEDGE, { _id: objItem._id, reply })
        .then(() => {
          store.commit(SET_ERROR, { msg: "操作成功", color: "primary" });
          this.getList(this.query);
        })
        .catch((err) => {
          store.commit(SET_ERROR, { msg: err });
        });
    },
    deleteItem(item) {
      if (
        confirm(
          "确定要删除 " + item.title + "？删除后该知识将不能被检索和查看。"
        )
      ) {
        if (confirm("已经引用该知识的内容将不受影响。如首页、咨询等内容。")) {
          store
            .dispatch(DELETE_KNOWLEDGE, item)
            .then(() => {
              store.commit(SET_ERROR, { msg: "删除成功", color: "primary" });
              this.reloadPage();
            })
            .catch((err) => {
              store.commit(SET_ERROR, { msg: err });
            });
        }
      }
    },
  },
};
function sumChildren(obj, key = "kb_count") {
  let count = obj[key];
  if (obj.children) {
    for (let item of obj.children) {
      count += sumChildren(item);
    }
  }
  return count;
}
</script>

<style scoped>
.base-con {
  max-width: 1280px;
  min-height: 800px;
  margin: 0 auto;
}
.searchInputBox {
  max-width: 700px;
  margin: 0 auto;
}
.sub-tit {
  font-size: 26px;
  font-weight: 300;
}
.menu-text {
  font-size: 0.875rem;
}
</style>