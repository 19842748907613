<template>
  <div style="min-height: 100%;">
    <second-bar title="客户支持" barType="button" buttonText="我的咨询" buttonLink="/workbench/advisory"></second-bar>
    <div class="sup-con">
      <v-row>
        <v-col lg="7" md="7" sm="12">
          <v-card outlined class="pa-6 fill-height">
            <h2 class="sup-title">我们能帮到您什么？</h2>
            <div style="max-width: 514px;">
              <v-row style="max-width: 528px;">
                <v-col cols="5">
                  <v-select
                    v-model="query.catalog"
                    :items="KBCatalogList"
                    item-text="name"
                    item-value="_id"
                    @change="setSecondCa"
                    label="知识分类"
                    outlined
                    hide-details
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="5">
                  <v-select
                    v-model="query.secCatalog"
                    :items="KBSecCatalog"
                    label="知识目录"
                    item-text="name"
                    item-value="_id"
                    @change="getKnList"
                    hide-details
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="2">
                  <v-btn text @click="resetSearchFilter">重置</v-btn>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="query.key"
                    @keydown.enter="getKnList()"
                    flat
                    clearable
                    hide-details
                    label="搜索客户支持"
                    prepend-inner-icon="search"
                    dense
                    outlined
                    :loading="loading"
                    color="secondary"
                  ></v-text-field>
                </v-col>
              </v-row>
              
            </div>
            <div style="max-width: 440px;" v-if="historyKeys.length">
              <v-chip
                v-for="(item,i) in historyKeys"
                :key="i"
                class="ma-2"
                text-color="primary"
                @click="fillSearch(item)"
              >
                {{ item }}
              </v-chip>
            </div>
            <div class="search-result">
              <div class="title-b" v-if="!searchedKey">
                <h3>热门主题</h3>
              </div>
              <div class="results-b">
                <v-alert
                  border="left"
                  color="grey lighten-3"
                  v-if="!knList.length"
                >
                  暂无相关主题知识，您可以
                  <span class="black--text" style="cursor: pointer;" @click="$router.push('/knowledge')">查看所有知识<v-icon class="mx-2">read_more</v-icon></span>
                  或
                  <span class="black--text" style="cursor: pointer;" @click="$router.push({path:'/workbench/advisory/edit?enterprise='+ currentUser.employer.ownerId._id +'&group='+ query.catalog +'&catalog='+ query.secCatalog})">提交咨询申请<v-icon class="mx-2">read_more</v-icon></span>
                </v-alert>
                
                <div class="title-b" v-if="showNum && searchedKey">
                  <h3>"{{searchedKey}}"有{{knList.length}}个搜索结果</h3>
                </div>
                <div class="result" v-for="(item, i) in knList" :key="i">
                  <div class="rt">
                    <span @click="$router.push('/detail/'+item._id)">{{ item.title }}</span>
                  </div>
                  <div class="detail">
                    <div class="my-2">
                      <span class="date" v-if="item.updateTime">更新：{{ item.updateTime | fromDate}}</span>
                      <span class="type">
                        <v-icon class="dicon" style="margin-right: 0;">info_outline</v-icon>
                        {{ item.catalog.name }}
                      </span>
                    </div>
                    <div>
                      <span>
                        <v-icon small color="grey">thumb_up_alt</v-icon>
                        <span class="caption ml-1" v-if="item.useful_account">{{ item.useful_account.length}}</span>
                      </span>
                      <span class="ml-4">
                        <v-icon small color="grey">visibility</v-icon>
                        <span class="caption ml-1">{{ item.views_count }}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col lg="5" md="5" sm="12">
          <v-card outlined class="mb-4 pa-6">
            <h4 class="text-h5 ls-h">知识</h4>
            <p class="mt-2" style="min-height: 100px;">获取深度教程、快速入门指南、提示、参考知识等资料。</p>
            <v-btn color="primary" small outlined @click="$router.push('/knowledge')">
              <span class="mr-2">查看所有知识</span>
              <v-icon small>east</v-icon>
            </v-btn>
          </v-card>
          <v-card outlined>
            <div class="activity-feed activity-feed-surround">
              <h4 class="activity-header text-h5 ls-h" id="activity-header">
                <!-- <v-icon color="primary">event</v-icon> -->
                企业近期服务
              </h4>
              <ul class="activity-feed">
                <!-- <v-hover v-slot:default="{ hover }" v-for="(item,i) in caseList" :key="i"> -->
                  <li class="activity purchase" v-for="(item,i) in caseList" :key="i">
                    <div class="activity-main">
                      <span class="activity-row activity-row-icon">
                        <v-icon color="primary">{{ item.catalog.profileIcon?item.catalog.profileIcon:'settings' }}</v-icon>
                      </span>
                      <span class="activity-row activity-row-details">
                        <widgets-case-dialogs :getId="item._id" :key="item._id">
                          <div class="mtitle">{{ item.catalog.name }} {{ item.case_number }}</div>
                        </widgets-case-dialogs>
                        <div class="subtitle">
                          <span v-if="item.status === 0">待受理</span>
                          <span v-if="item.status === 1">处理中</span>
                          <span v-if="item.status === 2">已取消</span>
                          <span v-if="item.status === 3">已取消</span>
                           &nbsp;
                          <span>{{item.createTime | formatTime}}</span>
                        </div>
                      </span>
                      <span class="activity-row activity-row-expand">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="ma-2" outlined fab x-small @click="setSearchService(item.catalog._id)">
                              <v-icon>help</v-icon>
                            </v-btn>
                          </template>
                          <span>常见问题</span>
                        </v-tooltip>
                        <!-- <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="ma-2" outlined fab x-small>
                              <v-icon>mail</v-icon>
                            </v-btn>
                          </template>
                          <span>我要投诉</span>
                        </v-tooltip> -->
                      </span>
                    </div>
                  </li>
                <!-- </v-hover> -->
              </ul>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="dlgCatalog" persistent max-width="600px">
      <v-form v-model="valid" @submit.prevent="submit('catalog')">
        <v-card>
          <v-card-title>
            <span class="headline">{{ dlgTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              autocomplete="off"
              v-model="editedCase.group"
              :items="KBCatalogList"
              @change="doAction('set_catalog_list')"
              :rules="[rules.selected]"
              label="支持分类"
              hint="请选择一个支持分类"
              item-text="name"
              item-value="_id"
              outlined
              dense
            ></v-autocomplete>
            <v-autocomplete
              autocomplete="off"
              v-model="editedCase.catalog"
              :items="KBSecCatalog"
              label="支持目录"
              hint="请选择一个支持目录"
              item-text="name"
              item-value="_id"
              outlined
              dense
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dlgCatalog = false">取消</v-btn>
            <v-btn color="secondary" text @click="submit('catalog')" :disabled="!valid">确定</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store";
import {
  FETCH_KNOWLEDGE_CATALOG_LIST,
  FETCH_KNOWLEDGE_LIST,
  FETCH_CASE_LIST,
} from "@/store/actions.type";
import {mapGetters} from "vuex";
import SecondBar from '@/components/secondbar'
export default {
  data: () => ({
    app: false,
    searchInput: '',
    showResult: false,
    loading: false,
    list: [
      {
        a: '提交咨询请求：账号相关问题',
        b: '已提交 2020/07/07： 咨询处理中'
      },
      {
        a: '提交咨询请求：账号相关问题',
        b: '已提交 2020/07/07： 咨询处理中'
      },
      {
        a: '提交咨询请求：账号相关问题',
        b: '已提交 2020/07/07： 咨询处理中'
      },
      {
        a: '提交咨询请求：账号相关问题',
        b: '已提交 2020/07/07： 咨询处理中'
      },
      {
        a: '提交咨询请求：账号相关问题',
        b: '已提交 2020/07/07： 咨询处理中'
      },
    ],
    showHotword: true,
    subMenu: [
      { title: '诊断服务', icon: 'pageview' },
      { title: '问题咨询', icon: 'help', link: '/diagnose' },
      { title: '维修申报', icon: 'settings_applications' },
    ],
    mySelect: '',
    mySelect2: '',
    query: {
      key: '',
      catalog: '',
      secCatalog: '',
      service: '',
      isTop: null,
      isUseful: null,
      limit: 10,
    },
    defaultQuery: {
      key: '',
      catalog: '',
      secCatalog: '',
      service: '',
      isTop: true,
      isUseful: null
    },
    knowledgeData: {},
    historyKeys:[],
    optionsItem: {},
    knList: [],
    KBSecCatalog: [],
    showSelect: false,
    showNum: false,
    caseList: [],
    searchedKey: '',
    dlgCatalog: false,
    valid: true,
    dlgTitle: '',
    editedCase: {
      enterprise: '',
      group: '',
      catalog: '',
    },
    rules: {
      required: value => (value === 0 || !!value) || '请输入内容.',
      phonenum: value => {
        if (value) {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        } else {
          return true;
        }
      },
      selected: value => {
        if (!value || value.length < 1) {
          return '请至少选择一个选项。'
        } else {
          return true;
        }
      },
      email: value => {
        if (value) {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        } else {
          return true;
        }
      },
    },
  }),
  components: {
    SecondBar,
  },
  watch: {
    "query.key"(val) {
      if (!val) {
        this.getKnList()
      }
    },
  },
  // watch: {
  //   searchInput(val) {
  //     this.getSearch(val)
  //   }
  // },
  computed: {
    ...mapGetters([
      "currentUser",
      "KBCatalogLoading",
      "knowledgeList",
      "knowledgeCount",
      "knowledgeLoading",
      "KBCatalogList",]),
  },
  created() {
    // this.query.isTop = true
    if (this.$route.params.keyword) this.query.key = decodeURI(this.$route.params.keyword)
    this.getKnList()
    this.getKnCatalogList()
    this.getCaseList()
  },
  methods: {
    submit() {
      this.$router.push({path:'/workbench/advisory/edit?enterprise='+ this.currentUser.employer.ownerId._id +'&group='+ this.editedCase.group +'&catalog='+ this.editedCase.secCatalog})
    },
    resetSearchFilter() {
      this.query.catalog = ''
      this.query.secCatalog = ''
      this.KBSecCatalog = []
      this.getKnList()
    },
    getSearchKeyword() {
      if (this.$route.params.keyword) {
        this.searchInput = decodeURI(this.$route.params.keyword)
      }
    },
    getKnList() {
      // const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItem;
      // let sort = '';
      // if (sortBy && sortBy.length === 1) {
      //   if (sortDesc[0] === true){
      //     sort = '-'+ sortBy[0];
      //   } else {
      //     sort = sortBy[0];
      //   }
      // }
      if (this.activeCatalog && this.activeCatalog.length) this.query.catalog = this.activeCatalog[0]._id
      let strQuery = '';
      if (this.query.key) this.query.isTop = null, this.query.limit = '', strQuery += '&key='+ this.query.key
      if (this.query.secCatalog) strQuery += '&catalog='+ this.query.secCatalog
      if (this.query.service !== null && this.query.service !== undefined) strQuery += '&service='+ this.query.service
      if (!this.query.key && !this.query.secCatalog) strQuery += '&isTop=true', this.query.limit = 10
      if (this.query.isUseful !== null && this.query.isUseful !== undefined) strQuery += '&isUseful='+ this.isUseful.isTop
      if (this.query.page) strQuery += '&page='+ this.query.page
      if (this.query.limit) strQuery += '&limit='+ this.query.limit
      // if (Object.keys(this.optionsItem).length > 0) {
        store.dispatch(FETCH_KNOWLEDGE_LIST, strQuery).then((res) => {
          this.knowledgeData = res
          this.knList = this.knowledgeData.knowledge
          if(this.knList.length) {
            this.searchedKey = this.query.key
            this.showSelect = true, this.showNum = true
          }else {
            this.searchedKey = ''
            this.showSelect = false, this.showNum = false
          }
          this.historyKeys = this.knowledgeData.query.historyKey.slice(0,10)
        })
      // }
    },
    getKnCatalogList() {
      store.dispatch(FETCH_KNOWLEDGE_CATALOG_LIST)
    },
    getCaseList() {
      store.dispatch(FETCH_CASE_LIST, {
        form: "service",
        query:
          "limit=5"
      }).then((res) => {
        this.caseList = res.cases
      })
    },
    setSecondCa() {
      if(this.query.catalog) {
        let c = []
        this.KBCatalogList.forEach( item => {
          if(item._id == this.query.catalog){
            c = item.children
          }
        })
        this.KBSecCatalog = c
      }
    },
    fillSearch(txt) {
      this.query.key = txt
      this.getKnList()
    },
    getSearch() {
      this.loading = true
      if (this.searchInput && this.searchInput != '') {
        setTimeout(() => {
          this.loading = false
          this.showHotword = false
          this.showResult = true
        }, 2000)
      }else {
        this.loading = false
        this.showHotword = true
        this.showResult = false
      }
    },
    setSearchService(id) {
      this.query = {
        key: '',
        catalog: '',
        secCatalog: '',
        service: '',
        isTop: false,
        isUseful: null,
        limit: 10,
      }
      this.query.service = id
      this.getKnList()
    },
  }
}
</script>

<style scoped>
.ls-h {
  letter-spacing: 0.15rem !important;
}
.func-con {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .9;
  position: absolute;
  width: 100%;
}
.search-result {
  margin-top: 30px;
  animation-name: result-show-fade;
  animation-duration: 300ms;
  animation-fill-mode: backwards;
  animation-timing-function: ease-out;
}
@keyframes result-show-fade {
  0% {
    opacity: 0.0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); 
  }
  100% {
    opacity: 1.0;
    -webkit-transform: translateY(0);
            transform: translateY(0); 
  } 
}
.search-result h3 {
  font-size: 1.525rem;
  letter-spacing: 0.15rem;
  font-weight: 400;
}
.title-b {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #000;
}
.result {
  margin-bottom: 30px;
}
.result .rt {
  cursor: pointer;
  font-size: 1.25rem;
  letter-spacing: 0.1rem;
}
.result .des {
  margin: 10px 0;
}
.result .detail .date,
.result .detail .type {
  margin-right: 30px;
  font-size: 0.725rem;
}
.result .rt span:hover {
  color: #d41746;
}
.result .detail .dicon {
  font-size: 18px;
  vertical-align: text-top;
  margin-right: 8px;
}
ul {
  padding: 0;
}
.sup-con {
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
}
.con-left,.con-right {
  float: left;
}
.con-left {
  width: 60%;
}
.sup-title {
  font-size: 1.953rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  margin-bottom: 30px;
}
.btn-text {
  color: #fff; 
  margin-left: 4px; 
  font-weight: 400;
}
.pro-card {
  overflow: hidden;
  padding: 0;
  margin-top: 30px;
}
.pro-card li {
  list-style: none;
  float: left;
}
.pro-card .con {
  display: block;
  float: left;
  position: relative;
  margin-bottom: 20px;
  margin-right: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  height: 220px;
  max-width: 150px;
  text-align: center;
  transition: border .2s !important;
  overflow: hidden;
}
.pro-card .con:before {
  content: " ";
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0);
  transition: background .2s !important;
  z-index: 99;
}
.pro-card .con .img-con {
  position: relative;
  height: 148px;
  width: 148px;
}
.pro-card .con span {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9em;
  line-height: 1.2;
  height: 5.2em;
  padding: 7px 10px;
  transition: color .2s !important;
}
.con-right {
  width: 35%;
  /* margin-top: 140px; */
  margin-bottom: 30px;
  margin-left: 5%;
  /* background-color: rgba(0,0,0,.7); */
}
.activity-feed.activity-feed-surround,
.forum-feed.activity-feed-surround {
  border: 1px solid rgba(255, 255, 255, 0.15);
  /* background-color: rgba(255, 255, 255, 0.07); */
}
.activity-feed > .activity-header,
.forum-feed > .activity-header {
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  margin: 0;
  padding: 20px;
  font-size: 1.25em;
  letter-spacing: 0.2rem;
  /* background-color: rgba(0,0,0,.7); */
}
.activity-feed,
.forum-feed {
  border: none;
  list-style: none;
  margin: 0 auto;
  overflow: hidden;
}
.activity-feed > .activity,
.forum-feed > .activity {
  min-height: 88px;
  position: relative;
  height: 100%;
  vertical-align: baseline;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: #424242;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  transition: all .2s;
  box-shadow: none;
  /* background-color: rgba(0,0,0,.7); */
}
.activity-feed > .activity:last-child,
.forum-feed > .activity:last-child {
  border: 0;
}
.activity-feed > .activity .activity-main,
.forum-feed > .activity .activity-main {
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  padding: 1.5em;
  right: 0;
  transition: right 0.2s ease-in-out;
  /* cursor: pointer; */
}
.activity-feed > .activity .activity-main .activity-row.activity-row-icon,
.forum-feed > .activity .activity-main .activity-row.activity-row-icon {
  color: #424242;
  margin-right: 13px;
  margin-top: 5px;
}
.activity-feed > .activity .activity-main .activity-row,
.forum-feed > .activity .activity-main .activity-row {
  display: inline-block;
  vertical-align: middle;
}

.activity-feed > .activity .activity-main .activity-row.activity-row-details,
.forum-feed > .activity .activity-main .activity-row.activity-row-details {
  width: 81%;
}
.activity-feed > .activity .activity-main .activity-row,
.forum-feed > .activity .activity-main .activity-row {
  display: inline-block;
  vertical-align: middle;
}
.activity-feed .mtitle,
.forum-feed .mtitle {
  display: block;
  line-height: 1.7em;
  transition: all .2s;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 12px;
  text-overflow: ellipsis;
  color: #424242;
  letter-spacing: 0.1rem;
}
.activity-feed .subtitle,
.forum-feed .subtitle {
  display: block;
  color: #424242;
  font-size: 0.9em;
  white-space: nowrap;
  margin-right: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20em;
  transition: max-width 0.2s;
  letter-spacing: 0.1rem;;
}
.activity-feed > .activity .activity-main .activity-row.activity-row-expand,
.forum-feed > .activity .activity-main .activity-row.activity-row-expand {
  margin-left: 8px;
  position: absolute;
  right: 14px;
  top: 27px;
}
</style>