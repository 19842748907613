<template>
  <v-sheet color="#f4f4f4" class="mainSheet">
    <div class="account-con">
      <div class="con-left">
        <account-nav :index="2"></account-nav>
      </div>
      <div class="con-right">
        <div class="right-over">
          <v-container>
            <v-card>
              <v-toolbar class="secondary" dark>
                <v-btn icon @click="goBack()">
                  <v-icon>arrow_back</v-icon>
                </v-btn>
                <v-toolbar-title>维修诊断服务</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="query.key"
                  @keydown.enter="doAction('search_item')"
                  class="mx-4"
                  flat
                  dense
                  hide-details
                  label="搜索服务编号、企业工单编号、设备序列号..."
                  prepend-inner-icon="search"
                  solo-inverted
                ></v-text-field>
              </v-toolbar>
              <v-data-table
                  v-model="selectedItem"
                  :headers="computedHeaders"
                  :items="caseList"
                  :options.sync="optionsItem"
                  :server-items-length="caseCount"
                  :loading="caseLoading"
                  loading-text="数据读取中... 请稍后"
                  :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
                  item-key="_id"
                  return-object="false"
                  transition
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <!-- <v-toolbar-title v-if="dealer_enterprise.length"><v-icon class="mr-4">assignment</v-icon>{{ query.enterprise ? dealer_enterprise.find(item => item._id === query.enterprise).name : '全部企业工单'}}</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider> -->
                    <v-spacer></v-spacer>
                    <v-menu offset-y v-if="currentUser && currentUser.hasAdmin && currentUser.hasAdmin.includes('enterprise')">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" color="secondary" depressed>
                          <v-icon left>add</v-icon>
                          新建工单
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                            v-for="(item, index) in availableService"
                            :key="index"
                            @click="confirmAplly(item)"
                        >
                          <v-icon class="mr-2" small>{{ item.profileIcon?item.profileIcon:'settings' }}</v-icon>
                          <v-list-item-title>
                            <span class="menu-text caption">{{ item.name }}</span>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click.stop="doAction('export_item')" :loading="downloading">
                          <v-icon small>save_alt</v-icon>
                        </v-btn>
                      </template>
                      <span>导出数据</span>
                    </v-tooltip>
                    <widgets-custom-header :defaultHeader="defaultHeader" :headerList="headerItem" :version=2 tableName="repairHeader" v-on:Cancel="doAction('cancel_header')" v-on:Default="doAction('default_header')" v-on:Save="doAction('set_header')"></widgets-custom-header>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click.stop="doAction('filter_item')">
                          <v-icon small>filter_list</v-icon>
                        </v-btn>
                      </template>
                      <span>过滤数据</span>
                    </v-tooltip>
                  </v-toolbar>
                </template>
                <template v-slot:item.case_number="{ item }">
                  <td nowrap="true">
                    <v-badge color="secondary" dot v-if="(item.creator._id === currentUser._id && item.out_req_type === '保外送修' && !item.confirm_price) || (item.creator._id === currentUser._id && item.status === 3)">
                      <widgets-case-repair :getId="item._id" v-on:Close="closeCase" :key="item._id" :isOpen="openCase(item.case_number)">
                        {{item.case_number}}
                      </widgets-case-repair>
                    </v-badge>
                    <widgets-case-repair v-else :getId="item._id" v-on:Close="closeCase" :key="item._id" :isOpen="openCase(item.case_number)">
                      {{item.case_number}}
                      <v-icon v-if="item.isSelf && item.status < 2" right small>create</v-icon>
                    </widgets-case-repair>
                  </td>
                </template>
                <template v-slot:item.out_req_id="{ item }">
                  <td nowrap="true">{{item.out_req_id}}</td>
                </template>
                <template v-slot:item.countTime="{ item }">
                  <td nowrap="true">{{item.createTime | fromDate}}</td>
                </template>
                <template v-slot:item.out_req_type="{ item }">
                  <td nowrap="true">{{item.out_req_type}}</td>
                </template>
                <template v-slot:item.return_policy="{ item }">
                  <td nowrap="true" v-if="item.return_policy">{{ repairPolicy.find(p => p.code === item.return_policy).name }}</td>
                  <td nowrap="true" v-else></td>
                </template>
                <template v-slot:item.catalog="{ item }">
                  <td nowrap="true">{{item.catalog?item.catalog.name:''}}</td>
                </template>
                <template v-slot:item.total_sla.expectTime="{ item }">
                  <td nowrap="true" v-if="item.total_sla && item.total_sla.status === 0"><widgets-baseTimer :endTime="item.total_sla.expectTime" :alertThreshold="item.total_sla.sla_limit / 2" :warningThreshold="item.total_sla.sla_limit" type="text"/></td>
                  <td nowrap="true" v-else-if="item.total_sla">{{ item.total_sla.status === 1 ? '完成' : '超时完成' }}</td>
                  <td nowrap="true" v-else>-</td>
                </template>
                <template v-slot:item.enterprise="{ item }">
                  <td nowrap="true" v-if="item.enterprise" @click="doAction('open_enterprise', item.enterprise)" class="v-list-item--link">{{item.enterprise.name}}</td>
                </template>
                <template v-slot:item.creator="{ item }">
                  <td nowrap="true" v-if="item.creator"><widgets-profile-dialogs :account="item.creator._id" class="v-list-item--link">{{item.creator.personal ? item.creator.personal.name : '*未设置姓名'}}</widgets-profile-dialogs></td>
                </template>
                <template v-slot:item.editor="{ item }">
                  <td nowrap="true" v-if="item.editor"><widgets-profile-dialogs :account="item.editor._id" class="v-list-item--link">{{item.editor.personal ? item.editor.personal.name : '*未设置姓名'}}</widgets-profile-dialogs></td>
                  <td nowrap="true" v-else>-</td>
                </template>
                <template v-slot:item.account="{ item }">
                  <td nowrap="true" v-if="item.account"><widgets-profile-dialogs :account="item.account._id" class="v-list-item--link">{{item.account.personal ? item.account.personal.name : '*未设置姓名'}}</widgets-profile-dialogs></td>
                  <td nowrap="true" v-else>-</td>
                </template>
                <template v-slot:item.link_asset="{ item }">
                  <td nowrap="true" v-if="item.link_asset && item.link_asset.length && item.link_asset[0] && item.link_asset[0].product">
                    <widgets-asset-dialogs :getId="item.link_asset[0]._id" caseType="repair">{{item.link_asset[0].product.name}}</widgets-asset-dialogs>
                  </td>
                </template>
                <template v-slot:item.link_asset.serial_number="{ item }">
                  <td nowrap="true" v-if="item.link_asset[0]">{{item.link_asset[0].serial_number}}</td>
                </template>
                <template v-slot:item.status="{ item }">
                  <td nowrap="true">
                    <v-chip label small v-if="item.status === 0" color="secondary">待受理</v-chip>
                    <v-chip label small v-else-if="item.status === 1" color="primary">{{item.gsx_create_time ? '检测完成' : '检测中'}}</v-chip>
                    <v-chip label small v-else-if="item.status === 2">{{item.repaired_time ? '维修完成' : '维修中'}}</v-chip>
                    <v-chip label small v-else-if="item.status === 3">待取机</v-chip>
                    <v-chip label small v-else-if="item.status === 4">服务结束</v-chip>
                    <v-chip label small v-else-if="item.status === 8">取消服务</v-chip>
                    <v-chip label small v-else>已完成</v-chip>
                  </td>
                </template>
                <template v-slot:item.createTime="{ item }">
                  <td nowrap="true">{{item.createTime | formatTime}}</td>
                </template>
                <template v-slot:item.updateTime="{ item }">
                  <td nowrap="true">{{item.updateTime | formatTime}}</td>
                </template>
              </v-data-table>
            </v-card>
            <v-navigation-drawer v-model="filterDrawer" app temporary bottom right style="z-index: 999;">
            <v-list-item>
            <v-list-item-content>
             <v-list-item-title class="title">
               过滤条件
             </v-list-item-title>
            </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-card-text>
            <h2 class="subtitle-2 my-2">基本信息</h2>
            <v-autocomplete
               v-model="query.creator"
               :items="listApplicant.filter(item => item.personal && item.personal.name)"
               :loading="searchEmployee.loadingApplicant"
               :search-input.sync="searchEmployee.searchApplicant"
               :filter="filterEmployee"
               item-text="personal.name"
               item-value="_id"
               autocomplete="off"
               outlined
               dense
               label="服务提交人员"
               hint="请选择工单提交人员"
               clearable
            >
             <template v-slot:item="data">
               <v-list-item-content>
                 <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                 <v-list-item-subtitle>{{data.item.username}} - {{data.item.phone}}</v-list-item-subtitle>
               </v-list-item-content>
             </template>
            </v-autocomplete>
            <v-autocomplete
               v-model="query.account"
               :items="listAccount.filter(item => item.personal && item.personal.name)"
               :loading="searchEmployee.loadingAccount"
               :search-input.sync="searchEmployee.searchAccount"
               :filter="filterEmployee"
               item-text="personal.name"
               item-value="_id"
               autocomplete="off"
               outlined
               dense
               label="设备使用人员"
               hint="请选择设备使用人员"
               clearable
            >
             <template v-slot:item="data">
               <v-list-item-content>
                 <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                 <v-list-item-subtitle>{{data.item.username}} - {{data.item.phone}}</v-list-item-subtitle>
               </v-list-item-content>
             </template>
            </v-autocomplete>
            <v-text-field
               v-model="query.serial_number"
               type="text"
               label="设备序列号"
               hint="设备序列号是设备的唯一标示"
               outlined
               dense
               clearable
            >
            </v-text-field>
            <h2 class="subtitle-2 mt-2">服务类型</h2>
            <v-chip-group v-if="repairType.length" v-model="query.out_req_type" column multiple>
             <v-chip v-for="type in repairType" :key="type.code" :value="type.code" filter label outlined>{{type.name}}</v-chip>
            </v-chip-group>
            <h2 class="subtitle-2 mt-2">三包策略</h2>
            <v-chip-group v-if="repairPolicy.length" v-model="query.return_policy" column multiple>
             <v-chip v-for="policy in repairPolicy" :key="policy.code" :value="policy.code" filter label outlined>{{policy.name}}</v-chip>
            </v-chip-group>
            <h2 class="subtitle-2 mt-2">工单状态</h2>
            <v-chip-group v-if="caseStatus.length" v-model="query.status" column multiple>
             <v-chip v-for="cases in caseStatus" :key="cases.code" :value="cases.code" filter label outlined>{{cases.name}}</v-chip>
            </v-chip-group>
            <h2 class="subtitle-2 mt-2">创建时间</h2>
            <v-menu ref="menuStartCreateTime" v-model="menuStartCreateTime"
                   :close-on-content-click="false"
                   transition="scale-transition"
                   offset-y
                   min-width="auto"
            >
             <template v-slot:activator="{ on, attrs }">
               <v-text-field
                   v-model="query.startCreateTime"
                   label="开始时间"
                   class="mt-2 mb-n4"
                   readonly
                   outlined
                   dense
                   clearable
                   v-bind="attrs"
                   v-on="on"
               ></v-text-field>
             </template>
             <v-date-picker v-model="query.startCreateTime" no-title scrollable width="225" @input="menuStartCreateTime = false; clearTime(['createTime'])">
             </v-date-picker>
            </v-menu>
            <v-menu ref="menuStartCreateTime" v-model="menuEndCreateTime"
                   :close-on-content-click="false"
                   transition="scale-transition"
                   offset-y
                   min-width="auto"
            >
             <template v-slot:activator="{ on, attrs }">
               <v-text-field
                   v-model="query.endCreateTime"
                   label="结束时间"
                   class="mt-2 mb-n4"
                   readonly
                   outlined
                   dense
                   clearable
                   v-bind="attrs"
                   v-on="on"
               ></v-text-field>
             </template>
             <v-date-picker v-model="query.endCreateTime" no-title scrollable width="225" @input="menuEndCreateTime = false; clearTime(['createTime'])"></v-date-picker>
            </v-menu>
            <v-chip-group v-model="query.createTime" column>
             <v-chip v-for="datetiem in updateTime" :key="datetiem.code" :value="datetiem.code" filter label outlined @change="clearTime(['startCreateTime', 'endCreateTime'])">{{datetiem.name}}</v-chip>
            </v-chip-group>
            <h2 class="subtitle-2 mt-2">更新时间</h2>
            <v-chip-group v-model="query.updateTime" column>
             <v-chip v-for="datetiem in updateTime" :key="datetiem.code" :value="datetiem.code" filter label outlined>{{datetiem.name}}</v-chip>
            </v-chip-group>
            </v-card-text>
            <template v-slot:append>
            <v-divider></v-divider>
            <v-card-actions>
             <v-spacer></v-spacer>
             <v-btn text @click="doAction('clear_filter')">清空</v-btn>
             <v-btn color="secondary" text @click="doAction('set_filter')">确认</v-btn>
            </v-card-actions>
            </template>
            </v-navigation-drawer>
            <v-dialog v-model="dlgService" persistent max-width="600px">
             <v-form v-model="valid" @submit.prevent="submit('service')">
               <v-card>
                 <v-card-title>
                   <span class="headline">{{ dlgTitle }}</span>
                 </v-card-title>
                 <v-card-text>
                   <v-autocomplete
                       autocomplete="off"
                       v-model="editedCase.service"
                       :items="availableService"
                       :rules="[rules.selected]"
                       label="服务"
                       hint="请选择开单的服务"
                       item-text="name"
                       item-value="_id"
                       outlined
                       dense
                   ></v-autocomplete>
                 </v-card-text>
                 <v-card-actions>
                   <v-spacer></v-spacer>
                   <v-btn text @click="dlgService = false">取消</v-btn>
                   <v-btn color="secondary" text @click="submit('service')" :disabled="!valid">确定</v-btn>
                 </v-card-actions>
               </v-card>
             </v-form>
            </v-dialog>
            <v-dialog v-model="dlgInfo" persistent scrollable max-width="600px">
             <widgets-enterprise-dialogs v-if="activeType === 'enterprise'" :id="activeId" :key="activeId" v-on:Close="doAction('close_info')"></widgets-enterprise-dialogs>
            </v-dialog>
          </v-container>
        </div>
      </div>
    </div>
  </v-sheet>
</template>
<script>
import store from "@/store";
import {
  FETCH_CASE_LIST,
  FETCH_CATALOG_LIST,
  FETCH_EMPLOYEE_LIST,
  FETCH_SERVICE_LIST,
  FETCH_TASK_LIST,
} from "@/store/actions.type";
import { mapGetters } from "vuex";
import Util from "@/common/util";
import AccountNav from "@/components/accountnav";
import {SET_ERROR} from "@/store/mutations.type";
import ApiService from "@/common/http";

export default {
  data() {
    return {
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        phonenum: (value) => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || "请输入正确的电话号码";
          } else {
            return true;
          }
        },
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        email: (value) => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "错误的电子邮件地址";
          } else {
            return true;
          }
        },
      },
      menuStartCreateTime: false, //筛选：创建工单开始时间
      menuEndCreateTime: false, //筛选：创建工单结束时间
      searchEmployee: {
        loadingAccount: false,
        searchAccount: null,
        timerAccount: null,
        entriesAccount: [],
        loadingApplicant: false,
        searchApplicant: null,
        timerApplicant: null,
        entriesApplicant: [],
      },
      query: {
        key: '',
        enterprise: '', //企业
        account: '', // 设备使用人
        creator: '', // 工单提交人
        serial_number: '', // 设备序列号
        service: [], // 服务类型
        tasks: [], // 当前任务
        status: [], // 工单状态
        updateTime: '', // 设备更新时间
        createTime: '', // 设备更新时间
        deadline: '', // SLA超期时间
        out_req_type: [], //服务类型
        return_policy: [], //三包策略
        startCreateTime: '', // 创建工单开始时间
        endCreateTime: '',
      },
      defaultQuery: {
        key: '',
        enterprise: '', //企业
        account: '', // 设备使用人
        creator: '', // 工单提交人
        serial_number: '', // 设备序列号
        service: [], // 服务类型
        tasks: [], // 当前任务
        status: [], // 工单状态
        updateTime: '', // 设备更新时间
        createTime: '', // 设备更新时间
        deadline: '', // SLA超期时间
        out_req_type: [], //服务类型
        return_policy: [], //三包策略
        startCreateTime: '', // 创建工单开始时间
        endCreateTime: '',
      },
      selectedItem: [],
      headerItem: [],
      defaultHeader: [
        { text: 'ESP工单编号', value: 'case_number' },
        { text: '维修单号', value: 'out_req_id', width: "115px", align: " d-none" },
        { text: '所属服务', value: 'catalog', width: "115px" },
        { text: '服务类型', value: 'out_req_type', width: "115px" },
        { text: '三包策略', value: 'return_policy', width: "115px", align: " d-none" },
        { text: 'SLA', value: 'total_sla.expectTime', align: " d-none" },
        { text: '持续时间', value: 'countTime', width: "115px" },
        { text: '请求人', value: 'creator', width: "115px" },
        { text: '处理人', value: 'editor', width: "115px", align: " d-none" },
        { text: '使用人', value: 'account', width: "115px", align: " d-none" },
        { text: '关联设备', value: 'link_asset', width: "115px", align: " d-none" },
        { text: '设备序列号', value: 'link_asset.serial_number', width: "115px", align: " d-none" },
        { text: '工单状态', value: 'status', width: "115px" },
        { text: '创建时间', value: 'createTime', align: " d-none" },
        { text: '更新时间', value: 'updateTime', align: " d-none" },
      ],
      optionsItem: {},
      filterDrawer: false,
      assetServiceList: [],
      tasksList: [],
      dlgHeader: false,
      dlgService: false,
      dlgTitle: "",
      valid: true,
      editedCase: {
        enterprise: "",
        service: "",
      },
      defaultCase: {
        enterprise: "",
        service: "",
      },
      availableService: [],
      dlgInfo: false,
      activeType: "",
      activeId: null,
      hasEnterpriseList: [],
      downloading: false,
    };
  },
  components: {
    AccountNav
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_CATALOG_LIST),
      store.dispatch(FETCH_TASK_LIST, "isEnable=true"),
    ]).then(() => {
      next();
    });
  },
  created() {
    this.getList();
    this.getService();
    this.getTasks();
    this.getHeader("repairHeader", 2);
    this.creatEnterpriseList()
    this.getServiceCatalog(this.query.enterprise)
  },
  mounted() {},
  computed: {
    ...mapGetters([
      "currentUser",
      "dealer_enterprise",
      "catalogList",
      "caseLoading",
      "caseList",
      "caseCount",
      "employeeList",
      "taskList",
      "serviceList"
    ]),
    caseStatus() {
      return Util.categories("repairStatus");
    },
    updateTime() {
      return Util.categories("updateTime");
    },
    SLAExpiry () {
      return Util.categories('SLAExpiry')
    },
    repairType () {
      return Util.categories('repairType')
    },
    repairPolicy () {
      return Util.categories('repairPolicy')
    },
    computedHeaders() {
      let arrHeaderItem = this.headerItem
      return arrHeaderItem.filter(item => !item.align)
    },
    listAccount() {
      return this.searchEmployee.entriesAccount.map(user => {
        return Object.assign({}, user)
      })
    },
    listApplicant() {
      return this.searchEmployee.entriesApplicant.map(user => {
        return Object.assign({}, user)
      })
    },
  },
  watch: {
    optionsItem: {
      handler() {
        this.getList(this.query);
      },
      deep: true,
    },
    dlgService(val) {
      val || this.closeService();
    },
    'searchEmployee.searchAccount'(val) {
      if (this.searchEmployee.timerAccount) {
        clearTimeout(this.searchEmployee.timerAccount)
      }
      this.searchEmployee.timerAccount = setTimeout(() => {
        if (this.searchEmployee.loadingAccount) return
        this.searchEmployee.loadingAccount = true
        if (this.query.enterprise || val) {
          let strEnterprise = this.currentUser.employer.ownerId._id, strKey = val || ''
          if (strEnterprise) {
            store.dispatch(FETCH_EMPLOYEE_LIST, 'enterprise='+ strEnterprise +'&key='+ strKey)
                .then((data) =>{
                  if (data) {
                    const { employees } = data
                    this.searchEmployee.entriesAccount = employees
                  }
                })
                .catch(err => {
                  store.commit(SET_ERROR, {msg: err})
                })
                .finally(() => (this.searchEmployee.loadingAccount = false))
          } else {
            this.searchEmployee.loadingAccount = false
          }
        } else {
          this.searchEmployee.loadingAccount = false
        }

      }, 500)
    },
    'searchEmployee.searchApplicant'(val) {
      if (this.searchEmployee.timerApplicant) {
        clearTimeout(this.searchEmployee.timerApplicant)
      }
      this.searchEmployee.timerApplicant = setTimeout(() => {
        if (this.searchEmployee.loadingApplicant) return
        this.searchEmployee.loadingApplicant = true
        if (this.query.enterprise || val) {
          let strEnterprise = this.currentUser.employer.ownerId._id, strKey = val || ''
          if (strEnterprise) {
            store.dispatch(FETCH_EMPLOYEE_LIST, 'enterprise='+ strEnterprise +'&key='+ strKey)
                .then((data) =>{
                  if (data) {
                    const { employees } = data
                    this.searchEmployee.entriesApplicant = employees
                  }
                })
                .catch(err => {
                  store.commit(SET_ERROR, {msg: err})
                })
                .finally(() => (this.searchEmployee.loadingApplicant = false))
          } else {
            this.searchEmployee.loadingApplicant = false
          }
        } else {
          this.searchEmployee.loadingApplicant = false
        }
      }, 500)
    },
  },
  methods: {
    confirmAplly(item) {
      this.$router.push({path: '/workbench/repair/edit?enterprise=' + this.currentUser.employer.ownerId._id +'&service='+ item._id})
    },
    creatEnterpriseList() {
      let j = {};
      j.name = this.currentUser.employer.ownerId.name;
      j._id = this.currentUser.employer.ownerId._id;
      this.hasEnterpriseList.push(j);
      this.query.enterprise = this.hasEnterpriseList[0]._id;
    },
    goBack() {
      this.$router.push({ path: "/workbench" });
    },
    getTasks(catalog = []) {
      if (catalog.length) {
        console.log(JSON.stringify(this.catalogList));
      } else {
        if (this.taskList) {
          this.tasksList = this.taskList.concat();
        }
      }
    },
    getService(enterprise = "") {
      let arrService = [],
        tmpHash = {};
      if (enterprise) {
        store.dispatch(FETCH_SERVICE_LIST, enterprise).then((data) => {
          if (data.length) {
            data.forEach((item) => {
              if (new Date(item.end_time) > new Date()) {
                arrService.push(
                  ...item.catalog.filter(
                    (service) =>
                      service.parentId.service_type === "asset_repair"
                  )
                );
              }
            });
            this.assetServiceList = arrService.reduce((total, current) => {
              if (!tmpHash[current["_id"]]) {
                tmpHash[current["_id"]] = true;
                total.push(current);
              }
              return total;
            }, []);
          }
        });
      } else {
        if (this.catalogList) {
          this.assetServiceList = this.flatGroupCatalog(this.catalogList).concat();
        }
      }
    },
    exportList(query = {}) {
      this.downloading = true
      // 获取分页信息
      const { sortBy, sortDesc, page } = this.optionsItem;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true){
          sort = '-'+ sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0){
        this.query = Object.assign(this.query, this.$route.query);
        if (this.query.status && !Array.isArray(this.query.status)) this.query.status = this.query.status.split(',')
        if (this.query.service && !Array.isArray(this.query.service)) this.query.service = this.query.service.split(',')
        if (this.query.tasks && !Array.isArray(this.query.tasks)) this.query.tasks = this.query.tasks.split(',')
        if (this.query.out_req_type && !Array.isArray(this.query.out_req_type)) this.query.out_req_type = this.query.out_req_type.split(',')
        if (this.query.return_policy && !Array.isArray(this.query.return_policy)) this.query.return_policy = this.query.return_policy.split(',')
      }
      let urlQuery = '';
      urlQuery = '&catalog_type=asset_repair'
      urlQuery += '&enterprise='+ this.currentUser.employer.ownerId._id
      if (query.key) urlQuery += '&key='+ query.key
      if (query.account) urlQuery += '&account='+ query.account
      if (this.query.status.length) urlQuery += '&status='+ query.status
      if (this.query.service.length) urlQuery += '&service='+ query.service
      if (this.query.tasks.length) urlQuery += '&tasks='+ query.tasks
      if (this.query.out_req_type.length) urlQuery += '&out_req_type='+ query.out_req_type
      if (this.query.return_policy.length) urlQuery += '&return_policy='+ query.return_policy
      if (this.query.creator) urlQuery += '&creator='+ query.creator
      if (this.query.serial_number) urlQuery += '&serial_number='+ query.serial_number
      if (this.query.updateTime) urlQuery += '&updateTime='+ query.updateTime
      if (this.query.createTime) urlQuery += '&createTime='+ query.createTime
      if (this.query.startCreateTime) urlQuery += '&startCreateTime='+ query.startCreateTime
      if (this.query.endCreateTime) urlQuery += '&endCreateTime='+ query.endCreateTime

      if (Object.keys(this.optionsItem).length > 0) {
        ApiService.download('/case/repair/export?page=' + page + '&limit=-1&sort=' + sort + urlQuery, {
          responseType:'blob'
        })
            .then(resp => {
              let fileName = 'ESP维修服务工单.xlsx';
              let contentDisposition = resp.headers['content-disposition'];
              if (contentDisposition) {
                fileName = window.decodeURI(resp.headers['content-disposition'].split('=')[1]);
              }
              let blob = new Blob([resp.data], {type: 'application/octet-stream'});
              if (window.navigator.msSaveOrOpenBlob) { //支持IE
                navigator.msSaveBlob(blob, fileName);
              } else {
                let link = document.createElement('a');
                link.style.display = "none";
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                this.downloading = false
                //释放内存
                window.URL.revokeObjectURL(link.href);
                document.body.removeChild(link);
              }
            }).catch(err => { // 请求失败处理
          this.downloading = false
          store.commit(SET_ERROR, {msg: err});
        });
      }

    },
    getList(query = {}) {
      // 获取分页信息
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItem;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true){
          sort = '-'+ sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0){
        this.query = Object.assign(this.query, this.$route.query);
        if (this.query.status && !Array.isArray(this.query.status)) this.query.status = this.query.status.split(',')
        if (this.query.service && !Array.isArray(this.query.service)) this.query.service = this.query.service.split(',')
        if (this.query.tasks && !Array.isArray(this.query.tasks)) this.query.tasks = this.query.tasks.split(',')
        if (this.query.out_req_type && !Array.isArray(this.query.out_req_type)) this.query.out_req_type = this.query.out_req_type.split(',')
        if (this.query.return_policy && !Array.isArray(this.query.return_policy)) this.query.return_policy = this.query.return_policy.split(',')
      }
      let urlQuery = '';
      urlQuery = '&catalog_type=asset_repair'
      urlQuery += '&enterprise='+ this.currentUser.employer.ownerId._id
      if (query.key) urlQuery += '&key='+ query.key
      if (query.account) urlQuery += '&account='+ query.account
      if (this.query.status.length) urlQuery += '&status='+ query.status
      if (this.query.service.length) urlQuery += '&service='+ query.service
      if (this.query.tasks.length) urlQuery += '&tasks='+ query.tasks
      if (this.query.out_req_type.length) urlQuery += '&out_req_type='+ query.out_req_type
      if (this.query.return_policy.length) urlQuery += '&return_policy='+ query.return_policy
      if (this.query.creator) urlQuery += '&creator='+ query.creator
      if (this.query.serial_number) urlQuery += '&serial_number='+ query.serial_number
      if (this.query.updateTime) urlQuery += '&updateTime='+ query.updateTime
      if (this.query.createTime) urlQuery += '&createTime='+ query.createTime
      if (this.query.startCreateTime) urlQuery += '&startCreateTime='+ query.startCreateTime
      if (this.query.endCreateTime) urlQuery += '&endCreateTime='+ query.endCreateTime
      if (Object.keys(this.optionsItem).length > 0) {
        store.dispatch(FETCH_CASE_LIST, {form: 'repair', query: 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery})
      }
    },
    getHeader(model, version) {
      let userHeader = this.currentUser[model];
      if (userHeader.content.length) {
        if (userHeader.version < version) {
          this.headerItem = JSON.parse(JSON.stringify(this.defaultHeader));
        } else {
          this.headerItem = JSON.parse(JSON.stringify(userHeader.content));
        }
      } else {
        this.headerItem = JSON.parse(JSON.stringify(this.defaultHeader));
      }
    },
    doAction(action, item = {}, additional) {
      switch (action) {
        case 'select_service': {
          this.dlgService = true
          this.dlgTitle = additional
          break
        }
        case 'set_service_list': {
          this.availableService.length = 0
          this.getServiceCatalog(this.editedCase.enterprise)
          break
        }
        case 'open_enterprise': {
          this.activeType = 'enterprise'
          this.activeId = item._id
          this.dlgInfo = true
          break
        }
        case 'close_info': {
          this.dlgInfo = false
          break
        }
        case 'cancel_header': {
          this.getHeader('repairHeader',2)
          this.$forceUpdate()
          break
        }
        case 'default_header': {
          break
        }
        case 'set_header': {
          this.getHeader('repairHeader',2)
          this.$forceUpdate()
          break
        }
        case 'filter_item': {
          this.filterDrawer = !this.filterDrawer
          break
        }
        case 'set_filter': {
          this.$router.push({ query: {} });
          this.getList(this.query)
          this.filterDrawer = !this.filterDrawer
          break
        }
        case 'clear_filter': {
          this.query = Object.assign({}, this.defaultQuery)
          this.$router.push({ query: {} });
          this.getList()
          this.filterDrawer = !this.filterDrawer
          break
        }
        case 'search_item': {
          this.getList(this.query)
          break
        }
        case 'export_item': {
          if (!this.downloading) {
            this.exportList(this.query)
          }
          break
        }
        default: {
          break
        }
      }
    },
    closeService() {
      this.dlgTitle = "";
      this.dlgService = false;
      this.$nextTick(() => {
        this.editedCase = Object.assign({}, this.defaultCase);
      });
    },
    getServiceCatalog (enterprise) {
      store.dispatch(FETCH_SERVICE_LIST, enterprise)
          .then((data) => {
            if (data.length) {
              data.forEach(item => {
                if (new Date(item.end_time) > new Date()) {
                  this.availableService.push(...item.catalog.filter(service => service.parentId.service_type === 'asset_repair'))
                }
              })
              const ids = this.availableService.map(s => s._id.toString())
              this.availableService = this.availableService.filter(({_id}, index) => !ids.includes(_id, index + 1))
            }
          })
    },
    flatGroupCatalog(arrGroup) {
      let arrRoles = [];
      arrGroup.forEach((groupItem) => {
        groupItem.children.forEach((catalogItem) => {
          arrRoles.push({
            _id: catalogItem._id,
            name: catalogItem.name,
            group: groupItem.name,
            process: catalogItem.process,
          });
        });
      });
      return arrRoles;
    },
    closeCase() {
      this.getList(this.query);
    },
    submit(subject) {
      if (subject === "service") {
        this.$router.push({
          path:
            "/dealer/workbench/maintain/create?enterprise=" +
            this.editedCase.enterprise +
            "&service=" +
            this.editedCase.service,
        });
      }
    },
    clearTime(arrModel = []) {
      for (const model of arrModel) {
        this.query[model] = ''
      }
    },
    filterEmployee(item, search) {
      return (
          item.username.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
          || item.phone.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
          || (item.personal && item.personal.name && item.personal.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1)
      )
    },
    openCase(key) {
      let returnValue = false
      if (Object.keys(this.$route.query).length > 0) {
        const objQuery = Object.assign(this.query, this.$route.query);
        if (objQuery.key === key) returnValue = true
      }
      return returnValue
    },
  },
};
</script>
<style scoped>
.nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
