<template>
  <div>
    <second-bar title="问题咨询"></second-bar>
    <div class="diagnose">
      <v-row>
        <v-col lg="7" md="5" sm="12">
          <v-card outlined class="pa-6">
            <div class="dia-con">
              <div class="dia-head">
                <v-list>
                  <v-list-item style="padding: 0;">
                    <v-list-item-avatar>
                      <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                    <v-list-item-title class="font-weight-light">尼古拉斯赵四</v-list-item-title>
                    <v-list-item-subtitle class="font-weight-light">现在</v-list-item-subtitle>
                  </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
              <div class="apply-input" v-if="showInput">
                <v-row>
                  <v-col lg="6" md="6" sm="12">
                    <v-select
                      v-model="mySelect"
                      :items="selectitems"
                      label="请选择问题分类"
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col lg="6" md="6" sm="12">
                    <v-select
                      v-model="mySelect2"
                      :items="selectitems2"
                      label="请选择问题子类"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
                <div>
                  <v-textarea
                    outlined
                    v-model="submitInput"
                    name="input-7-4"
                    label="请描述您的问题"
                    no-resize
                  ></v-textarea>
                </div>
                <div>
                  <v-file-input show-size label="上传附件" solo outlined flat @change="changeFile"></v-file-input>
                </div>
                <div>
                  <v-row justify="end" style="margin: 0;">
                    <v-btn color="primary" large :disabled="submitBtn" @click="submitQuestion">提交</v-btn>
                  </v-row>
                </div>
              </div>
              <div v-if="showQuestion">
                <v-card color="#f4f4f4">
                  <div class="cardcon">
                    <div class="quesTxt">问题分类：{{ mySelect }}</div>
                    <div class="quesTxt">问题子类：{{ mySelect2 }}</div>
                    <div class="quesTxt my-4">具体描述：</div>
                    <div>{{ question }}</div>
                    <div class="mt-4">
                      <v-img :src="require('@/assets/dia.png')"></v-img>
                    </div>
                  </div>
                </v-card>
                <v-btn @click="showAnswer = true">下一步</v-btn>
              </div>
              <div class="answers" v-if="showAnswer">
                <v-divider class="my-8"></v-divider>
                <h4>工程师回复：</h4>
                <div class="dia-head">
                  <v-list>
                    <v-list-item style="padding: 0;">
                      <v-list-item-avatar>
                        <v-menu>
                          <template v-slot:activator="{ on, attrs }">
                            <v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg" v-bind="attrs" v-on="on" style="cursor: pointer;"></v-img>
                            <!-- <v-btn text large v-bind="attrs" v-on="on">
                              <span class="nav-btn">企业中心</span>
                              <v-icon>expand_more</v-icon>
                            </v-btn> -->
                          </template>
                          <v-card max-width="300px">
                            <div style="padding: 10px 0;">
                              <v-list>
                              <v-list-item>
                                <v-list-item-avatar>
                                  <img src="https://cdn.vuetifyjs.com/images/lists/1.jpg" alt="John" />
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <v-list-item-title>迈克尔刘能</v-list-item-title>
                                  <v-list-item-subtitle
                                    >倍升互联（北京）科技有限公司</v-list-item-subtitle
                                  >
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                            </div>

                            <v-divider></v-divider>

                            <v-list dense>
                              <v-list-item-group color="primary">
                                <v-list-item>
                                  <v-list-item-icon>
                                    <v-icon small>phone</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>186 8866 8686</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item>
                                  <v-list-item-icon>
                                    <v-icon small>email</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>123xyz@abc.com</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </v-card>
                        </v-menu>
                        <!-- <v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg"></v-img> -->
                      </v-list-item-avatar>
                      <v-list-item-content>
                      <v-list-item-title class="font-weight-light">迈克尔刘能</v-list-item-title>
                      <v-list-item-subtitle class="font-weight-light">2分钟前</v-list-item-subtitle>
                    </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
                <div>
                  <v-card color="#f4f4f4">
                    <div class="cardcon">
                      <div style="color: #8e8e8e; margin-bottom: 10px;">解决方案</div>
                      <div style="font-style: italic;">{{ question }}</div>
                      <div class="mt-4">
                        <v-img :src="require('@/assets/answer.jpg')"></v-img>
                      </div>
                      <div>
                        <h4 class="mt-6">相关阅读：</h4>
                        <v-list class="mt-4" dense color="transparent">
                          <v-divider></v-divider>
                          <template v-for="(item, i) in searchListItem">
                            <v-list-item :key="i" @click="console.log('1')">
                              <v-list-item-avatar>
                                <v-icon>error_outline</v-icon>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>{{item.title}}</v-list-item-title>
                                <v-list-item-subtitle>更新：{{item.time}}</v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action>
                              <v-list-item-action-text>
                                <v-icon small color="grey">visibility</v-icon>
                                <span class="caption ml-1">389</span>
                              </v-list-item-action-text>
                              <span>
                                <v-icon small color="grey">thumb_up_alt</v-icon>
                                <span class="caption ml-1">245</span>
                              </span>
                              </v-list-item-action>
                            </v-list-item>
                            <v-divider :key="i"></v-divider>
                          </template>
                        </v-list>
                      </div>
                    </div>
                  </v-card>
                </div>
                <v-row justify="end" style="margin: 0;" v-if="showContinue">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        :disabled="disSub"
                      >
                        提交申请
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in menuItem"
                        :key="index"
                        @click="goToPage(item.link)"
                      > 
                        <v-icon class="mr-2" small>{{ item.icon }}</v-icon>
                        <v-list-item-title>
                          <span class="menu-text">{{ item.title }}</span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn class="mx-4" width="80" color="primary" @click="showInput2 = true, showContinue = false, showSecond = true" outlined>追问</v-btn>
                  <v-btn width="80" color="secondary" outlined>已解决</v-btn>
                </v-row>
              </div>
              <div v-if="showSecond">
                <v-divider class="mt-8 mb-6"></v-divider>
                <div class="dia-head">
                  <h4>追问：</h4>
                  <v-list>
                    <v-list-item style="padding: 0;">
                      <v-list-item-avatar>
                        <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                      <v-list-item-title class="font-weight-light">尼古拉斯赵四</v-list-item-title>
                      <v-list-item-subtitle class="font-weight-light">现在</v-list-item-subtitle>
                    </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
                <div class="apply-input" v-if="showInput2">
                  <v-row>
                    <v-col lg="6" md="6" sm="12">
                      <v-select
                        v-model="mySelect"
                        :items="selectitems"
                        label="请选择问题分类"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col lg="6" md="6" sm="12">
                      <v-select
                        v-model="mySelect2"
                        :items="selectitems2"
                        label="请选择问题子类"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                  <div>
                    <v-textarea
                      outlined
                      v-model="submitInput2"
                      name="input-7-4"
                      label="请描述您的问题"
                      no-resize
                    ></v-textarea>
                  </div>
                  <div>
                    <v-file-input show-size label="上传附件" solo outlined flat @change="changeFile"></v-file-input>
                  </div>
                  <div>
                    <v-row justify="end" style="margin: 0;">
                      <v-btn color="primary" large :disabled="submitBtn2" @click="submitQuestion2">提交</v-btn>
                    </v-row>
                  </div>
                </div>
              </div>
              <div v-if="showQuestion2">
                <v-card color="#f4f4f4">
                  <div class="cardcon">
                    <div class="quesTxt">问题分类：{{ mySelect }}</div>
                    <div class="quesTxt">问题子类：{{ mySelect2 }}</div>
                    <div class="quesTxt my-4">具体描述：</div>
                    <div>{{ question2 }}</div>
                    <div class="mt-4">
                      <v-img :src="require('@/assets/dia.png')"></v-img>
                    </div>
                  </div>
                </v-card>
                <v-btn @click="showAnswer2 = true">下一步</v-btn>
              </div>
              <div class="answers" v-if="showAnswer2">
                <v-divider class="my-8"></v-divider>
                <h4>工程师回复：</h4>
                <div class="dia-head">
                  <v-list>
                    <v-list-item style="padding: 0;">
                      <v-list-item-avatar>
                        <v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                      <v-list-item-title class="font-weight-light">迈克尔刘能</v-list-item-title>
                      <v-list-item-subtitle class="font-weight-light">2分钟前</v-list-item-subtitle>
                    </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
                <div>
                  <v-card color="#f4f4f4">
                    <div class="cardcon">
                      <div style="color: #8e8e8e; margin-bottom: 10px;">解决方案</div>
                      <div style="font-style: italic;">{{ question2 }}</div>
                      <div class="mt-4">
                        <v-img :src="require('@/assets/answer.jpg')"></v-img>
                      </div>
                    </div>
                  </v-card>
                </div>
                <v-row justify="end" style="margin: 0;" v-if="showBtn">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        :disabled="disSub"
                      >
                        提交申请
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in menuItem"
                        :key="index"
                        @click="goToPage(item.link)"
                      > 
                        <v-icon class="mr-2" small>{{ item.icon }}</v-icon>
                        <v-list-item-title>
                          <span class="menu-text">{{ item.title }}</span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn class="mx-4" width="80" color="primary" outlined>追问</v-btn>
                  <v-btn width="80" color="secondary" @click="showStar" outlined>已解决</v-btn>
                </v-row>
                <v-row justify="end" align="center" style="margin: 0;" v-else>
                  <span class="body-2" v-if="showDone">请对服务进行评价：</span>
                  <span class="body-2" v-if="!showDone">已评分！</span>
                  <v-rating v-model="rating" :readonly="rateOnly"></v-rating>
                </v-row>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col lg="5" md="5" sm="12">
          <v-card outlined class="pa-6">
            <v-text-field
              v-model="searchInput"
              flat
              clearable
              hide-details
              label="搜索客户支持"
              prepend-inner-icon="search"
              dense
              outlined
              color="secondary"
            ></v-text-field>
            <v-list class="mt-4">
              <v-divider></v-divider>
              <template v-for="(item, i) in searchListItem">
                <v-list-item :key="i" @click="console.log('1')">
                  <v-list-item-avatar>
                    <v-icon>error_outline</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{item.title}}</v-list-item-title>
                    <v-list-item-subtitle>更新：{{item.time}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                  <v-list-item-action-text>
                    <v-icon small color="grey">visibility</v-icon>
                    <span class="caption ml-1">389</span>
                  </v-list-item-action-text>
                  <span>
                    <v-icon small color="grey">thumb_up_alt</v-icon>
                    <span class="caption ml-1">245</span>
                  </span>
                  </v-list-item-action>
                </v-list-item>
                <v-divider :key="i"></v-divider>
              </template>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-snackbar
      v-model="snackbar"
      top
      color="white"
    >
      <span class="black--text">{{ txt }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          关闭
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import SecondBar from '@/components/secondbar'
export default {
  data: () => ({
    submitInput: '',
    submitInput2: '',
    submitBtn: true,
    submitBtn2: true,
    question: '',
    question2: '',
    showInput: true,
    showInput2: false,
    showQuestion: false,
    showQuestion2: false,
    fileInput: '',
    snackbar: false,
    showAnswer: false,
    showAnswer2: false,
    showContinue: true,
    showBtn: true,
    showSecond: false,
    rating: 0,
    rateOnly: false,
    txt: '',
    showDone: true,
    selectitems: ['解决方案支持', '软件技术支持', '硬件技术支持', '设备管理服务'],
    selectitems2: ['软件解决方案', '硬件解决方案', '系统解决方案'],
    mySelect: '',
    mySelect2: '',
    searchInput: '',
    searchListItem: [
      {
        title: '回收被挂起的解决方法',
        time: '一天前'
      },
      {
        title: '如何查看我的设备回收进展',
        time: '2小时前'
      },
      {
        title: '收到回寄设备后该如何处理',
        time: '一个月前'
      },
      {
        title: '回收被挂起的解决方法',
        time: '十年前'
      },
      {
        title: '如何查看我的设备回收进展',
        time: '1分钟前'
      },
    ],
    menuItem: [
      { title: '诊断', icon: 'pageview', link: '/sheet' },
      { title: '维修', icon: 'help', link: '/sheet' },
      { title: '回收', icon: 'settings_applications', link: '/sheet' },
      { title: '激活', icon: 'help', link: '/sheet' },
      { title: '发放', icon: 'help', link: '/sheet' },
      { title: '部署', icon: 'help', link: '/sheet' },
      { title: '运维', icon: 'help', link: '/sheet' },
    ],
  }),
  watch: {
    submitInput(val) {
      if (val.length > 8) {
        this.submitBtn = false
      }else {
        this.submitBtn = true
      }
    },
    submitInput2(val) {
      if (val.length > 8) {
        this.submitBtn2 = false
      }else {
        this.submitBtn2 = true
      }
    },
    rating(val) {
      if (val != 0) {
        this.rateOnly = true
        this.showDone = false
        this.txt = '评价成功！'
        this.snackbar = true
      }
    },
  },
  components: {
    SecondBar,
  },
  methods: {
    submitQuestion() {
      this.question = this.submitInput
      this.showInput = false
      this.showQuestion = true
      this.txt = '问题提交成功！'
      this.snackbar = true
    },
    submitQuestion2() {
      this.question2 = this.submitInput2
      this.showInput2 = false
      this.showQuestion2 = true
      this.txt = '问题提交成功！'
      this.snackbar = true
    },
    changeFile(e) {
      console.log(e)
    },
    showStar() {
      this.showBtn = false
    },
  },
}
</script>

<style scoped>
.diagnose {
  max-width: 1600px;
  margin: 0 auto;
}
.dia-con {
  max-width: 960px;
  min-height: 800px;
  margin: 0 auto;
}
.apply-input {
  margin-top: 20px;
}
.cardcon {
  padding: 20px;
  margin: 20px 0;
}
.quesTxt {
  color: #8e8e8e;
}
</style>