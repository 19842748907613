<template lang="html">
  <section id="event">
    <v-app-bar
      elevation="0"
      v-scroll="onScroll"
      dense
      style="margin: 0 auto;"
      dark
      :app="app"
    >
      <div class="sec-bar">
        <v-row>
          <div class="page-title">
            <span class="btn-text">客户支持</span>
          </div>
          <div class="flex-grow-1"></div>
          <v-btn
            text
            class="mr-2"
            @click="$router.push('/workbench')"
          >
            <v-icon small class="mr-1">assignment</v-icon>
            我的工单
          </v-btn>
          <v-btn
            text
             @click="comfirmAplly()"
            v-if="this.serviceId"
          >
            <v-icon small class="mr-1">add</v-icon>
            提交申请
          </v-btn>
        </v-row>
      </div>
    </v-app-bar>
    <section id="hero" v-if="materitalData.profile_image">
      <v-carousel
        :show-arrows="false"
        hide-delimiters
        >
        <v-carousel-item
          :src="materitalData.profile_image ? ossURL +'/'+ materitalData.profile_image : '/static/error/empty_state.svg'"
        >
          <div style="height: 100%; max-width: 1600px; margin: 0 auto;">
            <v-row
              class="fill-height mx-12"
              justify="start"
              align="end"
            >
              <div class="title-con">
                <div class="mb-3" v-if="materitalData.author">
                  <p class="stit">{{ materitalData.author }}</p>
                </div>
                <div class="tit mb-6">
                  {{ materitalData.title }}
                </div>

                <!-- <v-divider class="mb-3 mt-3" />

                <div class="mb-4">
                  <p class="stit">远程为您的设备提供诊断服务，即时了解设备健康度。</p>
                </div> -->
              </div>
            </v-row>
          </div>
        </v-carousel-item>
      </v-carousel>
    </section>
    <v-container style="max-width: 1600px;">
      <div class="display-2 text-center mt-8 mb-2" v-if="!materitalData.profile_image">{{ materitalData.title }}</div>
      <div class="subtitle-1 text-center" v-if="!materitalData.profile_image">{{ materitalData.author }}</div>
      <div class="" style="border-bottom: 1px solid #000;">
        <v-row style="max-width: 1600px; margin: 0 auto;">
          <div class="">
            <v-btn class="ma-2" text icon color="grey" style="position: relative;" @click="shareWeixin" v-if="$vuetify.breakpoint.smAndUp">
              <v-icon>mdi-wechat</v-icon>
            </v-btn>
            <v-btn class="ma-2" text icon color="grey" style="position: relative;" @click="shareWeixinM" v-if="!$vuetify.breakpoint.smAndUp">
              <v-icon>mdi-wechat</v-icon>
            </v-btn>
            <v-btn class="ma-2" text icon color="grey" @click="shareWeibo">
              <v-icon>mdi-sina-weibo</v-icon>
            </v-btn>
            <v-btn class="ma-2" text icon color="grey" @click="shareEmail">
              <v-icon>mdi-email</v-icon>
            </v-btn>
            <v-btn class="ma-2" text icon color="grey" @click="copyLink">
              <v-icon>mdi-link</v-icon>
            </v-btn>
          </div>
          <div class="spacer"></div>
          <div class="" style="line-height: 48px; color: grey;">
            
          </div>
        </v-row>
      </div>
      <v-card style="max-width: 1600px;" flat>
        <v-card-text>
          <div v-html="materitalData.context"></div>
        </v-card-text>
      </v-card>
      <!-- <div>
        <v-btn large color="secondary" dark width="30%" style="display: block; margin: 40px auto;" @click="$router.push('/apply')">提交申请 </v-btn>
      </div> -->
    </v-container>
    <v-dialog v-model="wxDialog" max-width="600">
      <v-card
        max-width="600"
        class="mx-auto"
      >
        <v-card-title>
          <v-btn icon @click="wxDialog = false">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <p class="title black--text" align="center">请使用微信扫描二维码分享！</p>
          <v-row justify="center">
            <div class="" style="width: 200px; height: 200px;">
              <v-img ref="img" :src="qrcodeImg"></v-img>
            </div>
          </v-row>
        </v-card-text>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      top
      color="success"
    >
      复制链接成功！
      <v-btn
        color="white"
        text
        @click="snackbar = false"
      >
        关闭
      </v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarM"
      top
      color="warning"
    >
      请使用浏览器分享功能！
      <v-btn
        color="white"
        text
        @click="snackbarM = false"
      >
        关闭
      </v-btn>
    </v-snackbar>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { FETCH_MATERIAL } from "@/store/actions.type";
export default {
  data() {
    return {
      app: false,
      eventDetail: "",
      moreSlide: 0,
      eventList: [],
      snackbar: false,
      showCode: false,
      snackbarM: false,
      wxDialog: false,
      qrcodeImg: "",
      show: false,
      items: [
        {
          src: require("../assets/nav/n5.jpg")
        },
        {
          src: require("../assets/nav/n4.jpg")
        },
        {
          src: require("../assets/nav/n3.jpg")
        },
        {
          src: require("../assets/nav/n2.jpg")
        }
      ],
      subMenu: [
        { title: "诊断服务", icon: "pageview" },
        { title: "问题咨询", icon: "help" },
        { title: "维修申报", icon: "settings_applications" }
      ],
      materitalData: {}
    };
  },
  watch: {
    eventId(val) {
      if (val) {
        this.getInfoData(val);
      }
    }
  },
  computed: {
    eventId() {
      return this.$route.params.id;
    },
    serviceId() {
      return this.$route.query.service;
    },
    serviceType() {
      return this.$route.query.type;
    },
    ...mapGetters(["isAuthenticated", "currentUser"])
  },
  created() {
    this.$emit("getTag", true);
    this.getInfoData(this.eventId);
  },
  activated() {},
  methods: {
    comfirmAplly() {
      if (this.isAuthenticated) {
        if (this.serviceType == 'asset_repair') {
          this.$router.push({path: '/workbench/repair/edit?enterprise=' + this.currentUser.employer.ownerId.id + '&service=' + this.serviceId})
        }else {
          this.$router.push({path: '/sheet?enterprise=' + this.currentUser.employer.ownerId.id + '&service=' + this.serviceId})
        }
      }else {
        this.$router.push('/login')
      }
      // alert('敬请期待！～')
    },
    getInfoData(id) {
      this.$store.dispatch(FETCH_MATERIAL, id).then(res => {
        this.materitalData = res;
      });
    },
    formatDate(date) {
      date = new Date(date);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return year + "年" + month + "月" + day + "日";
    },
    shareWeibo() {
      var p = {
        // 表示是否显示当前页面被分享数量(1 显示)(可选， 允许为空)
        count: "1",
        //将页面地址转成短域名， 并显示在内容文字后面。(可选， 允许为空)
        url: window.location.href,
        //用于发布微博的来源显示， 为空则分享的内容来源会显示来自互联网。(可选， 允许为空)
        appkey: "",
        //分享时所示的文字内容， 为空则自动抓取分享页面的title值(可选， 允许为空)
        title: this.eventDetail.title,
        //自定义图片地址， 作为微博配图(可选， 允许为空)
        pic: this.ossURL + "/" + this.eventDetail.bgImage,
        //转发时会 @相关的微博账号(可选， 允许为空)
        ralateUid: "",
        //语言设置(zh_cn | zh_tw)(可选)
        language: "zh_cn"
      };

      var s = [];
      for (var i in p) {
        s.push(i + "=" + encodeURIComponent(p[i] || ""));
      }
      var url = "http://service.weibo.com/share/share.php?" + s.join("&");
      window.open(url);
    },
    shareEmail() {
      parent.location.href =
        "mailto:" +
        " " +
        "?subject=" +
        this.eventDetail.title +
        "&body=" +
        window.location.href;
    },
    copyLink() {
      var Url2 = window.location.href;
      var oInput = document.createElement("input");
      oInput.value = Url2;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.snackbar = true;
    },
    onScroll () {
      this.app = window.scrollY > 64
    },
    shareWeixin(text) {
      text = window.location.href
    var webQcode = window.webQcode.setOption({
     padding: 10, //边距
     width: 200, // 宽度
     height: 200, //高度
     background: '#ffffff', // 背景色
     foreground: '#000000' // 前景色
    });
    webQcode.make(text, img=> {
     this.qrcodeImg = img
    })
      this.wxDialog = true
   },
    shareWeixinM() {
      this.snackbarM = true
    }
  }
};
</script>

<style scoped>
pre {
  white-space: pre-line;

  white-space: -moz-pre-line;

  white-space: -pre-line;

  white-space: -o-pre-line;

  word-wrap: break-word;

  word-break: break-all;

  overflow: hidden;

  font-family: inherit;

  line-height: 34px;
}
.mtLarge {
  margin-top: 64px;
}
.mtSmall {
  margin-top: 56px;
}
.cBtn {
  width: 80px;
  height: 80px;
  border: 1px solid #ccc;
  text-align: center;
  line-height: 80px;
  font-size: 30px;
  color: #ccc;
  transition: all 0.5s;
  cursor: pointer;
  border-radius: 50%;
}
.cBtn:hover {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: #fff;
}
.w100 {
  width: 100%;
}
.w40 {
  width: 40%;
}
.title-con {
  height: fit-content;
}
.title-con .tit {
  font-weight: 700;
  font-size: 3rem !important;
  line-height: 3rem;
  letter-spacing: -0.0083333333em !important;
}
.title-con .stit {
  font-size: 1.5rem !important;
  font-weight: 400;
  letter-spacing: normal !important;
}
.btn-text {
  color: #fff;
  margin-left: 4px;
  font-weight: 400;
}
.sec-bar {
  width: 1600px;
  margin: 0 auto;
  padding: 0 30px;
}
.page-title {
  height: 36px;
  line-height: 36px;
  font-size: 0.925rem;
}
.page-title span {
  padding-left: 10px;
  border-left: 4px solid #fff;
}
.menu-text {
  font-size: 0.875rem;
}
</style>
