<template>
  <v-sheet color="#f4f4f4" class="mainSheet">
    <div class="account-con">
      <div class="con-left">
        <account-nav :index="0"></account-nav>
      </div>
      <div class="con-right">
        <div class="right-over">
          <v-container>
            <v-dialog
                :value="!report.sumCount || !caseStatue.data || !report.caseCountYear"
                hide-overlay
                persistent
                width="300"
            >
              <v-card color="primary" dark>
                <v-card-text>
                  数据加载中...
                  <v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-row dense v-if="currentUser && report.sumCount" style="margin-top: -6px;">
              <v-col cols="4">
                <report-card-count icon="devices" :updateTime="report.sumCount.createTime" :count="report.sumCount.asset" label="已录入设备总数" :path="currentUser.hasAdmin.includes('enterprise')?'/asset':'/equip'"></report-card-count>
              </v-col>
              <v-col cols="4">
                <report-card-count icon="person" :updateTime="report.sumCount.createTime" :count="report.sumCount.account" label="企业员工总数" :path="currentUser.hasAdmin.includes('enterprise')?'/staff':''"></report-card-count>
              </v-col>
              <v-col cols="4">
                <report-card-count icon="assignment" :updateTime="report.sumCount.createTime" :count="report.sumCount.case" label="处理请求总数" path="/workbench"></report-card-count>
              </v-col>
              <v-col cols="4" v-if="report.assetHistory && report.assetHistory.data">
                <report-card-history :updateTime="report.assetHistory.createTime" :labels="report.assetHistory.data.map(item => item.date)" :value="report.assetHistory.data.map(item => item.count)" title="新增设备"></report-card-history>
              </v-col>
              <v-col cols="4" v-if="report.accountHistory && report.accountHistory.data">
                <report-card-history :updateTime="report.accountHistory.createTime" :labels="report.accountHistory.data.map(item => item.date)" :value="report.accountHistory.data.map(item => item.count)" title="新进员工"></report-card-history>
              </v-col>
              <v-col cols="4" v-if="report.caseHistory && report.caseHistory.data">
                <report-card-history :updateTime="report.caseHistory.createTime" :labels="report.caseHistory.data.map(item => item.date)" :value="report.caseHistory.data.map(item => item.count)" title="服务请求"></report-card-history>
              </v-col>
              <v-col cols="12">
                <v-data-iterator
                    :items="caseStatue.data"
                    item-key="name"
                    :items-per-page="4"
                    hide-default-footer
                >
                  <template v-slot:default="props">
                    <v-row>
                      <v-col v-for="item in props.items" :key="item.name" cols="12" sm="6" md="4" lg="4">
                        <v-card outlined>
                          <v-card-title class="title font-weight-light">
                            {{ item.name }}
                          </v-card-title>
                          <v-divider></v-divider>
                          <v-list dense>
                            <v-list-item link @click="gotoPath(item.code, 'pending')">
                              <v-list-item-content>
                                <v-list-item-title>处理中:</v-list-item-title>
                              </v-list-item-content>
                              <v-spacer></v-spacer>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ item.pending }}
                                </v-list-item-title>
                              </v-list-item-content>
                              <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
                            </v-list-item>
                            <v-list-item :link="item.code === 'service'" @click="gotoPath(item.code, 'warning')">
                              <v-list-item-content>
                                <v-list-item-title>即将超期:</v-list-item-title>
                              </v-list-item-content>
                              <v-spacer></v-spacer>
                              <v-list-item-content>
                                <v-list-item-title v-if="item.code === 'service'"><span class="orange--text">{{ item.warning }}</span></v-list-item-title>
                              </v-list-item-content>
                              <v-icon v-if="item.code === 'service'" class="subheading font-weight-light">arrow_forward</v-icon>
                            </v-list-item>
                            <v-list-item :link="item.code === 'service'" @click="gotoPath(item.code, 'overdue')">
                              <v-list-item-content>
                                <v-list-item-title>超期处理:</v-list-item-title>
                              </v-list-item-content>
                              <v-spacer></v-spacer>
                              <v-list-item-content>
                                <v-list-item-title v-if="item.code === 'service'"><span class="secondary--text">{{ item.overdue }}</span></v-list-item-title>
                              </v-list-item-content>
                              <v-icon v-if="item.code === 'service'" class="subheading font-weight-light">arrow_forward</v-icon>
                            </v-list-item>
                            <v-list-item link @click="gotoPath(item.code, 'complete')">
                              <v-list-item-content>
                                <v-list-item-title>已完成:</v-list-item-title>
                              </v-list-item-content>
                              <v-spacer></v-spacer>
                              <v-list-item-content>
                                <v-list-item-title>{{ item.complete }}</v-list-item-title>
                              </v-list-item-content>
                              <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
                            </v-list-item>
                          </v-list>
                          <v-divider></v-divider>
                          <v-card-text class="py-1">
                            <v-icon class="mr-2 grey--text" small>schedule</v-icon>
                            <span class="caption grey--text font-weight-light">更新时间：{{caseStatue.createTime | fromDate}}</span>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </v-col>
              <v-col cols=12 v-if="report.caseCountYear && report.caseCountYear.data">
                <v-card outlined class="mx-auto">
                  <v-card-title class="title font-weight-light">
                    服务请求{{report.caseCountYear.year}}年度热点图
                    <v-spacer></v-spacer>
                    <v-select
                        autocomplete="off"
                        v-model="catalogCountYear"
                        :items="BusinessType"
                        @change="updateHeatMap"
                        label="请选择业务类型"
                        item-text="name"
                        item-value="code"
                        hide-details
                        outlined
                        dense
                    ></v-select>
                  </v-card-title>
                  <v-divider></v-divider>
                  <report-heat-map width="100%" height="300px" :data="report.caseCountYear.data" :year="report.caseCountYear.year" :max="report.caseCountYear.maxValue" :updateTime="report.caseCountYear.createTime"></report-heat-map>
                  <v-divider></v-divider>
                  <v-card-text class="py-1">
                    <v-icon class="mr-2 grey--text" small>schedule</v-icon>
                    <span class="caption grey--text font-weight-light">更新时间：{{report.caseCountYear.createTime | fromDate}}</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <material-card color="secondary" icon="pending_actions" inline title="服务协议" class="mt-8">
                  <v-divider></v-divider>
                  <v-row class="pa-4" justify="space-between">
                    <v-col cols="12">
                      <v-data-table
                          :headers="service.headers"
                          :items="serviceList"
                          :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
                          sort-by="end_time"
                      >
                        <template v-slot:item.catalog="{ item }">{{item.catalog.map(i => i.name)}}</template>
                        <template v-slot:item.branch="{ item }">{{item.branch.map(i => i.name)}}</template>
                        <template v-slot:item.start_time="{ item }">{{ item.start_time | formatDate }}</template>
                        <template v-slot:item.end_time="{ item }">
                                        <span
                                            v-if="diffDays(item.end_time) > 0"
                                            class="text-decoration-line-through"
                                        >
                                        {{ item.end_time | formatDate }} -
                                        <span class="overline">{{ item.end_time | fromDate }}</span>
                                        </span>
                          <span v-else-if="diffDays(item.end_time) > -24" class="red--text">
                                        {{ item.end_time | formatDate }} -
                                        <span class="overline">{{ item.end_time | fromDate }}</span>
                                        </span>
                          <span v-else-if="diffDays(item.end_time) > -168" class="orange--text">
                                        {{ item.end_time | formatDate }} -
                                        <span class="overline">{{ item.end_time | fromDate }}</span>
                                        </span>
                          <span v-else>
                                        {{ item.end_time | formatDate }} -
                                        <span class="overline">{{ item.end_time | fromDate }}</span>
                                        </span>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </material-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
  </v-sheet>

</template>
<script>
import moment from "moment";
import AccountNav from "@/components/accountnav";
import store from "@/store";
import {
  FETCH_SERVICE_LIST,
  FETCH_REPORT_ALL_COUNT,
  FETCH_REPORT_CASE_HISTORY,
  FETCH_REPORT_ASSET_HISTORY,
  FETCH_REPORT_ENTERPRISE_HISTORY,
  FETCH_REPORT_ACCOUNT_HISTORY,
  FETCH_REPORT_CASE_STATUS,
  FETCH_REPORT_CASE_COUNT_YEAR
} from "@/store/actions.type";
import Util from "@/common/util";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      report:{
        sumCount: {},
        caseHistory: [],
        assetHistory: [],
        accountHistory: [],
        enterpriseHistory: [],
        caseCountYear: {},
        headers: [
          { text: '日期', value: 'period', width: '20%' },
          { text: '开始时间', value: 'startTime', width: '35%' },
          { text: '结束时间', value: 'endTime', width: '35%' },
          { text: '操作', value: 'actions', sortable: false, width: '10%'}
        ]
      },
      reportList: [
        { _id: '001', period: '2021-01', startTime: '2021-01-01', endTime: '2021-01-31' },
        { _id: '002', period: '2021-02', startTime: '2021-02-01', endTime: '2021-02-28' },
        { _id: '003', period: '2021-03', startTime: '2021-03-01', endTime: '2021-03-31' },
      ],
      catalogCountYear: 'service',
      caseStatue: [],
      service: {
        headers: [
          { text: '服务内容', value: 'catalog', width: '32%'},
          { text: '服务门店', value: 'branch', width: '28%'},
          { text: '开始时间', value: 'start_time', width: '13%'},
          { text: '结束时间', value: 'end_time', width: '27%'},
          // { text: '操作', value: 'actions', sortable: false, width: '8%'},
        ],
        startMenu: false,
        endMenu: false,
        editedIndex: -1,
        processList: [],
        editedItem: {
          catalog: [],
          branch: [],
          sign_time: [],
          sign_file: '',
          start_time: '',
          end_time: '',
          process: []
        },
        defaultItem: {
          catalog: [],
          branch: [],
          sign_time: [],
          sign_file: '',
          start_time: '',
          end_time: '',
          process: []
        },
        assetServiceListNum: '',
        assetRepairListNum: '',
        consultingServiceListNum: '',
      },
    }
  },
  components: {
    AccountNav,
  },
  created() {
    this.initialize()
    this.getServiceList()
  },
  computed: {
    ...mapGetters(["currentUser", "serviceList"]),
    BusinessType () {
      return Util.categories('BusinessType')
    },
  },
  methods: {
    getServiceList() {
      store.dispatch(FETCH_SERVICE_LIST, this.currentUser.employer.ownerId._id)
    },
    diffDays(endTime) {
      let nowTime = moment();
      return nowTime.diff(endTime, 'hours')
    },
    async initialize() {
      const sumCount = await store.dispatch(FETCH_REPORT_ALL_COUNT, '?source=cache')
      const caseHistory = await store.dispatch(FETCH_REPORT_CASE_HISTORY, '?source=cache')
      const assetHistory = await store.dispatch(FETCH_REPORT_ASSET_HISTORY, '?source=cache')
      const enterpriseHistory = await store.dispatch(FETCH_REPORT_ENTERPRISE_HISTORY, '?source=cache')
      const accountHistory = await store.dispatch(FETCH_REPORT_ACCOUNT_HISTORY, '?source=cache')
      const caseCountYear = await store.dispatch(FETCH_REPORT_CASE_COUNT_YEAR, '?source=cache')
      const statusList = await store.dispatch(FETCH_REPORT_CASE_STATUS, '?source=cache')

      this.report.sumCount = sumCount
      this.report.caseHistory = caseHistory
      this.report.assetHistory = assetHistory
      this.report.accountHistory = accountHistory
      this.report.enterpriseHistory = enterpriseHistory
      this.report.caseCountYear = caseCountYear
      this.caseStatue = statusList
      this.$forceUpdate()
    },
    flatGroupCatalog(arrGroup) {
      let arrRoles = []
      arrGroup.forEach((groupItem) => {
        arrRoles.push({ header: groupItem.name })
        groupItem.children.forEach((catalogItem) => {
          arrRoles.push({ _id: catalogItem._id, name: catalogItem.name, group: groupItem.name, process: catalogItem.process })
        })
        arrRoles.push({ divider: true })
      })
      return arrRoles
    },
    updateHeatMap() {
      if (this.catalogCountYear && this.catalogCountYear.length) {
        store.dispatch(FETCH_REPORT_CASE_COUNT_YEAR, '?source=cache&service='+ this.catalogCountYear)
            .then((data) => {
              this.report.caseCountYear = data
              this.$forceUpdate()
            })
      } else {
        store.dispatch(FETCH_REPORT_CASE_COUNT_YEAR, '?source=catch')
            .then((data) => {
              this.report.caseCountYear = data
              this.$forceUpdate()
            })
      }
    },
    gotoPath(path, query) {
      let dataTime = new Date();
      switch (query) {
        case 'pending': {
          if (path === 'service') this.$router.push({path: '/workbench/equip?&status=0,1'})
          if (path === 'repair') this.$router.push({path: '/workbench/repair?&status=0,1,2,3'})
          if (path === 'consult') this.$router.push({path: '/workbench/advisory?&status=0,1,2,3'})
          break
        }
        case 'complete': {
          if (path === 'service') this.$router.push({path: '/workbench/equip?&status=2,4'})
          if (path === 'repair') this.$router.push({path: '/workbench/repair?&status=4,8'})
          if (path === 'consult') this.$router.push({path: '/workbench/advisory?&status=2,3,4,8'})
          break
        }
        case 'warning': {
          if (path === 'service') this.$router.push({path: '/workbench/equip?status=0,1&deadline='+ dataTime.setTime(dataTime.getTime() + (4 * 3600 * 1000))})
          break
        }
        case 'overdue': {
          if (path === 'service') this.$router.push({path: '/workbench/equip?status=0,1&deadline='+ dataTime.setDate(dataTime.getDate())})
          break
        }
      }
    }
  }
};
</script>

<style scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
.hideText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
