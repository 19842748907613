<template>
  <v-sheet color="#f4f4f4" class="mainSheet">
    <div class="account-con">
      <div class="con-left">
        <account-nav :index="1"></account-nav>
      </div>
      <div class="con-right">
        <div class="right-over">
          <v-container>
            <v-card>
              <v-toolbar class="secondary" dark>
                <v-btn icon @click="goBack()" v-if="currentUser && currentUser.hasAdmin.includes('enterprise')">
                  <v-icon>arrow_back</v-icon>
                </v-btn>
                <v-toolbar-title>设备列表</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="query.key"
                    @keydown.enter="doAction('search_item')"
                    class="mx-4"
                    flat
                    dense
                    hide-details
                    label="搜索设备名称..."
                    prepend-inner-icon="search"
                    solo-inverted
                ></v-text-field>
              </v-toolbar>
              <v-data-table
                v-model="selectedItem"
                :headers="computedHeaders"
                :items="assetList"
                :options.sync="optionsItem"
                :server-items-length="assetCount"
                :loading="assetLoading"
                loading-text="数据读取中... 请稍后"
                :footer-props="{'items-per-page-options': [5, 10, 20]}"
                :item-class="row_class"
                item-key="_id"
                return-object="false"
                transition
                show-select
              >
                <template v-slot:top>
                  <widgets-asset-search
                      v-if="currentUser && hasEnterpriseList.find(item => item._id === query.enterprise)"
                      :account="currentUser"
                      :enterprise="hasEnterpriseList.find(item => item._id === query.enterprise)"
                      :stateList="stateList"
                      :warehouseList="warehouseList"
                      :typeList="typeList"
                      :vendorList="vendorList"
                      :productList="productList"
                      @change="searchAsset"
                  ></widgets-asset-search>
                  <v-toolbar flat color="white">
                    <v-toolbar-title>
                      <v-icon class="mr-4">devices</v-icon>
                      <span v-if="query.typein">配件列表</span>
                      <span v-else>设备列表</span>
                    </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <div>
                      <v-btn class="ma-2" text @click="doAction('edit_batch', selectedItem, '批量修改')" :disabled="!selectedItem.length" v-if="query.enterprise && currentUser.hasAdmin.includes('enterprise') && currentUser.employer.ownerId.self_asset">
                        <v-icon left>create</v-icon>
                        批量修改
                      </v-btn>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" :disabled="disSub" text>
                            <v-icon left>support_agent</v-icon>
                            服务申请
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item v-for="(item, index) in availableService" :key="index" @click="confirmAplly(item)">
                            <v-list-item-icon>
                              <v-icon>{{ item.profileIcon?item.profileIcon:'settings' }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              {{ item.name }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                    <v-spacer></v-spacer>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" color="secondary" depressed>
                          <v-icon left>add</v-icon>
                          新建设备
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item @click="doAction('create_item', query.enterprise, '新增设备')">
                          <v-list-item-icon>
                            <v-icon>add_box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>通过表单增加单台设备</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="query.enterprise" @click="doAction('import_item', query.enterprise, '新增多台设备')">
                          <v-list-item-icon>
                            <v-icon>library_add</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>通过文件增加多台设备</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <div>
                      <v-tooltip bottom v-if="query.enterprise">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" class="ma-2" icon @click.stop="doAction('export_item')" :loading="downloading">
                            <v-icon small>save_alt</v-icon>
                          </v-btn>
                        </template>
                        <span>导出数据</span>
                      </v-tooltip>
                      <widgets-custom-header v-if="completeFields" :defaultHeader="defaultHeader" :headerList="headerItem" :version=3 tableName="assetHeader" v-on:Cancel="doAction('cancel_header')" v-on:Default="doAction('default_header')" v-on:Save="doAction('set_header')"></widgets-custom-header>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              v-bind="attrs"
                              v-on="on"
                              class="ma-2"
                              icon
                              @click.stop="doAction('filter_item')"
                          >
                            <v-icon small>filter_list</v-icon>
                          </v-btn>
                        </template>
                        <span>过滤数据</span>
                      </v-tooltip>
                    </div>
                  </v-toolbar>
                </template>
                <template v-slot:item.name="{ item }">
                  <td nowrap="true">
                    <widgets-asset-dialogs :getId="item._id">
                      <div>
                        <v-tooltip bottom v-if="item.isLock">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small>lock</v-icon>
                          </template>
                          <span>设备锁定中</span>
                        </v-tooltip>
                        {{item.name}}
                        <v-tooltip bottom v-if="item.asset_type === 3">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small>currency_exchange</v-icon>
                          </template>
                          <span>企业租赁设备</span>
                        </v-tooltip>
                        <v-tooltip bottom v-else-if="item.ownerType === 'Enterprise'">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small>corporate_fare</v-icon>
                          </template>
                          <span>企业自有设备</span>
                        </v-tooltip>
                        <v-tooltip bottom v-else-if="item.ownerType === 'Account'">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small>person</v-icon>
                          </template>
                          <span>个人自有设备</span>
                        </v-tooltip>
                      </div>
                    </widgets-asset-dialogs>
                  </td>
                </template>
                <template v-slot:item.state="{ item }">
                  <td nowrap="true">{{item.state ? item.state.name : item.state}}</td>
                </template>
                <template v-slot:item.product="{ item }">
                  <td nowrap="true" v-if="item.product" @click="doAction('open_product', item.product)" class="v-list-item--link text-truncate" style="max-width: 150px;">{{item.product.name}} - {{item.product.part_number}}</td>
                </template>
                <template v-slot:item.branch="{ item }">
                  <td nowrap="true">{{item.branch}}</td>
                </template>
                <template v-slot:item.account="{ item }">
                  <td nowrap="true" v-if="item.account"><widgets-profile-dialogs :account="item.account._id" class="v-list-item--link">{{item.account.personal ? item.account.personal.name : '*未设置姓名'}}</widgets-profile-dialogs></td>
                </template>
                <template v-slot:item.serial_number="{ item }">
                  <td nowrap="true" v-if="item.product_type && item.product_type.noSN">无序列号</td>
                  <td nowrap="true" v-else>{{item.serial_number}}</td>
                </template>
                <template v-slot:item.label="{ item }">
                  <td nowrap="true">{{item.label}}</td>
                </template>
                <template v-slot:item.asset_from="{ item }">
                  <td nowrap="true">{{item.asset_from}}</td>
                </template>
                <template v-slot:item.asset_state="{ item }">
                  <td nowrap="true">{{item.asset_state.map(state => assetState.find(o => o.code === state).name)}}</td>
                </template>
                <template v-slot:item.transport.state="{ item }">
                  <td nowrap="true" v-if="item.transport && item.transport.state === 0">在途</td>
                  <td nowrap="true" v-else-if="item.transport && item.transport.state === 1">揽收</td>
                  <td nowrap="true" v-else-if="item.transport && item.transport.state === 2">疑难</td>
                  <td nowrap="true" v-else-if="item.transport && item.transport.state === 3">签收</td>
                  <td nowrap="true" v-else-if="item.transport && item.transport.state === 4">退签</td>
                  <td nowrap="true" v-else-if="item.transport && item.transport.state === 5">派件</td>
                  <td nowrap="true" v-else-if="item.transport && item.transport.state === 14">拒签</td>
                  <td v-else>-</td>
                </template>
                <template v-slot:item.transport.delivery_address="{ item }">
                  <td nowrap="true" v-if="item.transport" class="v-list-item--link text-truncate" style="max-width: 150px;">{{ item.transport.delivery_address }}</td>
                </template>
                <template v-slot:item.guaranteed_type="{ item }">
                  <td nowrap="true">{{ guaranteedType.filter(g => item.guaranteed_type.includes(g.code)).map(g => g.name) }}</td>
                </template>
                <template v-slot:item.guaranteed_time="{ item }">
                  <td nowrap="true" v-if="item.guaranteed_time">{{item.guaranteed_time | formatDate}} - {{item.guaranteed_time | fromDate}}</td>
                </template>
                <template v-slot:item.guaranteed_limit="{ item }">
                  <td nowrap="true" v-if="item.guaranteed_limit >= 12">{{parseInt(item.guaranteed_limit / 12) }}年 {{item.guaranteed_limit % 12 > 0 ? item.guaranteed_limit % 12 +'个月' : ''}}</td>
                  <td nowrap="true" v-else-if="item.guaranteed_limit">{{item.guaranteed_limit}}个月</td>
                  <td nowrap="true" v-else></td>
                </template>
                <template v-slot:item.guaranteed_deadline="{ item }">
                  <td nowrap="true" v-if="item.guaranteed_deadline">{{item.guaranteed_deadline | formatDate}} - {{item.guaranteed_deadline | fromDate}}</td>
                </template>
                <template v-slot:item.purchase_time="{ item }">
                  <td nowrap="true" v-if="item.purchase_time">{{item.purchase_time | formatDate}}</td>
                </template>
                <template v-slot:item.warranty_time="{ item }">
                  <td nowrap="true" v-if="item.warranty_time >= 12">{{parseInt(item.warranty_time / 12) }}年 {{item.warranty_time % 12 > 0 ? item.warranty_time % 12 +'个月' : ''}}</td>
                  <td nowrap="true" v-else-if="item.warranty_time">{{item.warranty_time}}个月</td>
                  <td nowrap="true" v-else>{{item.warranty_time}}</td>
                </template>
                <template v-slot:item.warranty_deadline="{ item }">
                  <td nowrap="true" v-if="item.warranty_deadline">{{item.warranty_deadline | formatDate}} - {{item.warranty_deadline | fromDate}}</td>
                </template>
                <template v-slot:item.warehouse="{ item }">
                  <td nowrap="true" v-if="item.warehouse">{{item.warehouse.name}}</td>
                </template>
                <template v-slot:item.warehouse_time="{ item }">
                  <td nowrap="true" v-if="item.warehouse_time">{{item.warehouse_time | formatDate}}</td>
                </template>
                <template v-slot:item.createTime="{ item }">
                  <td nowrap="true">{{item.createTime | formatTime}}</td>
                </template>
                <template v-slot:item.isABM="{ item }">
                  <td nowrap="true">{{item.isABM ? '是' : '否'}}</td>
                </template>
                <template v-slot:item.updateTime="{ item }">
                  <td nowrap="true">{{item.updateTime | formatTime}}</td>
                </template>
                <template v-slot:item.rent_info.rent_type="{ item }">
                  <td nowrap="true" v-if="item.asset_type === 3 && item.rent_info">{{item.rent_info.rent_type === 0 ? '融资租赁' : '经营租赁'}}</td>
                  <td nowrap="true" v-else>-</td>
                </template>
                <template v-slot:item.rent_info.lease_start="{ item }">
                  <td nowrap="true" v-if="item.asset_type === 3 && item.rent_info && item.rent_info.lease_start">{{item.rent_info.lease_start | formatDate}}</td>
                  <td nowrap="true" v-else>-</td>
                </template>
                <template v-slot:item.rent_info.lease_term="{ item }">
                  <td nowrap="true" v-if="item.asset_type === 3 && item.rent_info && item.rent_info.lease_term">{{item.rent_info.lease_term / 365}} 年</td>
                  <td nowrap="true" v-else>-</td>
                </template>
                <template v-slot:item.rent_info.payment_cycle="{ item }">
                  <td nowrap="true" v-if="item.asset_type === 3 && item.rent_info">{{item.rent_info.payment_cycle === '0' ? '月付' : item.rent_info.payment_cycle === '1' ? '季付' : '半年付'}}</td>
                  <td nowrap="true" v-else>-</td>
                </template>
                <template v-slot:item.rent_info.total_period="{ item }">
                  <td nowrap="true" v-if="item.asset_type === 3 && item.rent_info">{{item.rent_info.total_period}}</td>
                  <td nowrap="true" v-else>-</td>
                </template>
                <template v-slot:item.rent_info.expiry_date="{ item }">
                  <td nowrap="true" v-if="item.asset_type === 3 && item.rent_info && item.rent_info.expiry_date">{{item.rent_info.expiry_date | formatDate}}</td>
                  <td nowrap="true" v-else>-</td>
                </template>
              </v-data-table>
            </v-card>
            <v-navigation-drawer v-model="filterDrawer" app temporary bottom right style="z-index: 999;">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="title">过滤条件</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-card-text>
                <h2 class="subtitle-2 mt-2">企业与用户</h2>
                <v-autocomplete
                    autocomplete="off"
                    v-model="query.branch"
                    :items="arrBranch"
                    label="分支机构"
                    hint="请选择企业的分支机构"
                    outlined
                    dense
                    clearable
                ></v-autocomplete>
                <v-autocomplete
                  v-model="query.account"
                  :items="queryAccountList"
                  :loading="queryAccountListLoading"
                  :search-input.sync="searchQueryAccount"
                  cache-items
                  item-text="personal.name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  dense
                  label="使用人员"
                  hint="请选择设备使用人员"
                  clearable
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                      <v-list-item-subtitle>{{data.item.username}} - {{data.item.phone}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <div v-for="field in assetFieldList" :key="field._id">
                  <h2 class="subtitle-2 mt-2">{{field.name}}</h2>
                  <v-text-field
                      v-if="field.format.type === 'text'"
                      v-model="query[field._id]"
                      type="text"
                      :label="field.name"
                      outlined
                      dense
                      clearable
                  >
                  </v-text-field>
                  <v-chip-group v-if="field.format.type === 'selects'" v-model="query[field._id]" column multiple>
                    <v-chip v-for="item in field.format.items" :key="item" :value="item" filter label outlined>{{item}}</v-chip>
                  </v-chip-group>
                </div>
                <h2 class="subtitle-2 mt-2">设备归属</h2>
                <v-chip-group v-if="assetOwner.length" v-model="query.owner" column>
                  <v-chip v-for="owner in assetOwner" :key="owner.code" :value="owner.code" filter label outlined>{{owner.name}}</v-chip>
                </v-chip-group>
                <h2 class="subtitle-2 mt-2" v-if="query.owner === 'rental'">起租时间</h2>
                <v-menu
                    v-if="query.owner === 'rental'"
                    v-model="menuRentalStartDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="query.rental_create_startdate"
                        label="开始日期"
                        class="mt-2 mb-n4"
                        readonly
                        outlined
                        dense
                        clearable
                        @click:clear="query.rental_create_startdate = ''"
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="query.rental_create_startdate"
                      no-title scrollable width="225"
                      @input="menuRentalStartDate = false;"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                    v-if="query.owner === 'rental'"
                    v-model="menuRentalEndDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="query.rental_create_enddate"
                        label="结束日期"
                        class="mt-2 mb-n4"
                        readonly
                        outlined
                        dense
                        clearable
                        @click:clear="query.rental_create_enddate = ''"
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="query.rental_create_enddate"
                      no-title scrollable width="225"
                      @input="menuRentalEndDate = false;"
                  ></v-date-picker>
                </v-menu>
                <h2 class="subtitle-2 mt-2" v-if="query.owner === 'rental'">租赁到期</h2>
                <v-chip-group  v-if="query.owner === 'rental'" v-model="query.rental_deadline" column>
                  <v-chip v-for="datetiem in rentalDeadline" :key="datetiem.code" :value="datetiem.code" filter label outlined>{{datetiem.name}}</v-chip>
                </v-chip-group>
                <h2 class="subtitle-2 mt-2">设备状态</h2>
                <v-chip-group v-if="stateList.length" v-model="query.state" column multiple>
                  <v-chip v-for="state in stateList" :key="state._id" :value="state._id" filter label outlined>{{state.name}}</v-chip>
                </v-chip-group>
                <h2 class="subtitle-2 mt-2">保修到期</h2>
                <v-chip-group v-model="query.warranty_deadline" column>
                  <v-chip v-for="datetiem in expiryDate" :key="datetiem.code" :value="datetiem.code" filter label outlined>{{datetiem.name}}</v-chip>
                </v-chip-group>
                <h2 class="subtitle-2 mt-2">延保类型</h2>
                <v-chip-group v-if="guaranteedType.length" v-model="query.guaranteed_type" column multiple>
                  <v-chip v-for="guaranteed_type in guaranteedType" :key="guaranteed_type.code" :value="guaranteed_type.code" filter label outlined>{{guaranteed_type.name}}</v-chip>
                </v-chip-group>
                <h2 class="subtitle-2 mt-2">延保到期</h2>
                <v-chip-group v-model="query.guaranteed_deadline" column>
                  <v-chip v-for="datetiem in expiryDate" :key="datetiem.code" :value="datetiem.code" filter label outlined>{{datetiem.name}}</v-chip>
                </v-chip-group>
                <h2 class="subtitle-2 mt-2">快递信息</h2>
                <v-text-field
                    v-model="query.express_no"
                    type="text"
                    label="快递单号"
                    outlined
                    dense
                    clearable
                    hide-details
                >
                </v-text-field>
                <v-chip-group v-if="expressState.length" v-model="query.express_state" column multiple>
                  <v-chip v-for="state in expressState" :key="state.code" :value="state.code" filter label outlined>{{state.name}}</v-chip>
                </v-chip-group>
                <h2 class="subtitle-2 mt-2">设备情况</h2>
                <v-chip-group v-if="assetState.length" v-model="query.asset_state" column multiple>
                  <v-chip v-for="asset_state in assetState" :key="asset_state.code" :value="asset_state.code" filter label outlined>{{asset_state.name}}</v-chip>
                </v-chip-group>
                <h2 class="subtitle-2 mt-2">更新时间</h2>
                <v-chip-group v-model="query.updateTime" column>
                  <v-chip v-for="datetiem in updateTime" :key="datetiem.code" :value="datetiem.code" filter label outlined>{{datetiem.name}}</v-chip>
                </v-chip-group>
                <h2 class="subtitle-2 mt-2">所在仓库</h2>
                <v-chip-group v-if="warehouseList.length" v-model="query.warehouse" column multiple>
                  <v-chip v-for="warehouse in warehouseList" :key="warehouse._id" :value="warehouse._id" filter label outlined>{{warehouse.name}}</v-chip>
                </v-chip-group>
                <h2 class="subtitle-2 mt-2">产品品牌</h2>
                <v-chip-group v-if="vendorList.length" v-model="query.vendor" column multiple @change="doAction('set_product_list')">
                  <v-chip v-for="vendor in vendorList" :key="vendor._id" :value="vendor._id" filter label outlined>{{vendor.name}}</v-chip>
                </v-chip-group>
                <h2 class="subtitle-2 mt-2">产品类型</h2>
                <v-chip-group v-if="typeList.length" v-model="query.productType" column multiple @change="doAction('set_product_list')">
                  <v-chip v-for="prd_type in typeList.filter(t => t.isEnable)" :key="prd_type._id" :value="prd_type._id" filter label outlined>{{prd_type.name}}</v-chip>
                </v-chip-group>
                <h2 class="subtitle-2 mt-2">关联产品</h2>
                <v-chip-group v-if="productList.length" v-model="query.product" column multiple>
                  <v-chip v-for="product in productList" :key="product._id" :value="product._id" filter label outlined>{{product.name}}</v-chip>
                </v-chip-group>
              </v-card-text>
              <template v-slot:append>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="doAction('clear_filter')">清空</v-btn>
                  <v-btn color="secondary" text @click="doAction('set_filter')">确认</v-btn>
                </v-card-actions>
              </template>
            </v-navigation-drawer>
            <v-dialog v-model="dlgInfo" persistent scrollable max-width="600px">
              <widgets-enterprise-dialogs v-if="activeType === 'enterprise'" :id="activeId" :key="activeId" v-on:Close="doAction('close_info')"></widgets-enterprise-dialogs>
              <widgets-product-dialogs v-if="activeType === 'product'" :id="activeId" :key="activeId" v-on:Close="doAction('close_info')" v-on:Goto="doAction('goto', $event)"></widgets-product-dialogs>
              <v-card v-if="activeType === 'batch'">
                <v-card-title>
                  批量修改 - {{ query.enterprise ? hasEnterpriseList.find(item => item._id === query.enterprise).name : ''}}
                  <v-spacer></v-spacer>
                  <v-btn icon @click="doAction('close_info')">
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-form v-model="batchUpdate.valid" @submit.prevent="batchSubmit">
                    <v-row>
                      <v-col cols="12">
                        <v-row
                          no-gutters
                          v-for="(item, index) in batchUpdate.fields"
                          :key="'batch_field_'+index"
                        >
                          <v-col cols="10">
                            <v-text-field
                              v-if="item.name === 'name'"
                              v-model="item.value"
                              :rules="[rules.required]"
                              type="text"
                              label="设备名称"
                              hint="设备名称是该设备的标示，如开发电脑，新员工笔记本等"
                              outlined
                              dense
                            ></v-text-field>
                            <v-autocomplete
                              v-if="item.name === 'state'"
                              v-model="item.value"
                              :items="stateList"
                              :rules="[rules.selected]"
                              item-text="name"
                              item-value="_id"
                              autocomplete="off"
                              label="设备状态"
                              hint="设备状态用来记录当前设备的状态，可在系统后台维护状态类型。"
                              outlined
                              dense
                            ></v-autocomplete>
                            <v-autocomplete
                              v-if="item.name === 'product'"
                              v-model="item.value"
                              :items="productList"
                              :rules="[rules.selected]"
                              item-text="name"
                              item-value="_id"
                              autocomplete="off"
                              outlined
                              dense
                              label="关联产品"
                              hint="请选择设备关联的产品"
                            >
                              <template v-slot:item="data">
                                <v-list-item-content>
                                  <v-list-item-title>{{data.item.vendor.name}} - {{data.item.name}} - {{data.item.part_number}}</v-list-item-title>
                                  <v-list-item-subtitle
                                    v-if="data.item.it_info"
                                  >{{data.item.it_info.ram}}G - {{data.item.it_info.network}}</v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </v-autocomplete>
                            <v-autocomplete
                                v-if="item.name === 'account'"
                                v-model="item.value"
                                :items="itemAccountList"
                                :loading="itemAccountListLoading"
                                :search-input.sync="searchItemAccount"
                                cache-items
                                item-text="personal.name"
                                item-value="_id"
                                autocomplete="off"
                                outlined
                                dense
                                label="使用人员"
                                hint="请选择设备使用人员"
                                clearable
                            >
                              <template v-slot:item="data">
                                <v-list-item-content>
                                  <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                                  <v-list-item-subtitle>{{data.item.username}} - {{data.item.phone}}</v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </v-autocomplete>
                            <v-text-field
                              v-if="item.name === 'asset_from'"
                              v-model="item.value"
                              type="text"
                              label="设备来源"
                              hint="设备获得方式，如采购、回收等"
                              outlined
                              dense
                            ></v-text-field>
                            <v-combobox
                              v-if="item.name === 'label'"
                              v-model="item.value"
                              label="设备标签"
                              hint="使用回车键可以输入多个标签。如内部设备代码、编码等信息"
                              deletable-chips
                              multiple
                              small-chips
                              outlined
                              dense
                            ></v-combobox>
                            <v-autocomplete
                              v-if="item.name === 'asset_type'"
                              autocomplete="off"
                              v-model="item.value"
                              :items="assetType"
                              label="设备属性"
                              hint="请选择设备属性"
                              item-text="name"
                              item-value="code"
                              outlined
                              dense
                            ></v-autocomplete>
                            <v-autocomplete
                              v-if="item.name === 'asset_state'"
                              autocomplete="off"
                              v-model="item.value"
                              :items="assetState"
                              label="设备情况"
                              hint="请选择设备属性"
                              item-text="name"
                              item-value="code"
                              outlined
                              dense
                            ></v-autocomplete>
                            <v-autocomplete
                              v-if="item.name === 'guaranteed_type'"
                              autocomplete="off"
                              v-model="item.value"
                              :items="guaranteedType"
                              label="延保类型"
                              hint="请选择延保类型"
                              item-text="name"
                              item-value="code"
                              outlined
                              dense
                              deletable-chips
                              multiple
                              small-chips
                            ></v-autocomplete>
                            <v-menu
                              v-if="item.name === 'guaranteed_time'"
                              v-model="batchUpdate.guaranteed_time"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="item.value"
                                  label="延保开始时间"
                                  readonly
                                  v-bind="attrs"
                                  outlined
                                  dense
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="item.value"
                                @input="batchUpdate.guaranteed_time = false"
                                no-title
                                scrollable
                              ></v-date-picker>
                            </v-menu>
                            <v-text-field
                              v-if="item.name === 'guaranteed_limit'"
                              v-model.number="item.value"
                              suffix="月"
                              type="number"
                              label="延保期限"
                              hint="延保期限单位月"
                              outlined
                              dense
                            ></v-text-field>
                            <v-menu
                              v-if="item.name === 'purchase_time'"
                              v-model="batchUpdate.purchase_time"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="item.value"
                                  label="购买日期"
                                  readonly
                                  v-bind="attrs"
                                  outlined
                                  dense
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="item.value"
                                @input="batchUpdate.purchase_time = false"
                                no-title
                                scrollable
                              ></v-date-picker>
                            </v-menu>
                            <v-text-field
                              v-if="item.name === 'warranty_time'"
                              v-model.number="item.value"
                              suffix="月"
                              type="number"
                              label="厂商质保期"
                              hint="厂商质保期在产品管理中可以设置默认值"
                              outlined
                              dense
                            ></v-text-field>
                            <v-autocomplete
                              v-if="item.name === 'warehouse'"
                              v-model="item.value"
                              :items="warehouseList"
                              item-text="name"
                              item-value="_id"
                              autocomplete="off"
                              outlined
                              dense
                              clearable
                              label="仓库"
                              hint="请选择设备所在仓库"
                            >
                              <template v-slot:item="data">
                                <v-list-item-content>
                                  <v-list-item-title>{{data.item.name}} - {{data.item.center_code}}</v-list-item-title>
                                  <v-list-item-subtitle>{{data.item.address}}</v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </v-autocomplete>
                            <v-menu
                              v-if="item.name === 'warehouse_time'"
                              v-model="batchUpdate.warehouse_time"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="item.value"
                                  label="入库日期"
                                  readonly
                                  v-bind="attrs"
                                  outlined
                                  dense
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="item.value"
                                @input="batchUpdate.warehouse_time = false"
                                no-title
                                scrollable
                              ></v-date-picker>
                            </v-menu>
                            <v-textarea
                              v-if="item.name === 'remarks'"
                              v-model="item.value"
                              label="备注"
                              hint="请输入设备备注信息"
                              rows="4"
                              outlined
                              dense
                            ></v-textarea>
                          </v-col>
                          <v-col cols="2" class="text-right">
                            <v-btn icon @click="doAction('remove_batch_field', index)">
                              <v-icon>remove</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions v-if="currentUser.hasAdmin.includes('enterprise') && currentUser.employer.ownerId.self_asset">
                  <v-menu max-height="250px" top offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn text v-bind="attrs" v-on="on"><v-icon left>add</v-icon>增加批量修改字段</v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item
                        v-for="(item, index) in filterSelected(batchUpdate.assetFields, batchUpdate.fields)"
                        :key="index"
                        link
                        @click="doAction('add_batch_field', item)"
                      >
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-spacer></v-spacer>

                  <v-btn text @click="batchUpdate.showAsset = !batchUpdate.showAsset">
                    <v-subheader>批量处理设备：{{selectedItem.length}}</v-subheader>
                    <v-icon right>{{ batchUpdate.showAsset ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</v-icon>
                  </v-btn>
                  <v-btn text @click="doAction('close_info')">取消</v-btn>
                  <v-btn
                    text
                    color="secondary"
                    @click="batchSubmit"
                    :disabled="!batchUpdate.valid"
                  >确认</v-btn>
                </v-card-actions>
                <v-expand-transition>
                  <div v-show="batchUpdate.showAsset">
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-chip
                        class="ma-2"
                        close
                        label
                        small
                        v-for="(selected, index) in selectedItem"
                        :key="selected._id"
                        @click:close="doAction('close_selected', index)"
                      >{{selected.name}}</v-chip>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
              <v-card v-if="activeType === 'import'">
                <v-card-title>
                  批量导入 - {{ query.enterprise ? hasEnterpriseList.find(item => item._id === query.enterprise).name : ''}}
                  <v-spacer></v-spacer>
                  <v-btn icon @click="doAction('close_info')"><v-icon>close</v-icon></v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="6">
                        <p>1. 下载设备模版，填写设备信息.</p>
                        <v-btn text @click="doAction('download_template')">下载模版<v-icon>file_download</v-icon></v-btn>
                      </v-col>
                      <v-col cols="6">
                        <input
                          id="import_file"
                          type="file"
                          name="import_file"
                          ref="uploadImport"
                          accept=".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          :multiple="false"
                          @change="importFiles($event)"/>
                        <p>2. 上传填好的文件.</p>
                        <v-btn text @click="doAction('upload_asset')" :loading="!importEnd"><v-icon left dark>file_upload</v-icon>上传文件</v-btn>
                      </v-col>
                    </v-row>
                    <v-row v-if="importFailed.length || importResult">
                      <v-col cols="12">
                        <v-divider></v-divider>
                        <v-alert
                          outlined
                          type="error"
                          text
                        >
                          导入失败：<span v-if="importFailed.length === 0">无</span><ul v-else><li v-for="(fail,index) in importFailed" :key="index">行：{{fail.line}} 错误：{{fail.error}}</li></ul>
                        </v-alert>
                        <v-alert
                          outlined
                          type="success"
                          text
                        >
                          成功创建：{{importResult.insertSum}} 条记录； 更新：{{importResult.updateSum}} 条记录；
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('Asset_Manage')">
                  <v-spacer></v-spacer>
                  <v-btn text @click="doAction('close_info')">关闭</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
        </div>
      </div>
    </div>
  </v-sheet>
</template>
<script>
import XLSX from 'xlsx'
import ApiService from "@/common/http";
import Util from "@/common/util";
import { mapGetters } from "vuex";
import store from "@/store";
import {
  FETCH_ASSET_LIST,
  FETCH_ASSETSTATE_LIST,
  FETCH_EMPLOYEE_LIST,
  FETCH_PRODUCT_LIST,
  FETCH_WAREHOUSE_LIST,
  FETCH_PRODUCTTYPE_LIST,
  FETCH_VENDOR_LIST,
  BATCH_ASSET,
  FETCH_FIELDS,
  FETCH_SERVICE_LIST,
} from "@/store/actions.type";
import { SET_ERROR } from "@/store/mutations.type";
import AccountNav from "@/components/accountnav";
export default {
  data() {
    return {
      rules: {
        required: value => value === 0 || !!value || "请输入内容.",
        phonenum: value => {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || "请输入正确的电话号码";
        },
        selected: value => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "错误的电子邮件地址";
        },
        name: v =>
          /^[A-Za-z][A-Za-z0-9@._]*$/.test(v) ||
          "用户名第一位必须为字母，其余字母加数组组合",
        max: val => (val || "").length <= 20 || "请输入4-20个字的用户名称",
        min: val => (val || "").length >= 4 || "请输入4-20个字的用户名称",
        confirmpass: () => {
          if (this.editedEmployee.password !== this.editedEmployee.repassword) {
            return "两次输入必须一致";
          } else {
            return true;
          }
        }
      },
      enterpriseList: [],
      filterDrawer: null,
      selectedItem: [],
      batchImport: {},
      batchUpdate: {
        valid: true,
        fields: [{ text: '设备状态', name: 'state', value: ''}],
        showAsset: false,
        guaranteed_time: false,
        purchase_time: false,
        warehouse_time: false,
        assetFields: [
          { text: '设备名称', value: 'name' },
          { text: '设备状态', value: 'state' },
          { text: '关联产品', value: 'product' },
          { text: "使用人", value: "account" },
          { text: "设备标签", value: "label" },
          { text: "设备来源", value: "asset_from" },
          { text: "设备情况", value: "asset_state" },
          { text: "延保类型", value: "guaranteed_type" },
          { text: "仓库", value: "warehouse" },
          { text: "入库时间", value: "warehouse_time" },
          { text: '设备备注', value: "remarks" },
        ]
      },
      arrBranch: [],
      menuRentalStartDate: false,
      menuRentalEndDate: false,
      query: {
        key: '',
        ownerType: [], // Dealer 标准服务 Enterprise 企业自有
        ownerId: '',
        enterprise: '', //企业
        branch: '', //分支机构
        account: '', // 员工
        owner: '', // 'dealer' 'rental' 'enterprise' 'personal'
        state: [], // 设备状态
        vendor: [], // 品牌
        productType: [], // 产品类型
        product: [], // 产品
        warehouse: [], // 仓库
        asset_state: [], // 设备情况 （良好、损坏）
        express_state: [], // 物流状态
        express_no: '', // 快递单号
        guaranteed_type: [], // 延保类型
        warranty_deadline: '', // 保修到期时间
        guaranteed_deadline: '', // 延保到期时间
        rental_deadline: '', // 租赁到期时间
        rental_create_startdate: '', // 起租开始日期
        rental_create_enddate: '', // 起租结束日期
        updateTime: '', // 设备更新时间
      },
      defaultQuery: {
        key: '',
        ownerType: [],
        ownerId: '',
        enterprise: '',
        branch: '',
        account: '',
        owner: '',
        state: [],
        vendor: [],
        productType: [],
        product: [],
        warehouse: [],
        asset_state: [],
        express_state: [],
        express_no: '',
        guaranteed_type: [],
        warranty_deadline: '',
        guaranteed_deadline: '',
        rental_deadline: '',
        rental_create_startdate: '',
        rental_create_enddate: '',
        updateTime: '',
      },
      headerItem: [],
      defaultHeader: [
        { text: '名称', value: 'name', width: "105px" },
        { text: '状态', value: 'state', width: "85px" },
        { text: '产品', value: 'product' },
        { text: '分公司', value: 'branch', width: "125px", align: " d-none" },
        { text: "使用人", value: "account", width: "125px" },
        { text: "序列号", value: "serial_number" },
        { text: "外部编号", value: "out_number", width: "125px", align: " d-none" },
        { text: "标签", value: "label", width: "125px" },
        { text: "来源", value: "asset_from", width: "125px", align: " d-none" },
        { text: "情况", value: "asset_state", width: "125px", align: " d-none" },
        { text: "企业PO", value: "order_info.po_number", width: "125px", sortable: false, align: " d-none" },
        { text: "采购时间", value: "purchase_time", width: "125px", align: " d-none" },
        { text: "质保期限", value: "warranty_time", width: "125px", align: " d-none" },
        { text: "质保到期", value: "warranty_deadline", width: "125px", align: " d-none" },
        { text: "延保类型", value: "guaranteed_type", width: "125px", align: " d-none" },
        { text: "延保开始时间", value: "guaranteed_time", width: "125px", align: " d-none" },
        { text: "延保期限", value: "guaranteed_limit", width: "125px", align: " d-none" },
        { text: "延保到期", value: "guaranteed_deadline", width: "125px", align: " d-none" },
        { text: "仓库", value: "warehouse", width: "125px", align: " d-none" },
        { text: "入库时间", value: "warehouse_time", width: "125px", align: " d-none" },
        { text: "快递单号", value: "transport.express_number", sortable: false, width: "125px", align: " d-none" },
        { text: "快递状态", value: "transport.state", sortable: false, width: "125px", align: " d-none" },
        { text: "创建时间", value: "createTime", width: "125px", align: " d-none" },
        { text: "更新时间", value: "updateTime", width: "125px" },
        { text: "收件人姓名", value: "transport.delivery_user", sortable: false, width: "125px", align: " d-none" },
        { text: "收件人电话", value: "transport.delivery_phone", sortable: false, width: "155px", align: " d-none" },
        { text: "收件人地址", value: "transport.delivery_address", sortable: false, width: "155px", align: " d-none" },
        { text: "租赁方式", value: "rent_info.rent_type", width: "105px", align: " d-none" },
        { text: "起租日期", value: "rent_info.lease_start", width: "125px", align: " d-none" },
        { text: "租期", value: "rent_info.lease_term", width: "95px", align: " d-none" },
        { text: "支付周期", value: "rent_info.payment_cycle", width: "105px", align: " d-none" },
        { text: "租赁期数", value: "rent_info.total_period", width: "95px", align: " d-none" },
        { text: "租赁到期", value: "rent_info.expiry_date", width: "125px", align: " d-none" }
      ],
      importExcelHeader: ['系统编号', '设备名称', '设备标签', '设备来源', '设备情况', '设备状态', '是否锁定',
        '分支机构', '外部编号',
        '用户姓名', '员工编号', '用户电话', '用户邮箱',
        '用户地址', '仓库', '入库时间',
        '购买日期', '质保期限',
        '延保类型', '延保开始时间', '延保期限',
        '产品型号', '设备序列号',
        'SIM卡号', 'MAC地址', '是否ABM', 'IMEI串号', 'Apple账号', 'Apple密码', '设备备注',
        '租赁方式', '客户付款日', '客户帐期', '起租日期', '租期-年', '支付周期',
        '租赁期数', '租赁到期'
      ],
      importExcelNotice: [['ESP设备批量新增和更新模版']],
      optionsItem: {},
      dlgHeader: false,
      dlgInfo: false,
      activeType: "",
      activeId: null,
      importResult: null,
      importFailed: [],
      importEnd: true,
      availableService: [],
      itemAccountList: [],
      itemAccountListLoading: false,
      searchItemAccount: null,
      queryAccountList: [],
      queryAccountListLoading: false,
      searchQueryAccount: null,
      downloading: false,
      assetFieldList: [],
      menuItem: [
        { title: '诊断', icon: 'pageview', link: '/sheet' },
        { title: '维修', icon: 'help', link: '/sheet' },
        { title: '回收', icon: 'settings_applications', link: '/sheet' },
        { title: '激活', icon: 'help', link: '/sheet' },
        { title: '发放', icon: 'help', link: '/sheet' },
        { title: '部署', icon: 'help', link: '/sheet' },
        { title: '运维', icon: 'help', link: '/sheet' },
      ],
      disSub: true,
      completeFields: false
    };
  },
  components: {
    AccountNav,
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_ASSETSTATE_LIST, "isEnable=true"),
      store.dispatch(FETCH_VENDOR_LIST, "limit=200&isEnable=true"),
      store.dispatch(FETCH_PRODUCTTYPE_LIST, ""),
      store.dispatch(FETCH_PRODUCT_LIST, 'limit=200&isEnable=true'),
      store.dispatch(FETCH_WAREHOUSE_LIST, "isEnable=true")
    ]).then(() => {
      next();
    });
  },
  created() {
    this.getList();
    this.getHeader("assetHeader", 3);
  },
  mounted() {},
  computed: {
    ...mapGetters([
      "currentUser",
      "stateList",
      "vendorList",
      "typeList",
      "productList",
      "warehouseList",
      "hasEnterpriseList",
      "assetList",
      "assetLoading",
      "assetCount",
      "catalogList",
      "serviceList",
      "customFields",
    ]),
    assetType() {
      return Util.categories("assetType");
    },
    assetState() {
      return Util.categories("assetState");
    },
    guaranteedType() {
      return Util.categories("guaranteedType");
    },
    expiryDate() {
      return Util.categories("expiry");
    },
    updateTime() {
      return Util.categories("updateTime");
    },
    expressState () {
      return Util.categories('expressState')
    },
    computedHeaders() {
      return this.headerItem.filter(item => !item.align)
    },
    assetOwner() {
      return Util.categories('assetOwner');
    },
    rentalDeadline() {
      return Util.categories('rentalDeadline');
    }
  },
  watch: {
    optionsItem: {
      handler() {
        this.getList(this.query);
      },
      deep: true
    },
    selectedItem(val) {
      val.length ? this.disSub = false : this.disSub = true
    },
    searchItemAccount(val) {
      this.itemAccountListLoading = true
      setTimeout(() => {
        store.dispatch(FETCH_EMPLOYEE_LIST, '&limit=50&enterprise='+ this.query.enterprise + '&key='+ val)
            .then((data) => {
              if (data.employees) this.itemAccountList = [...data.employees];
            })
            .catch(err => {
              store.commit(SET_ERROR, {msg: err});
            })
            .finally(() => (this.itemAccountListLoading = false))
      }, 500)
    },
    searchQueryAccount(val) {
      this.queryAccountListLoading = true
      setTimeout(() => {
        store.dispatch(FETCH_EMPLOYEE_LIST, '&limit=50&enterprise='+ this.query.enterprise + '&key='+ val)
            .then((data) => {
              if (data.employees) this.queryAccountList = [...data.employees];
            })
            .catch(err => {
              store.commit(SET_ERROR, {msg: err});
            })
            .finally(() => (this.queryAccountListLoading = false))
      }, 500)
    }
  },
  methods: {
    goBack() {
      this.$router.push({ path: "/asset" });
    },
    exportList(query = {}) {
      this.downloading = true
      // 获取分页信息
      const { sortBy, sortDesc, page } = this.optionsItem;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true){
          sort = '-'+ sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0){
        this.query = Object.assign(this.query, this.$route.query);
        if (this.query.state && !Array.isArray(this.query.state)) this.query.state = this.query.state.split(',')
        if (this.query.express_state && !Array.isArray(this.query.express_state)) this.query.express_state = this.query.express_state.split(',')
        if (this.query.productType && !Array.isArray(this.query.productType)) {
          let arrType = this.query.productType.split(',')
          if (this.query.typein) {
            let newType = this.typeList.filter(t => { return !arrType.find(a => a === t._id)})
            this.query.productType = newType.map(n => n._id)
          } else {
            this.query.productType = arrType
          }
        }
        if (this.query.enterprise) {
          store.dispatch(FETCH_EMPLOYEE_LIST, '&limit=100&enterprise='+ this.query.enterprise)
        }
      }
      if (this.currentUser.employer.ownerId && this.currentUser.employer.ownerId.branch) this.arrBranch = [...this.currentUser.employer.ownerId.branch]
      let urlQuery = '';
      if (query.key) urlQuery += '&key='+ query.key
      if (this.query.ownerType.length) urlQuery += '&ownerType='+ query.ownerType
      if (query.ownerId) urlQuery += '&ownerId='+ query.ownerId
      if (query.enterprise) urlQuery += '&enterprise='+ query.enterprise
      if (query.branch) urlQuery += '&branch='+ query.branch
      if (query.account) urlQuery += '&account='+ query.account
      if (query.owner) urlQuery += '&owner='+ query.owner
      if (this.query.state.length) urlQuery += '&state='+ this.query.state
      if (this.query.express_state.length) urlQuery += '&express_state='+ this.query.express_state
      if (this.query.vendor.length) urlQuery += '&vendor='+ this.query.vendor
      if (this.query.productType.length) urlQuery += '&product_type='+ this.query.productType
      if (this.query.product.length) urlQuery += '&product='+ this.query.product
      if (this.query.warehouse.length) urlQuery += '&warehouse='+ this.query.warehouse
      if (this.query.asset_state.length) urlQuery += '&asset_state='+ this.query.asset_state
      if (this.query.guaranteed_type.length) urlQuery += '&guaranteed_type='+ this.query.guaranteed_type
      if (this.query.warranty_deadline) urlQuery += '&warranty_deadline='+ this.query.warranty_deadline
      if (this.query.guaranteed_deadline) urlQuery += '&guaranteed_deadline='+ this.query.guaranteed_deadline
      if (this.query.rental_deadline) urlQuery += '&rental_deadline='+ this.query.rental_deadline
      if (this.query.rental_create_startdate) urlQuery += '&rental_create_startdate='+ this.query.rental_create_startdate
      if (this.query.rental_create_enddate) urlQuery += '&rental_create_enddate='+ this.query.rental_create_enddate
      if (this.query.updateTime) urlQuery += '&updateTime='+ this.query.updateTime
      if (this.query.express_no) urlQuery += '&express_no='+ this.query.express_no

      let arrQuery = Object.keys(this.query) || []
      if (arrQuery && arrQuery.length) {
        for (const q of arrQuery) {
          if (q.length === 24 && this.query[q]) urlQuery += '&'+ q +'='+ this.query[q]
        }
      }

      if (Object.keys(this.optionsItem).length > 0) {
        ApiService.download('/asset/export?page=' + page + '&limit=-1&sort=' + sort + urlQuery, {
          responseType:'blob'
        })
          .then(resp => {
            let fileName = this.hasEnterpriseList.find(item => item._id === query.enterprise).name +'_资产设备清单.xlsx';
            let contentDisposition = resp.headers['content-disposition'];
            if (contentDisposition) {
              fileName = window.decodeURI(resp.headers['content-disposition'].split('=')[1]);
            }
            let blob = new Blob([resp.data], {type: 'application/octet-stream'});
            if (window.navigator.msSaveOrOpenBlob) { //支持IE
              navigator.msSaveBlob(blob, fileName);
            } else {
              let link = document.createElement('a');
              link.style.display = "none";
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              document.body.appendChild(link);
              link.click();
              this.downloading = false
              //释放内存
              window.URL.revokeObjectURL(link.href);
              document.body.removeChild(link);
            }
          })
          .catch(err => { // 请求失败处理
            this.downloading = false
            store.commit(SET_ERROR, {msg: err});
          });
      }
    },
    getList(query = {}) {
      // 获取分页信息
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItem;
      let sort = "";
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = "-" + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0){
        this.query = Object.assign(this.query, this.$route.query);
        if (this.query.state && !Array.isArray(this.query.state)) this.query.state = this.query.state.split(',')
        if (this.query.express_state && !Array.isArray(this.query.express_state)) this.query.express_state = this.query.express_state.split(',')
        if (this.query.productType && !Array.isArray(this.query.productType)) {
          let arrType = this.query.productType.split(',')
          if (this.query.typein) {
            let newType = this.typeList.filter(t => { return !arrType.find(a => a === t._id)})
            this.query.productType = newType.map(n => n._id)
          } else {
            this.query.productType = arrType
          }
        }
      }
      let urlQuery = "";
      if (query.key) urlQuery += "&key=" + query.key;
      if (this.query.ownerType.length) urlQuery += "&ownerType=" + query.ownerType;
      if (query.ownerId) urlQuery += "&ownerId=" + query.ownerId;
      if (query.enterprise) urlQuery += "&enterprise=" + query.enterprise;
      if (query.branch) urlQuery += '&branch='+ query.branch
      if (query.account) urlQuery += "&account=" + query.account;
      if (query.owner) urlQuery += '&owner='+ query.owner;
      if (this.query.state.length) urlQuery += "&state=" + this.query.state;
      if (this.query.express_state.length) urlQuery += '&express_state='+ this.query.express_state;
      if (this.query.vendor.length) urlQuery += "&vendor=" + this.query.vendor;
      if (this.query.productType.length) urlQuery += "&product_type=" + this.query.productType;
      if (this.query.product.length) urlQuery += "&product=" + this.query.product;
      if (this.query.warehouse.length) urlQuery += "&warehouse=" + this.query.warehouse;
      if (this.query.asset_state.length) urlQuery += "&asset_state=" + this.query.asset_state;
      if (this.query.guaranteed_type.length) urlQuery += "&guaranteed_type=" + this.query.guaranteed_type;
      if (this.query.warranty_deadline) urlQuery += "&warranty_deadline=" + this.query.warranty_deadline;
      if (this.query.guaranteed_deadline) urlQuery += "&guaranteed_deadline=" + this.query.guaranteed_deadline;
      if (this.query.rental_deadline) urlQuery += '&rental_deadline='+ this.query.rental_deadline;
      if (this.query.rental_create_startdate) urlQuery += '&rental_create_startdate='+ this.query.rental_create_startdate
      if (this.query.rental_create_enddate) urlQuery += '&rental_create_enddate='+ this.query.rental_create_enddate
      if (this.query.updateTime) urlQuery += '&updateTime='+ this.query.updateTime
      if (this.query.express_no) urlQuery += '&express_no='+ this.query.express_no

      let arrQuery = Object.keys(this.query) || []
      if (arrQuery && arrQuery.length) {
        for (const q of arrQuery) {
          if (q.length === 24 && this.query[q]) urlQuery += '&'+ q +'='+ this.query[q]
        }
      }

      if (Object.keys(this.optionsItem).length > 0) {
        store.dispatch(FETCH_ASSET_LIST, "page=" + page + "&limit=" + itemsPerPage + "&sort=" + sort + urlQuery)
        .then(() =>{
          this.creatEnterpriseList();
        });
      }
    },
    searchAsset(query) {
      Object.assign(this.query, query)
      this.getList()
    },
    getHeader(model, version) {
      if (!this.completeFields) {
        let enterpriseFields = this.currentUser.employer.ownerId.asset_field || []
        if (enterpriseFields && enterpriseFields.length) this.assetFieldList = [...enterpriseFields]
        this.defaultHeader = this.defaultHeader.concat(...this.assetFieldList.map(f => {
          return {
            text: f.name,
            value: f._id,
            width: "145px"
          }
        }))
      }
      let userHeader = this.currentUser[model];
      if (userHeader.content.length) {
        if (userHeader.version !== version) {
          this.headerItem = JSON.parse(JSON.stringify(this.defaultHeader));
        } else {
          this.headerItem = JSON.parse(JSON.stringify(userHeader.content));
        }
      } else {
        this.headerItem = JSON.parse(JSON.stringify(this.defaultHeader));
      }
      this.completeFields = true
    },
    doAction(action, item = {}) {
      switch (action) {
        case "search_item": {
          if (this.query.key !== "") {
            this.getList({ key: this.query.key });
          } else {
            this.getList();
          }
          break;
        }
        case 'export_item': {
          if (!this.downloading) {
            this.exportList(this.query)
          }
          break
        }
        case "filter_item": {
          this.filterDrawer = !this.filterDrawer;
          break;
        }
        case "create_item": {
          this.$router.push({ path: "/equip/edit/0" });
          break;
        }
        case "set_filter": {
          this.$router.push({ query: {} });
          this.getList(this.query);
          this.filterDrawer = !this.filterDrawer;
          break;
        }
        case "clear_filter": {
          this.query = Object.assign({}, this.defaultQuery);
          this.$router.push({ query: {} });
          this.getList();
          this.filterDrawer = !this.filterDrawer;
          this.assetFieldList = []
          break;
        }
        case "open_enterprise": {
          this.activeType = "enterprise";
          this.activeId = item._id;
          this.dlgInfo = true;
          break;
        }
        case "open_product": {
          this.activeType = "product";
          this.activeId = item._id;
          this.dlgInfo = true;
          break;
        }
        case "close_info": {
          this.dlgInfo = false;
          this.importResult = null
          this.importFailed.length = 0
          this.importEnd = true
          this.getList(this.query);
          break;
        }
        case "cancel_header": {
          this.getHeader("assetHeader",3);
          this.$forceUpdate();
          break;
        }
        case "default_header": {
          break;
        }
        case "set_header": {
          this.getHeader("assetHeader",3);
          this.$forceUpdate();
          break;
        }
        case "goto": {
          this.dlgInfo = false;
          this.$router.push({ path: "/equip?" + item });
          break;
        }
        case "set_product_list": {
          let strQuery = "isEnable=true&limit=100";
          if (this.query.vendor) strQuery += "&vendor=" + this.query.vendor;
          if (this.query.productType) strQuery += "&type=" + this.query.productType;
          store.dispatch(FETCH_PRODUCT_LIST, strQuery);
          break;
        }
        case "edit_batch": {
          let hasLock = this.selectedItem.find(o => o.isLock);
          let hasEnterprise = this.selectedItem.find(o => o.ownerType === "Enterprise");
          if (hasLock) {
            store.commit(SET_ERROR, {
              msg: "锁定状态的设备不能被批量修改，请取消锁定设备的选择后修改。"
            });
            return;
          }
          if (hasEnterprise) {
            store.commit(SET_ERROR, {
              msg:
                  "企业自有设备不能被批量修改，请取消企业自有设备的选择后继修改。"
            });
            return;
          }
          this.batchUpdate.valid = true;
          this.batchUpdate.fields = [{ text: "状态", name: "state", value: "" }];
          this.batchUpdate.showAsset = false;
          this.batchUpdate.guaranteed_time = false;
          this.batchUpdate.purchase_time = false;
          this.batchUpdate.warehouse_time = false;
          this.activeType = "batch";
          this.dlgInfo = true;
          break;
        }
        case "close_selected": {
          this.selectedItem.splice(item, 1);
          if (!this.selectedItem.length) this.doAction("close_info");
          break;
        }
        case "add_batch_field": {
          this.batchUpdate.fields.push({
            text: item.text,
            name: item.value,
            value: ""
          });
          break;
        }
        case "remove_batch_field": {
          this.batchUpdate.fields.splice(item, 1);
          break;
        }
        case "import_item": {
          this.activeType = "import";
          this.dlgInfo = true;
          break;
        }
        case 'download_template': {
          store.dispatch(FETCH_FIELDS, this.query.enterprise+'/Asset')
              .then(() => {
                this.customFields.forEach(item => {
                  if (!this.importExcelHeader.includes(item.name)) this.importExcelHeader.push(item.name)
                })

                let ws = XLSX.utils.aoa_to_sheet(this.importExcelNotice)
                XLSX.utils.sheet_add_json(ws, [
                  {}
                ], {header: this.importExcelHeader, origin: 'A2'})

                ws['!merges'] = [{s: {r:0, c:0}, e: {r:0, c:this.importExcelHeader.length - 1}}]
                ws.A1.s = {alignment:{ wrapText: true }};
                ws.A2.c = [{t: '*系统编号，导出设备时系统自动写入，批量更新时使用。新增设备时无需填写。'}];
                ws.A2.c.hidden = true;
                ws.B2.c = [{t: '*设备名称，用于简要描述设备的信息。'}];
                ws.B2.c.hidden = true;
                ws.F2.c = [{t: '*设备状态，设备当前的状态属性，可通过系统在线查询状态列表后填写。'}];
                ws.F2.c.hidden = true;
                ws.V2.c = [{t: '*产品型号，设备所属的具体产品，可通过系统在线查询产品列表后填写。'}];
                ws.V2.c.hidden = true;
                ws.W2.c = [{t: '*设备序列号，设备的唯一标识信息，新增设备时不能与现有设备序列号重复。'}];
                ws.W2.c.hidden = true;
                ws.AE2.c = [{t: '*租赁方式，租赁设备必填。二选一内容：融资租赁、经营性租赁。'}];
                ws.AE2.c.hidden = true;

                let wb = XLSX.utils.book_new()
                XLSX.utils.book_append_sheet(wb, ws, 'Import_Asset')
                XLSX.writeFile(wb, this.hasEnterpriseList.find(item => item._id === this.query.enterprise).name +'_设备模版.xlsx')
              })
          break
        }
        case 'upload_asset': {
          this.$refs.uploadImport.click()
          break
        }

        default: {
          break;
        }
      }
    },
    async batchSubmit() {
      store.dispatch(BATCH_ASSET, {assetIds: this.selectedItem.map(o => o._id), fields: this.batchUpdate.fields})
          .then(() => {
            store.commit(SET_ERROR, {msg: '修改完成', color: 'primary'});
            this.doAction('close_info')
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
    },
    filterSelected(arrAll = [], arrSelected = []) {
      return arrAll.filter(
          field => !arrSelected.find(selected => selected.name === field.value)
      );
    },
    importFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.importAsset(fileList[x]);
      })
    },
    importAsset(file) {
      this.importEnd = false
      let param = new FormData();
      param.append('file', file);
      param.append('enterprise', this.query.enterprise);
      ApiService.post('/asset/import', param)
          .then((data) => {
            this.importResult = data.data.Success
            this.importFailed = data.data.Failed
            this.$refs.uploadImport.value = '';
            this.importEnd = true
          })
          .catch((err) => {
            this.$refs.uploadImport.value = '';
            this.importResult = { updateSum: 0, insertSum: 0 }
            this.importFailed = []
            this.importEnd = true
            this.importFailed = [{line: 2, error: err.response.data.message}]
          });
      this.getList(this.query)
    },
    getServiceCatalog(enterprise) {
      store.dispatch(FETCH_SERVICE_LIST, enterprise)
          .then((data) => {
            if (data && data.length) {
              let dataList = data.map(item => item.catalog)
              dataList = dataList.reduce((a, b) => {return a.concat(b)})
              this.availableService = this.uniqueByKey(dataList, '_id')
            }
          })
    },
    uniqueByKey(arrList = [], strKey = 'id') {
      let objHash = {}
      let result = arrList.reduce((total, current) => {
        if (!objHash[current[strKey]]) {
          objHash[current[strKey]] = true
          total.push(current)
        }
        return total
      }, [])
      return result
    },
    confirmAplly(item) {
      let select_item = [], strURL = '/sheet?enterprise='
      if (this.selectedItem && this.selectedItem.length) {
        if (this.selectedItem.length > 10){
          store.commit(SET_ERROR, {msg: '批量创建工单最多选择10台设备。'});
        } else {
          this.selectedItem.forEach(item => {
            select_item.push(item._id)
          })
          if (item.parentId.service_type === 'asset_repair') strURL = '/workbench/repair/edit?enterprise='
          this.$router.push({path: strURL + this.query.enterprise +'&service='+ item._id +'&asset='+ select_item})
        }
      }
    },
    creatEnterpriseList() {
      const enterpriseId = this.currentUser.employer.ownerId._id;
      this.query.enterprise = enterpriseId;
      let objEnterprise = this.hasEnterpriseList.find(v => v._id === enterpriseId)
      if (objEnterprise && objEnterprise.branch) this.arrBranch = [...objEnterprise.branch]
      this.getServiceCatalog(enterpriseId);
    },
    isChangeAble(item) {
      if (item.ownerType == "Dealer") {
        if (this.currentUser.employer.ownerId.self_asset) {
          this.$router.push({ path: "/equip/edit/" + item._id });
        } else {
          store.commit(SET_ERROR, {
            msg: "企业无资产管理权限，请联系经销商获取详细信息。"
          });
        }
      } else if (item.ownerType == "Enterprise") {
        if (item.ownerId._id == this.currentUser.employer.ownerId._id) {
          this.$router.push({ path: "/equip/edit/" + item._id });
        } else {
          store.commit(SET_ERROR, {
            msg: "企业无资产管理权限，请联系经销商获取详细信息。"
          });
        }
      } else {
        this.$router.push({ path: "/equip/edit/" + item._id });
      }
    },
    row_class(item) {
      if (item.guaranteed_deadline && new Date(item.guaranteed_deadline) <= new Date()) return "red lighten-4"
      if (item.warranty_deadline && new Date(item.warranty_deadline) <= new Date()) return "red lighten-4"
      if (item.rent_info && item.rent_info.expiry_date && new Date(item.rent_info.expiry_date) <= new Date()) return "red lighten-4"
    }
  }
};
</script>
<style scoped>
.nowrap {
  white-space:nowrap; overflow:hidden; text-overflow:ellipsis;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
