<template>
  <v-card v-if="itemData && !itemData.non_service" outlined>
    <v-toolbar flat color="#009fe9" dark>
      设备服务单
<!--      <v-chip class="mx-4" v-if="itemData.status === 0" dark color="deep-orange">待受理</v-chip>-->
<!--      <v-chip class="mx-4" v-else-if="itemData.status === 1" dark color="light-blue">处理中</v-chip>-->
<!--      <v-chip class="mx-4" v-else-if="itemData.status === 2" dark color="green">已完成</v-chip>-->
<!--      <v-chip class="mx-4" v-else>已取消</v-chip>-->
    </v-toolbar>
    <v-card-text>
      <v-alert
          text
          outlined
          color="#009fe9"
          class="my-4"
          dark
          icon="sms">
        请在电脑中使用浏览器访问：https://esp.mdaas.com 查看更多信息。
      </v-alert>
      <v-expansion-panels :value="1">
        <v-expansion-panel>
          <v-expansion-panel-header>
            工单信息
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list two-line>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>post_add</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{itemData.name}}</v-list-item-title>
                  <v-list-item-subtitle>服务类型</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>numbers</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{itemData.case_number}}</v-list-item-title>
                  <v-list-item-subtitle>服务单号</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>alt_route</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{itemData.out_number}}</v-list-item-title>
                  <v-list-item-subtitle>外部单号</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>edit_note</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{itemData.creator}}</v-list-item-title>
                  <v-list-item-subtitle>发起人</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>more_time</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{itemData.createTime | formatTime}}</v-list-item-title>
                  <v-list-item-subtitle>申请时间</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>person_outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{itemData.account}}</v-list-item-title>
                  <v-list-item-subtitle>使用者</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>playlist_add_check</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-if="itemData.status === 0">待受理</v-list-item-title>
                  <v-list-item-title v-else-if="itemData.status === 1">处理中</v-list-item-title>
                  <v-list-item-title v-else-if="itemData.status === 2">已完成</v-list-item-title>
                  <v-list-item-title v-else>已取消</v-list-item-title>
                  <v-list-item-subtitle>当前状态</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>update</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{itemData.updateTime | formatTime}}</v-list-item-title>
                  <v-list-item-subtitle>更新时间</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            处理过程
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-timeline dense clipped>
              <v-timeline-item
                  small
                  v-for="(item, index) in itemData.taskList"
                  :key="'task_'+index"
                  :color="item.active ? '#009fe9' : item.status === 2 ? 'green' : 'grey'"
                  :icon="item.status === 2 ? 'done' : ''"
              >
                <strong>{{item.name}}</strong>
                <div class="text-caption" v-if="item.active">进行中...</div>
                <div class="text-caption" v-else-if="item.time">{{item.time | formatTime}}</div>
                <div class="text-caption" v-else></div>
              </v-timeline-item>
            </v-timeline>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
  <v-card v-else>
    <v-toolbar flat color="#009fe9" dark>
      非服务支持设备：{{itemData && itemData.case_number}}
    </v-toolbar>
    <v-card-text>
      <v-alert
          text
          outlined
          color="#009fe9"
          class="my-4"
          dark
          icon="sms">
        抱歉，您提交的设备服务目前不在服务范围内，如有服务需求，请与我们联系4001505665。
      </v-alert>
    </v-card-text>
  </v-card>
</template>
<script>
import store from "@/store";
import {
  OVERVIEW_SERVICE_CASE,
} from "@/store/actions.type";

export default {
  data() {
    return {
      itemData: null,
    }
  },
  created() {
  },
  mounted() {
    if (this.$route.params.id) this.getItem(this.$route.params.id)
  },
  computed: {
  },
  methods: {
    getItem(id) {
      store.dispatch(OVERVIEW_SERVICE_CASE, { id })
          .then((data) => {
            this.itemData = data
          })
    }
  }
}
</script>
