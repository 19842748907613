<template>
  <div>
    <element-banner></element-banner>
    <v-row no-gutters v-for="(item, i) in page_content" :key="i" style="position: relative">
      <!--<div class="float-left mb-n6" style="align-self:center;"><v-icon class="handle" small style="cursor: move"  color="grey lighten-2">drag_indicator</v-icon></div>-->
      <v-col cols="12">
        <element-text
          v-if="item.type === 'text'"
          :key="i"
          :index="i"
          :id="pageId"
          :element="item"
          v-on:DeleteItem="getPageInfo('body')"
          v-on:UpdateItem="getPageInfo('body')"
        ></element-text>
        <element-carousel
          v-if="item.type === 'carousel'"
          :key="i"
          :index="i"
          :id="pageId"
          :element="item"
          v-on:DeleteItem="getPageInfo('body')"
          v-on:UpdateItem="getPageInfo('body')"
        ></element-carousel>
        <element-service
          v-if="item.type === 'service'"
          :key="i"
          :index="i"
          :id="pageId"
          :element="item"
          v-on:DeleteItem="getPageInfo('body')"
          v-on:UpdateItem="getPageInfo('body')"
        ></element-service>
        <element-material
          v-if="item.type === 'material'"
          :key="i"
          :index="i"
          :id="pageId"
          :element="item"
          v-on:DeleteItem="getPageInfo('body')"
          v-on:UpdateItem="getPageInfo('body')"
        ></element-material>
        <element-image
          v-if="item.type === 'image'"
          :key="i"
          :index="i"
          :id="pageId"
          :element="item"
          v-on:DeleteItem="getPageInfo('body')"
          v-on:UpdateItem="getPageInfo('body')"
        ></element-image>
        <element-horizontal
          v-if="item.type === 'horizontal'"
          :key="i"
          :index="i"
          :id="pageId"
          :element="item"
          v-on:DeleteItem="getPageInfo('body')"
          v-on:UpdateItem="getPageInfo('body')"
        ></element-horizontal>
        <element-onerowcard
          v-if="item.type === 'onerowcard'"
          :key="i"
          :index="i"
          :id="pageId"
          :element="item"
          v-on:DeleteItem="getPageInfo('body')"
          v-on:UpdateItem="getPageInfo('body')"
        ></element-onerowcard>
        <element-fourcard
          v-if="item.type === 'fourcard'"
          :key="i"
          :index="i"
          :id="pageId"
          :element="item"
          v-on:DeleteItem="getPageInfo('body')"
          v-on:UpdateItem="getPageInfo('body')"
        ></element-fourcard>
        <element-twocolcard
          v-if="item.type === 'twocolcard'"
          :key="i"
          :index="i"
          :id="pageId"
          :element="item"
          v-on:DeleteItem="getPageInfo('body')"
          v-on:UpdateItem="getPageInfo('body')"
        ></element-twocolcard>
        <element-threecolcard
          v-if="item.type === 'threecolcard'"
          :key="i"
          :index="i"
          :id="pageId"
          :element="item"
          v-on:DeleteItem="getPageInfo('body')"
          v-on:UpdateItem="getPageInfo('body')"
        ></element-threecolcard>
        <element-threecard
          v-if="item.type === 'threecard'"
          :key="i"
          :index="i"
          :id="pageId"
          :element="item"
          v-on:DeleteItem="getPageInfo('body')"
          v-on:UpdateItem="getPageInfo('body')"
        ></element-threecard>
        <element-twocard
          v-if="item.type === 'twocard'"
          :key="i"
          :index="i"
          :id="pageId"
          :element="item"
          v-on:DeleteItem="getPageInfo('body')"
          v-on:UpdateItem="getPageInfo('body')"
        ></element-twocard>
      </v-col>
    </v-row>
  </div>
  <!-- <div>
    <section id="hero">
      <v-carousel
        :show-arrows="false"
        hide-delimiter-background
        delimiter-icon="mdi-minus"
        hide-delimiters
      >
        <v-carousel-item :src="require('@/assets/banner1.jpg')">
          <div style="height: 100%; max-width: 1280px; margin: 0 auto;">
            <v-row class="fill-height" justify="start" align="center">
              <div class="title-con">
                <div class="tit">企业级服务的专属平台</div>

                <v-divider class="mb-3 mt-3" />

                <div class="mb-4">
                  <p class="stit">高效省时的IT服务，随您定制的解决方案</p>
                </div>
                <div class="mb-4">
                  <v-btn depressed color="secondary">立即注册</v-btn>
                  <v-btn text color="secondary" class="mx-2">登录</v-btn>
                </div>
              </div>
            </v-row>
          </div>
        </v-carousel-item>
      </v-carousel>
    </section>
    <div class="lay-mid">
      <div class="wrapper">
        <div class="content">
          <div class="homepage">
            <div class="game-colum" id="wow1">
              <div class="game-promo" @click="$router.push('/event')">
                <div class="game-tip">服务名称</div>
              </div>
            </div>
            <div class="game-colum" id="wow2">
              <div class="game-promo">
                <div class="game-tip">服务名称</div>
              </div>
            </div>
            <div class="game-colum" id="wow3">
              <div class="game-promo">
                <div class="game-tip">服务名称</div>
              </div>
            </div>
            <div class="game-colum" id="wow4">
              <div class="game-promo">
                <div class="game-tip">服务名称</div>
              </div>
            </div>
            <div class="game-colum" id="wow5">
              <div class="game-promo">
                <div class="game-tip">服务名称</div>
              </div>
            </div>
            <div class="game-colum" id="wow6">
              <div class="game-promo">
                <div class="game-tip">服务名称</div>
              </div>
            </div>
            <div class="game-colum" id="wow7">
              <div class="game-promo">
                <div class="game-tip">魔兽世界</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="con-mid">
      <div class="mid-wrapper">
        <v-slide-group show-arrows>
          <v-slide-item v-for="n in 8" :key="n">
            <v-card class="ma-4" height="280" width="136" flat rounded="0">
              <div class="nav-card-con" @click="$router.push('/event')">
                <div class="tit">服务名称</div>
                <div class="img">
                  <v-img :src="require('@/assets/nav/n'+ n +'.jpg')" cover height="100%"></v-img>
                </div>
              </div>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </div>
    </section>
  </div>-->
</template>

<script>
import { FETCH_PAGE } from "@/store/actions.type";
export default {
  data: () => ({
    switch_off: false,
    switch_on: true,
    showGameCard: false,
    card: [
      { color: "primary", icon: "mdi-heart" },
      { color: "secondary", icon: "mdi-heart" },
      { color: "accent", icon: "mdi-heart" },
      { color: "error", icon: "mdi-heart" },
      { color: "info", icon: "mdi-heart" },
      { color: "success", icon: "mdi-heart" },
      { color: "warning", icon: "mdi-heart" },
      { color: "primary", icon: "mdi-heart" },
      { color: "secondary", icon: "mdi-heart" }
    ],
    pageId: '',
    page_content: [],
  }),
  created() {
    this.getPageInfo();
  },
  methods: {
    expandCard() {
      this.showGameCard = !this.showGameCard;
    },
    getPageInfo(component = "body") {
      this.$store
        .dispatch(FETCH_PAGE, "?name=home&component=" + component)
        .then(data => {
          this.pageId = data._id;
          this.page_content = data.config;
        });
    }
  }
};
</script>
<style scoped>
.con-mid {
  position: relative;
  z-index: 99;
  margin-top: -60px;
  padding-bottom: 40px;
}
.nav-card-con {
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
}
.nav-card-con .tit {
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 0.85rem;
  color: #b4b4b4;
  background-color: #424242;
  transition: all 0.3s;
}
.nav-card-con:hover .tit {
  color: #fff;
  background-color: #000;
}
.nav-card-con .img {
  flex-grow: 1;
}
.mid-wrapper {
  max-width: 1280px;
  margin: 0 auto;
}
.wrapper {
  width: 1280px;
  margin: 0 auto;
}
.wrapper .header .blogo {
  width: 402px;
  height: 92px;
}
.section {
  margin-top: 10px;
  padding-bottom: 120px;
}
.section .sleft {
  width: 670px;
  padding-top: 40px;
  padding-left: 22px;
}
.section .sleft h2 {
  font-size: 28px;
  /* color: #62737E; */
  color: #000;
  cursor: pointer;
  margin-bottom: 20px;
}
.section .sleft h2:hover {
  color: #fff;
}
.section .sleft h2 span {
  color: #000;
  display: inline-block;
  font-size: 14px;
  border: 1px solid #3a4c53;
  position: relative;
  top: -5px;
  padding: 2px 5px;
  border-radius: 5px;
}
.lay-mid {
  background-color: rgba(157, 157, 157, 0.5);
}
.lay-mid .content {
  min-height: 350px;
  padding: 15px 0;
  margin-bottom: 100px;
}
.lay-mid .content .homepage .game-colum {
  position: relative;
  float: left;
  width: 162px;
  padding-top: 355px;
  color: #787878;
  font-size: 11px;
  margin: 10px;
}
.lay-mid .content .homepage .game-colum .game-promo {
  border: 1px solid #3e414a;
  left: 6px;
  top: -95px;
  width: 162px;
  height: 392px;
  display: block;
  position: absolute;
  outline: none;
  text-align: left;
  padding: 0;
  font-size: 16px;
  color: #b2bac7;
  cursor: pointer;
}
.lay-mid .content .homepage .game-colum .game-promo .game-tip {
  line-height: 3.6;
  text-align: center;
  font-size: 14px;
  background-color: #000;
}
#wow1 .game-promo {
  background: url(../assets/nav/n1.jpg) 50% 50% no-repeat;
}
#wow2 .game-promo {
  background: url(../assets/nav/n2.jpg) 50% 50% no-repeat;
}
#wow3 .game-promo {
  background: url(../assets/nav/n3.jpg) 50% 50% no-repeat;
}
#wow4 .game-promo {
  background: url(../assets/nav/n4.jpg) 50% 50% no-repeat;
}
#wow5 .game-promo {
  background: url(../assets/nav/n5.jpg) 50% 50% no-repeat;
}
#wow6 .game-promo {
  background: url(../assets/nav/n6.jpg) 50% 50% no-repeat;
}
#wow7 .game-promo {
  background: url(../assets/nav/n8.jpg) 50% 50% no-repeat;
}
#wow1 .game-promo,
#wow2 .game-promo,
#wow3 .game-promo,
#wow4 .game-promo,
#wow5 .game-promo,
#wow6 .game-promo,
#wow7 .game-promo {
  background-size: cover;
  transition: all 0.5s;
}
#wow1 .game-promo:hover,
#wow2 .game-promo:hover,
#wow3 .game-promo:hover,
#wow4 .game-promo:hover,
#wow5 .game-promo:hover,
#wow6 .game-promo:hover,
#wow7 .game-promo:hover {
  background-position: -248px 0;
}
.navbarfooter {
  position: relative;
  box-sizing: border-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
  color: #fff;
  margin-top: 20px;
  z-index: 0;
  background: #424242;
  min-height: 500px;
}
.foot-logo {
  padding: 30px 0;
}
.foot-logo .flimg {
  width: 66px;
  height: 66px;
  background: url(../assets/logo.png) 0 0 no-repeat;
  background-size: contain;
  margin: 0 auto;
}
.title-con {
  height: fit-content;
}
.title-con .tit {
  font-weight: 700;
  font-size: 3.75rem !important;
  line-height: 3.75rem;
  letter-spacing: -0.0083333333em !important;
}
.title-con .stit {
  font-size: 1.5rem !important;
  font-weight: 400;
  letter-spacing: normal !important;
}
</style>