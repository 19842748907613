<template>
  <v-card v-if="itemData && (!itemData.mfr_code || itemData.mfr_code === 'ap')" outlined>
    <v-toolbar flat color="#009fe9" dark>
      设备维修单
    </v-toolbar>
    <v-card-text>
      <v-alert
          v-if="(itemData.out_req_type === '保外送修' || itemData.out_req_type === '保外维修') && (itemData.status > 0 || itemData.status < 3) && (!itemData.confirm_price) "
          text
          outlined
          color="red"
          class="my-4"
          dark
          icon="feedback">
        需要在<strong>确认维修</strong>后执行后续环节，请在电脑中使用浏览器访问：https://esp.mdaas.com 进行维修确认。
      </v-alert>
      <v-alert
          v-else
          text
          outlined
          color="#009fe9"
          class="my-4"
          dark
          icon="sms">
        请在电脑中使用浏览器访问：https://esp.mdaas.com 查看更多信息。
      </v-alert>

      <v-expansion-panels :value="0">
        <v-expansion-panel>
          <v-expansion-panel-header>
            报修信息
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list two-line>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>post_add</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{itemData.catalog.name}}</v-list-item-title>
                  <v-list-item-subtitle>服务类型</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>numbers</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{itemData.case_number}}</v-list-item-title>
                  <v-list-item-subtitle>服务单号</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>alt_route</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{itemData.out_req_id}}</v-list-item-title>
                  <v-list-item-subtitle>外部单号</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>bug_report</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{itemData.detail_fault}}</v-list-item-title>
                  <v-list-item-subtitle>故障描述</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>edit_note</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{itemData.creator.personal.name}}</v-list-item-title>
                  <v-list-item-subtitle>申请人</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>more_time</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{itemData.createTime | formatTime}}</v-list-item-title>
                  <v-list-item-subtitle>申请时间</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>person_outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{itemData.account.personal.name}}</v-list-item-title>
                  <v-list-item-subtitle>使用人</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>playlist_add_check</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-if="itemData.status === 0">待受理</v-list-item-title>
                  <v-list-item-title v-else-if="itemData.status === 1">检测中/维修中</v-list-item-title>
                  <v-list-item-title v-else-if="itemData.status === 2">维修完成</v-list-item-title>
                  <v-list-item-title v-else-if="itemData.status === 3">待返回/待取机</v-list-item-title>
                  <v-list-item-title v-else-if="itemData.status === 4">服务结束</v-list-item-title>
                  <v-list-item-title v-else>取消服务</v-list-item-title>
                  <v-list-item-subtitle>当前状态</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>outbox</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{itemData.returns.channel | repairReturn}}</v-list-item-title>
                  <v-list-item-subtitle>寄回方式</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>forward_to_inbox</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{itemData.returns.contact_name}}</v-list-item-title>
                  <v-list-item-subtitle>联系人</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>update</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{itemData.updateTime | formatTime}}</v-list-item-title>
                  <v-list-item-subtitle>更新时间</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            处理信息
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-timeline dense clipped>
              <v-timeline-item
                  v-for="(item, index) in itemData.steps"
                  :key="'task_'+index"
                  :color="item.isCurrent ? '#009fe9' :'grey'"
                  :icon="item.step_icon"
              >
                <v-row>
                  <v-col cols="4"><strong>{{item.step_info}}</strong></v-col>
                  <v-col class="text-right" v-if="item.step_time">{{item.step_time | formatTime}}</v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
  <v-card v-else>
    <v-toolbar flat color="#009fe9" dark>
      非服务支持设备：{{itemData && itemData.case_number}}
    </v-toolbar>
    <v-card-text>
      <v-alert
          text
          outlined
          color="#009fe9"
          class="my-4"
          dark
          icon="sms">
        抱歉，您提交的设备维修目前不在服务范围内，如有服务需求，请与我们联系4001505665。
      </v-alert>
    </v-card-text>
  </v-card>
</template>
<script>
import store from "@/store";
import {
  OVERVIEW_REPAIR_CASE,
} from "@/store/actions.type";

export default {
  data() {
    return {
      itemData: null,
    }
  },
  created() {
  },
  mounted() {
    if (this.$route.params.id) this.getItem(this.$route.params.id)
  },
  computed: {
  },
  methods: {
    getItem(id) {
      store.dispatch(OVERVIEW_REPAIR_CASE, { id })
          .then((data) => {
            this.itemData = data
          })
    }
  }
}
</script>
