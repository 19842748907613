<template>
  <v-sheet color="#f4f4f4" class="mainSheet">
    <div class="account-con">
      <div class="con-left">
        <account-nav :index="2"></account-nav>
      </div>
      <div class="con-right">
        <div class="right-over">
          <v-container>
            <v-card>
              <v-toolbar class="secondary" dark>
                <v-btn icon @click="goBack()">
                  <v-icon>arrow_back</v-icon>
                </v-btn>
                <v-toolbar-title>
                  <!-- <v-icon>alarm_on</v-icon> -->
                  服务请求列表
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="query.key"
                  @keydown.enter="doAction('search_item')"
                  class="mx-4"
                  flat
                  dense
                  hide-details
                  label="搜索服务编号、企业工单编号..."
                  prepend-inner-icon="search"
                  solo-inverted
                ></v-text-field>
              </v-toolbar>
              <!-- <v-alert dense color="grey lighten-3" class="ma-2" dismissible>
                <v-subheader>工作台是集中处理日常工作服务请求、待办事项的区域，在工作台中可以查找、编辑和完成服务请求。</v-subheader>
              </v-alert> -->
              <v-data-table
                v-model="selectedItem"
                :headers="computedHeaders"
                :items="caseList"
                :options.sync="optionsItem"
                :server-items-length="caseCount"
                :loading="caseLoading"
                loading-text="数据读取中... 请稍后"
                :footer-props="{'items-per-page-options': [5, 10, 15, 20]}"
                item-key="_id"
                return-object="false"
                transition
              >
                <template v-slot:top>
                  <v-toolbar flat color="white" v-if="currentUser">
                    <v-spacer></v-spacer>
                    <v-menu offset-y v-if="currentUser.hasAdmin.includes('enterprise')">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" color="secondary" depressed>
                          <v-icon left>add</v-icon>
                          新建工单
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item @click="doAction('select_service', query.enterprise, '选择企业和服务')">
                          <v-list-item-icon>
                            <v-icon>add_box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>通过表单增加请求</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="doAction('batch_create', query.enterprise, '批量创建新请求')">
                          <v-list-item-icon>
                            <v-icon>library_add</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>通过文件增加请求</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click.stop="doAction('export_item')">
                          <v-icon small>save_alt</v-icon>
                        </v-btn>
                      </template>
                      <span>导出数据</span>
                    </v-tooltip>
                    <widgets-custom-header :defaultHeader="defaultHeader" :headerList="headerItem" :version=2 tableName="serviceHeader" v-on:Cancel="doAction('cancel_header')" v-on:Default="doAction('default_header')" v-on:Save="doAction('set_header')"></widgets-custom-header>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click.stop="doAction('filter_item')">
                          <v-icon small>filter_list</v-icon>
                        </v-btn>
                      </template>
                      <span>过滤数据</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click.stop="doAction('reload_item')">
                          <v-icon small>autorenew</v-icon>
                        </v-btn>
                      </template>
                      <span>刷新列表</span>
                    </v-tooltip>
                  </v-toolbar>
                </template>
                <template v-slot:item.case_number="{ item }">
                  <td nowrap="true">
                    <v-btn icon color="secondary" v-if="item.isMark" @click="markCase(item._id, false)"><v-icon transition="scale-transition">star</v-icon></v-btn>
                    <v-btn icon v-else @click="markCase(item._id, true)"><v-icon transition="scale-transition">star_outline</v-icon></v-btn>
                    <v-badge color="secondary" dot :value="item.isEdit && !item.isSelf">
                      <widgets-case-dialogs :getId="item._id" v-on:Close="closeCase" :key="item._id" :isOpen="openCase(item.case_number)">
                        {{item.case_number}}
                        <v-icon v-if="item.isSelf && item.status < 2" right small>create</v-icon>
                      </widgets-case-dialogs>
                    </v-badge>
                  </td>
                </template>
                <template v-slot:item.out_number="{ item }">
                  <td nowrap="true">{{item.out_number}}</td>
                </template>
                <template v-slot:item.catalog="{ item }">
                  <td nowrap="true">{{item.catalog?item.catalog.name:''}}</td>
                </template>
                <template v-slot:item.total_sla.expectTime="{ item }">
                  <td nowrap="true" v-if="item.total_sla && item.total_sla.status === 0"><widgets-baseTimer :endTime="item.total_sla.expectTime" :alertThreshold="item.total_sla.sla_limit / 2" :warningThreshold="item.total_sla.sla_limit" type="text"/></td>
                  <td nowrap="true" v-else-if="item.total_sla">{{ item.total_sla.status === 1 ? '完成' : '超时完成' }}</td>
                  <td nowrap="true" v-else>-</td>
                </template>
                <template v-slot:item.current_task="{ item }">
                  <td nowrap="true" v-if="item.current_task">{{ item.current_task.task ? item.current_task.task.name : '' }}</td>
                  <td nowrap="true" v-else>{{item.status === 0 ? '受理' : '已结束'}}</td>
                </template>
                <template v-slot:item.count_tasks="{ item }">
                  <td nowrap="true" v-if="item.status === 0">-</td>
                  <td nowrap="true" v-else>共 {{ item.count_tasks }} 个</td>
                </template>
                <template v-slot:item.enterprise="{ item }">
                  <td nowrap="true" v-if="item.enterprise" @click="doAction('open_enterprise', item.enterprise)" class="v-list-item--link">{{item.enterprise.name}}</td>
                </template>
                <template v-slot:item.creator="{ item }">
                  <td nowrap="true" v-if="item.creator"><widgets-profile-dialogs :account="item.creator._id" class="v-list-item--link">{{item.creator.personal ? item.creator.personal.name : '*未设置姓名'}}</widgets-profile-dialogs></td>
                </template>
                <template v-slot:item.editor="{ item }">
                  <td nowrap="true" v-if="item.editor"><widgets-profile-dialogs :account="item.editor._id" class="v-list-item--link">{{item.editor.personal ? item.editor.personal.name : '*未设置姓名'}}</widgets-profile-dialogs></td>
                  <td nowrap="true" v-else>-</td>
                </template>
                <template v-slot:item.deliver_team="{ item }">
                  <td nowrap="true">{{ item.deliver_team ? item.deliver_team.name : '-' }}</td>
                </template>
                <template v-slot:item.account="{ item }">
                  <td nowrap="true" v-if="item.account"><widgets-profile-dialogs :account="item.account._id" class="v-list-item--link">{{item.account.personal ? item.account.personal.name : '*未设置姓名'}}</widgets-profile-dialogs></td>
                  <td nowrap="true" v-else>-</td>
                </template>
                <template v-slot:item.link_asset="{ item }">
                  <td nowrap="true" v-if="item.link_asset">{{item.link_asset.length}}台</td>
                </template>
                <template v-slot:item.link_asset_new="{ item }">
                  <td nowrap="true" v-if="item.link_asset_new">{{item.link_asset_new.length}}台</td>
                </template>
                <template v-slot:item.status="{ item }">
                  <td nowrap="true">
                    <v-chip label small v-if="item.status === 0" color="secondary">待受理</v-chip>
                    <v-chip label small v-else-if="item.status === 1" color="primary">处理中</v-chip>
                    <v-chip label small v-else-if="item.status === 2">已取消</v-chip>
                    <v-chip label small v-else-if="item.status === 3">已取消</v-chip>
                    <v-chip label small v-else>已完成</v-chip>
                  </td>
                </template>
                <template v-slot:item.createTime="{ item }">
                  <td nowrap="true">{{item.createTime | formatTime}}</td>
                </template>
                <template v-slot:item.updateTime="{ item }">
                  <td nowrap="true">{{item.updateTime | formatTime}}</td>
                </template>
              </v-data-table>
            </v-card>
            <v-navigation-drawer v-model="filterDrawer" app temporary bottom right>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="title">
                    过滤条件
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-card-text>
                <h2 class="subtitle-2 my-2">基本信息</h2>
                <v-autocomplete
                    v-model="query.creator"
                    :items="listApplicant"
                    :loading="searchEmployee.loadingApplicant"
                    :search-input.sync="searchEmployee.searchApplicant"
                    item-text="personal.name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    dense
                    label="服务提交人员"
                    hint="请选择工单提交人员"
                    clearable
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                      <v-list-item-subtitle>{{data.item.username}} - {{data.item.phone}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                    v-model="query.account"
                    :items="listAccount"
                    :loading="searchEmployee.loadingAccount"
                    :search-input.sync="searchEmployee.searchAccount"
                    item-text="personal.name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    dense
                    label="设备使用人员"
                    hint="请选择设备使用人员"
                    clearable
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                      <v-list-item-subtitle>{{data.item.username}} - {{data.item.phone}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-text-field
                  v-model="query.serial_number"
                  type="text"
                  label="设备序列号"
                  hint="设备序列号是设备的唯一标示"
                  outlined
                  dense
                  clearable
                >
                </v-text-field>
                <v-text-field
                  v-model="query.express_number"
                  type="text"
                  label="快递单号"
                  hint="请输入工单所含快递单号"
                  outlined
                  dense
                  clearable
                >
                </v-text-field>
                <h2 class="subtitle-2 mt-2">工单状态</h2>
                <v-chip-group v-if="caseStatus.length" v-model="query.status" column multiple>
                  <v-chip v-for="cases in caseStatus" :key="cases.code" :value="cases.code" filter label outlined>{{cases.name}}</v-chip>
                </v-chip-group>
                <h2 class="subtitle-2 mt-2">服务方式</h2>
                <v-chip-group v-if="handoverType.length" v-model="query.handover" column multiple>
                  <v-chip v-for="handover in handoverType" :key="handover.code" :value="handover.code" filter label outlined>{{handover.name}}</v-chip>
                </v-chip-group>
                <h2 class="subtitle-2 mt-2">SLA过期时间</h2>
                <v-chip-group v-model="query.deadline" column>
                  <v-chip v-for="datetiem in SLAExpiry" :key="datetiem.code" :value="datetiem.code" filter label outlined>{{datetiem.name}}</v-chip>
                </v-chip-group>
                <h2 class="subtitle-2 mt-2">创建时间</h2>
                <v-menu ref="menuStartCreateTime" v-model="menuStartCreateTime"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="query.startCreateTime"
                        label="开始时间"
                        class="mt-2 mb-n4"
                        readonly
                        outlined
                        dense
                        clearable
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="query.startCreateTime" no-title scrollable width="225" @input="menuStartCreateTime = false; clearTime(['createTime'])">
                  </v-date-picker>
                </v-menu>
                <v-menu ref="menuStartCreateTime" v-model="menuEndCreateTime"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="query.endCreateTime"
                        label="结束时间"
                        class="mt-2 mb-n4"
                        readonly
                        outlined
                        dense
                        clearable
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="query.endCreateTime" no-title scrollable width="225" @input="menuEndCreateTime = false; clearTime(['createTime'])"></v-date-picker>
                </v-menu>
                <h2 class="subtitle-2 mt-2">完成时间</h2>
                <v-menu ref="menuStartCreateTime" v-model="menuStartCompleteTime"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="query.startCompleteTime"
                        label="开始时间"
                        class="mt-2 mb-n4"
                        readonly
                        outlined
                        dense
                        clearable
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="query.startCompleteTime" no-title scrollable width="225" @input="menuStartCompleteTime = false; clearTime(['createTime'])">
                  </v-date-picker>
                </v-menu>
                <v-menu ref="menuStartCreateTime" v-model="menuEndCompleteTime"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="query.endCompleteTime"
                        label="结束时间"
                        class="mt-2 mb-n4"
                        readonly
                        outlined
                        dense
                        clearable
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="query.endCompleteTime" no-title scrollable width="225" @input="menuEndCompleteTime = false; clearTime(['createTime'])"></v-date-picker>
                </v-menu>
                <h2 class="subtitle-2 mt-2">更新时间</h2>
                <v-chip-group v-model="query.updateTime" column>
                  <v-chip v-for="datetiem in updateTime" :key="datetiem.code" :value="datetiem.code" filter label outlined>{{datetiem.name}}</v-chip>
                </v-chip-group>
                <h2 class="subtitle-2 mt-2">所属服务</h2>
                <v-chip-group v-if="assetServiceList.length" v-model="query.service" column multiple>
                  <v-chip v-for="service in assetServiceList" :key="service._id" :value="service._id" filter label outlined>{{service.name}}</v-chip>
                </v-chip-group>
              </v-card-text>
              <template v-slot:append>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="doAction('clear_filter')">清空</v-btn>
                  <v-btn color="secondary" text @click="doAction('set_filter')">确认</v-btn>
                </v-card-actions>
              </template>
            </v-navigation-drawer>
            <v-dialog v-model="dlgService" persistent max-width="600px">
              <v-form v-model="valid" @submit.prevent="submit('service')">
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ dlgTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-autocomplete
                      autocomplete="off"
                      v-model="editedCase.service"
                      :items="assetServiceList"
                      :rules="[rules.selected]"
                      label="服务"
                      hint="请选择开单的服务"
                      item-text="name"
                      item-value="_id"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dlgService = false">取消</v-btn>
                    <v-btn color="secondary" text @click="submit('service')" :disabled="!valid">确定</v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>
            <v-dialog v-model="dlgInfo" persistent scrollable max-width="600px">
              <widgets-enterprise-dialogs v-if="activeType === 'enterprise'" :id="activeId" :key="activeId" v-on:Close="doAction('close_info')"></widgets-enterprise-dialogs>
            </v-dialog>
            <v-dialog v-model="dlgInfo" persistent scrollable max-width="600px">
              <widgets-enterprise-dialogs v-if="activeType === 'enterprise'" :id="activeId" :key="activeId" v-on:Close="doAction('close_info')"></widgets-enterprise-dialogs>
              <v-card v-if="activeType === 'batch_create'">
                <v-card-title>
                  批量开单
                  <v-spacer></v-spacer>
                  <v-btn icon @click="doAction('close_info')"><v-icon>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                  <!-- <v-autocomplete
                    autocomplete="off"
                    v-model="editedCase.enterprise"
                    :items="dealer_enterprise"
                    :rules="[rules.selected]"
                    @change="doAction('set_service_list')"
                    label="企业"
                    hint="请选择一家企业"
                    item-text="name"
                    item-value="_id"
                    outlined
                    dense
                  ></v-autocomplete> -->
                  <v-autocomplete
                    autocomplete="off"
                    v-model="editedCase.service"
                    :items="assetServiceList"
                    :rules="[rules.selected]"
                    label="服务"
                    hint="请选择开单的服务"
                    item-text="name"
                    item-value="_id"
                    outlined
                    dense
                    class="mt-2"
                  ></v-autocomplete>

                  <v-row v-if="editedCase.service">
                    <v-col cols="12"><v-divider></v-divider></v-col>
                    <v-col cols="6">
                      <p>1. 下载开单模版，填写设备信息.</p>
                      <v-btn text @click="doAction('download_create_template')">下载模版<v-icon>file_download</v-icon></v-btn>
                    </v-col>
                    <v-col cols="6">
                      <input
                        id="import_file"
                        type="file"
                        name="import_file"
                        ref="uploadImport"
                        accept=".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        :multiple="false"
                        @change="importCreateFiles($event)"/>
                      <p>2. 上传填好的文件.</p>
                      <v-btn text @click="doAction('upload_create_file')" :loading="!batch.import_end"><v-icon left dark>file_upload</v-icon>上传文件</v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="batch.import_failed.length || batch.import_result.length">
                    <v-col cols="12">
                      <v-divider></v-divider>
                      <v-alert
                        outlined
                        type="error"
                        text
                      >
                        导入失败：<span v-if="batch.import_failed.length === 0">无</span><ul v-else><li v-for="(fail,index) in batch.import_failed" :key="index">行：{{fail.line}} 错误：{{fail.error}}</li></ul>
                      </v-alert>
                      <v-alert
                        outlined
                        type="success"
                        text
                      >
                        成功创建：{{ batch.import_result.length }} 条记录；
                        <v-row v-if="batch.import_result.length">
                          <v-col class="grow">
                            <p class="font-weight-black" v-for="item in batch.import_result" :key="item._id">{{ item.case_number }}</p>
                          </v-col>
                        </v-row>
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card v-if="activeType === 'batch_edit'">
                <v-card-title>
                  批量处理任务 - {{batchUpdate.task_info.name}}
                  <v-spacer></v-spacer>
                  <v-btn icon @click="doAction('close_info')"><v-icon>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-alert dense color="grey lighten-3" class="ma-2" dismissible>
                        <div class="v-card__subtitle">{{batchUpdate.task_info.remarks}}
                          <div class="secondary--text">批量处理无法修改设备信息，请通过单个工单处理设备信息。</div>
                        </div>
                      </v-alert>
                    </v-col>
                    <v-col cols="12">
                      <v-form v-model="batchUpdate.valid" @submit.prevent="batchSubmit">
                        <v-row align="center" v-for="(item, i) in batchUpdate.task_fields" :key="i+'_task_fields'">
                            <v-col cols="2" class="text-right">
                              {{item.name}}
                            </v-col>
                            <v-col cols="8" class="text--secondary" v-if="item.format.type === 'text'">
                              <v-text-field
                                v-if="item.format.mask"
                                v-model="item.value"
                                v-mask="item.format.mask"
                                :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                                hide-details
                                outlined
                                dense>
                              </v-text-field>
                              <v-text-field
                                v-else
                                v-model="item.value"
                                :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                                hide-details
                                outlined
                                dense>
                              </v-text-field>
                            </v-col>
                            <v-col cols="8" class="text--secondary" v-if="item.format.type === 'selects'">
                              <v-autocomplete
                                autocomplete="off"
                                v-model="item.value"
                                :items="item.format.items"
                                :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                                hide-details
                                outlined
                                dense
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="2" class="text-right"></v-col>
                          </v-row>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn text @click="batchUpdate.showCase = !batchUpdate.showCase">
                    <v-subheader>批量处理工单：{{selectedItem.length}}</v-subheader><v-icon right>{{ batchUpdate.showCase ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text v-if="batchUpdate.task_info.actions.includes('cancel')" @click="batchCancel()"><v-icon left>remove_circle</v-icon>批量取消</v-btn>
                  <v-btn text @click="batchReject"><v-icon left>replay_circle_filled</v-icon>批量驳回</v-btn>
                  <v-btn text v-if="batchUpdate.task_info.actions.includes('complete')" color="secondary" @click="batchSubmit()" :disabled="!batchUpdate.valid"><v-icon left>check_circle</v-icon>批量提交</v-btn>
                </v-card-actions>
                <v-expand-transition>
                  <div v-show="batchUpdate.showCase">
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-chip
                        class="ma-2"
                        close
                        label
                        small
                        v-for="(selected, index) in selectedItem"
                        :key="selected._id"
                        @click:close="doAction('close_selected', index)"
                      >{{selected.case_number}}</v-chip>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-dialog>
          </v-container>
        </div>
      </div>
    </div>
  </v-sheet>
</template>
<script>
import XLSX from 'xlsx'
import ApiService from "@/common/http";
import { mapGetters } from "vuex";
import store from "@/store";
import {
  FETCH_CASE_LIST,
  FETCH_CATALOG_LIST,
  // FETCH_DEALER_ENTERPRISE,
  FETCH_EMPLOYEE_LIST,
  FETCH_SERVICE_LIST,
  FETCH_TASK_LIST,
  PUBLISH_CASE, REMOVE_CASE_ITEM
} from "@/store/actions.type";
import { SET_ERROR } from "@/store/mutations.type";
import Util from "@/common/util";
import AccountNav from "@/components/accountnav";

export default {
  data() {
    return {
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        phonenum: (value) => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || "请输入正确的电话号码";
          } else {
            return true;
          }
        },
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        email: (value) => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "错误的电子邮件地址";
          } else {
            return true;
          }
        },
      },
      menuStartCreateTime: false, //筛选：创建工单开始时间
      menuEndCreateTime: false, //筛选：创建工单结束时间
      menuStartCompleteTime: false, //筛选：完成工单开始时间
      menuEndCompleteTime: false, //筛选：完成工单结束时间
      searchEmployee: {
        loadingAccount: false,
        searchAccount: null,
        timerAccount: null,
        entriesAccount: [],
        loadingApplicant: false,
        searchApplicant: null,
        timerApplicant: null,
        entriesApplicant: [],
      },
      query: {
        key: "",
        enterprise: "", //企业
        account: "", // 设备使用人
        creator: "", // 工单提交人
        serial_number: "", // 设备序列号
        service: [], // 服务类型
        tasks: [], // 当前任务
        status: [], // 工单状态
        updateTime: "", // 设备更新时间
        createTime: "", // 设备更新时间
        deadline: "", // SLA超期时间
        handover: [], // 服务方式
        startCreateTime: '', // 创建工单开始时间
        endCreateTime: '',
        startCompleteTime: '', // 完成工单开始时间
        endCompleteTime: '',
        express_number:'', // 快递单号
      },
      defaultQuery: {
        key: "",
        enterprise: "", //企业
        account: "", // 设备使用人
        creator: "", // 工单提交人
        serial_number: "", // 设备序列号
        service: [], // 服务类型
        tasks: [], // 当前任务
        status: [], // 工单状态
        updateTime: "", // 设备更新时间
        createTime: "", // 设备更新时间
        deadline: "", // SLA超期时间
        handover: [], // 服务方式
        startCreateTime: '', // 创建工单开始时间
        endCreateTime: '',
        startCompleteTime: '', // 完成工单开始时间
        endCompleteTime: '',
        express_number:'', // 快递单号
      },
      selectedItem: [],
      headerItem: [],
      defaultHeader: [
        { text: '工单编号', value: 'case_number' },
        { text: '外部编号', value: 'out_number', align: " d-none" },
        { text: '所属服务', value: 'catalog', width: "115px" },
        { text: '服务方式', value: 'handover_type', width: "115px", align: " d-none" },
        { text: 'SLA', value: 'total_sla.expectTime' },
        { text: '当前任务', value: 'current_task' },
        { text: '相关任务', value: 'count_tasks', width: "115px" },
        { text: '请求人', value: 'creator', width: "115px" },
        { text: '处理人', value: 'editor', width: "115px", align: " d-none" },
        { text: '服务团队', value: 'deliver_team', width: "115px", align: " d-none" },
        { text: '设备用户', value: 'account', width: "115px", align: " d-none" },
        { text: '关联设备', value: 'link_asset', width: "115px", align: " d-none" },
        { text: '更换新设备', value: 'link_asset_new', width: "125px", align: " d-none" },
        { text: '工单状态', value: 'status', width: "115px" },
        { text: '创建时间', value: 'createTime', align: " d-none" },
        { text: '更新时间', value: 'updateTime', align: " d-none" },
      ],
      optionsItem: {},
      filterDrawer: false,
      assetServiceList: [],
      tasksList: [],
      dlgHeader: false,
      dlgService: false,
      dlgTitle: "",
      valid: true,
      editedCase: {
        enterprise: "",
        service: "",
      },
      defaultCase: {
        enterprise: "",
        service: "",
      },
      availableService: [],
      dlgInfo: false,
      activeType: "",
      activeId: null,
      hasEnterpriseList: [],
      availableEnterService: [],
      batch: {
        create_header: ['服务类型', '外部单号', '用户姓名', '用户电话', '用户邮件', '关联设备序列号', '更换新设备序列号'],
        import_result: [],
        import_failed: [],
        import_end: true,
      },
      defaultBatch: {
        create_header: ['服务类型', '外部单号', '用户姓名', '用户电话', '用户邮件', '关联设备序列号', '更换新设备序列号'],
        import_result: [],
        import_failed: [],
        import_end: true,
      },
      batchUpdate: {
        valid: true,
        showCase: false,
        task_info: {},
        task_fields: []
      }
    };
  },
  components: {
    AccountNav,
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_CATALOG_LIST),
      store.dispatch(FETCH_TASK_LIST, "isEnable=true"),
    ]).then(() => {
      next();
    });
  },
  created() {
    this.getList();
    this.getService();
    this.getTasks();
    this.getHeader("serviceHeader", 2);
    this.creatEnterpriseList()
    this.getServiceCatalog(this.query.enterprise);
  },
  mounted() {},
  computed: {
    ...mapGetters([
      "currentUser",
      "dealer_enterprise",
      "catalogList",
      "caseLoading",
      "caseList",
      "caseCount",
      "taskList",
      "serviceList"
    ]),
    caseStatus() {
      return Util.categories("caseStatus");
    },
    updateTime() {
      return Util.categories("updateTime");
    },
    SLAExpiry() {
      return Util.categories("SLAExpiry");
    },
    handoverType() {
      return Util.categories('handoverType')
    },
    listAccount() {
      return this.searchEmployee.entriesAccount.map(user => {
        return Object.assign({}, user)
      })
    },
    listApplicant() {
      return this.searchEmployee.entriesApplicant.map(user => {
        return Object.assign({}, user)
      })
    },
    computedHeaders() {
      let arrHeaderItem = this.headerItem
      return arrHeaderItem.filter(item => !item.align)
    },
  },
  watch: {
    optionsItem: {
      handler() {
        this.getList(this.query);
      },
      deep: true,
    },
    dlgService(val) {
      val || this.closeService();
    },
    'searchEmployee.searchAccount'(val) {
      if (this.searchEmployee.timerAccount) {
        clearTimeout(this.searchEmployee.timerAccount)
      }
      this.searchEmployee.timerAccount = setTimeout(() => {
        if (this.searchEmployee.loadingAccount) return
        this.searchEmployee.loadingAccount = true
        if (this.query.enterprise || val) {
          let strEnterprise = this.query.enterprise || '', strKey = val || ''
          store.dispatch(FETCH_EMPLOYEE_LIST, 'enterprise='+ strEnterprise +'&key='+ strKey)
            .then((data) =>{
              if (data) {
                const { employees } = data
                this.searchEmployee.entriesAccount = employees
              }
            })
            .catch(err => {
              store.commit(SET_ERROR, {msg: err})
            })
            .finally(() => (this.searchEmployee.loadingAccount = false))
        } else {
          this.searchEmployee.loadingAccount = false
        }

      }, 500)
    },
    'searchEmployee.searchApplicant'(val) {
      if (this.searchEmployee.timerApplicant) {
        clearTimeout(this.searchEmployee.timerApplicant)
      }
      this.searchEmployee.timerApplicant = setTimeout(() => {
        if (this.searchEmployee.loadingApplicant) return
        this.searchEmployee.loadingApplicant = true
        if (this.query.enterprise || val) {
          let strEnterprise = this.query.enterprise || '', strKey = val || ''
          store.dispatch(FETCH_EMPLOYEE_LIST, 'enterprise='+ strEnterprise +'&key='+ strKey)
            .then((data) =>{
              if (data) {
                const { employees } = data
                this.searchEmployee.entriesApplicant = employees
              }
            })
            .catch(err => {
              store.commit(SET_ERROR, {msg: err})
            })
            .finally(() => (this.searchEmployee.loadingApplicant = false))
        } else {
          this.searchEmployee.loadingApplicant = false
        }
      }, 500)
    },
  },
  methods: {
    exportList(query = {}) {
      // 获取分页信息
      const { sortBy, sortDesc, page } = this.optionsItem;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true){
          sort = '-'+ sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0){
        this.query = Object.assign(this.query, this.$route.query);
        if (this.query.status && !Array.isArray(this.query.status)) this.query.status = this.query.status.split(',')
        if (this.query.handover && !Array.isArray(this.query.handover)) this.query.handover = this.query.handover.split(',')
        if (this.query.service && !Array.isArray(this.query.service)) this.query.service = this.query.service.split(',')
        if (this.query.tasks && !Array.isArray(this.query.tasks)) this.query.tasks = this.query.tasks.split(',')
        if (this.query.enterprise) {
          store.dispatch(FETCH_EMPLOYEE_LIST, '&enterprise='+ this.query.enterprise)
        }
      }

      let urlQuery = '';
      urlQuery = '&catalog_type=asset_service'
      if (query.key) urlQuery += '&key='+ query.key
      if (query.enterprise) urlQuery += '&enterprise='+ query.enterprise
      if (query.account) urlQuery += '&account='+ query.account
      if (this.query.status.length) urlQuery += '&status='+ query.status
      if (this.query.handover.length) urlQuery += '&handover='+ query.handover
      if (this.query.service.length) urlQuery += '&service='+ query.service
      if (this.query.tasks.length) urlQuery += '&tasks='+ query.tasks
      if (this.query.creator) urlQuery += '&creator='+ query.creator
      if (this.query.serial_number) urlQuery += '&serial_number='+ query.serial_number
      if (this.query.updateTime) urlQuery += '&updateTime='+ query.updateTime
      if (this.query.createTime) urlQuery += '&createTime='+ query.createTime
      if (this.query.deadline) urlQuery += '&deadline='+ query.deadline
      if (this.query.startCreateTime) urlQuery += '&startCreateTime='+ query.startCreateTime
      if (this.query.endCreateTime) urlQuery += '&endCreateTime='+ query.endCreateTime
      if (this.query.startCompleteTime) urlQuery += '&startCompleteTime='+ this.query.startCompleteTime
      if (this.query.endCompleteTime) urlQuery += '&endCompleteTime='+ this.query.endCompleteTime
      if (this.query.express_number) urlQuery += '&express_number='+ this.query.express_number

      if (Object.keys(this.optionsItem).length > 0) {
        ApiService.download('/case/service/export?page=' + page + '&limit=-1&sort=' + sort + urlQuery, {
          responseType:'blob'
        })
          .then(resp => {
            let fileName = this.hasEnterpriseList[0].name +'_设备服务工单.xlsx';
            let contentDisposition = resp.headers['content-disposition'];
            if (contentDisposition) {
              fileName = window.decodeURI(resp.headers['content-disposition'].split('=')[1]);
            }
            let blob = new Blob([resp.data], {type: 'application/octet-stream'});
            if (window.navigator.msSaveOrOpenBlob) { //支持IE
              navigator.msSaveBlob(blob, fileName);
            } else {
              let link = document.createElement('a');
              link.style.display = "none";
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              document.body.appendChild(link);
              link.click();
              //释放内存
              window.URL.revokeObjectURL(link.href);
              document.body.removeChild(link);
            }
          })
          .catch(err => { // 请求失败处理
            store.commit(SET_ERROR, {msg: err});
          });
      }
    },
    getList(query = {}) {
      // 获取分页信息
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItem;
      let sort = "";
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = "-" + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
        if (this.query.status && !Array.isArray(this.query.status)) this.query.status = this.query.status.split(',')
        if (this.query.handover && !Array.isArray(this.query.handover)) this.query.handover = this.query.handover.split(',')
        if (this.query.service && !Array.isArray(this.query.service)) this.query.service = this.query.service.split(',')
        if (this.query.tasks && !Array.isArray(this.query.tasks)) this.query.tasks = this.query.tasks.split(',')
        if (this.query.enterprise) {
          store.dispatch(FETCH_EMPLOYEE_LIST, '&enterprise='+ this.query.enterprise)
        }
      }

      let urlQuery = "";
      urlQuery = "&catalog_type=asset_service";
      if (query.key) urlQuery += '&key='+ query.key
      if (query.enterprise) urlQuery += '&enterprise='+ query.enterprise
      if (query.account) urlQuery += '&account='+ query.account
      if (this.query.status.length) urlQuery += '&status='+ this.query.status
      if (this.query.handover.length) urlQuery += '&handover='+ this.query.handover
      if (this.query.service.length) urlQuery += '&service='+ this.query.service
      if (this.query.tasks.length) urlQuery += '&tasks='+ this.query.tasks
      if (this.query.creator) urlQuery += '&creator='+ this.query.creator
      if (this.query.serial_number) urlQuery += '&serial_number='+ this.query.serial_number
      if (this.query.updateTime) urlQuery += '&updateTime='+ this.query.updateTime
      if (this.query.createTime) urlQuery += '&createTime='+ this.query.createTime
      if (this.query.deadline) urlQuery += '&deadline='+ this.query.deadline
      if (this.query.startCreateTime) urlQuery += '&startCreateTime='+ this.query.startCreateTime
      if (this.query.endCreateTime) urlQuery += '&endCreateTime='+ this.query.endCreateTime
      if (this.query.startCompleteTime) urlQuery += '&startCompleteTime='+ this.query.startCompleteTime
      if (this.query.endCompleteTime) urlQuery += '&endCompleteTime='+ this.query.endCompleteTime
      if (this.query.express_number) urlQuery += '&express_number='+ this.query.express_number

      if (Object.keys(this.optionsItem).length > 0) {
        store.dispatch(FETCH_CASE_LIST, {
          form: "service",
          query:
              "page=" +
              page +
              "&limit=" +
              itemsPerPage +
              "&sort=" +
              sort +
              urlQuery,
        });
      }
    },
    creatEnterpriseList() {
      let j = {};
      j.name = this.currentUser.employer.ownerId.name;
      j._id = this.currentUser.employer.ownerId._id;
      this.hasEnterpriseList.push(j);
      this.query.enterprise = this.hasEnterpriseList[0]._id;
      this.editedCase.enterprise = this.hasEnterpriseList[0]._id;
    },
    goBack() {
      this.$router.push({ path: "/workbench" });
    },
    getTasks(catalog = []) {
      if (catalog.length) {
        console.log(JSON.stringify(this.catalogList));
      } else {
        if (this.taskList) {
          this.tasksList = this.taskList.concat();
        }
      }
    },
    getService(enterprise = "") {
      let arrService = [],
        tmpHash = {};
      if (enterprise) {
        store.dispatch(FETCH_SERVICE_LIST, enterprise).then((data) => {
          if (data.length) {
            data.forEach((item) => {
              if (new Date(item.end_time) > new Date()) {
                arrService.push(...item.catalog);
              }
            });
            this.assetServiceList = arrService.reduce((total, current) => {
              if (!tmpHash[current["_id"]]) {
                tmpHash[current["_id"]] = true;
                total.push(current);
              }
              return total;
            }, []);
          }
        });
      } else {
        if (this.catalogList) {
          this.assetServiceList = this.flatGroupCatalog(this.catalogList).concat();
        }
      }
    },

    getHeader(model, version) {
      let userHeader = this.currentUser[model];
      if (userHeader.content.length) {
        if (userHeader.version < version) {
          this.headerItem = JSON.parse(JSON.stringify(this.defaultHeader));
        } else {
          this.headerItem = JSON.parse(JSON.stringify(userHeader.content));
        }
      } else {
        this.headerItem = JSON.parse(JSON.stringify(this.defaultHeader));
      }
    },

    doAction(action, item = {}, additional) {
      switch (action) {
        case "select_service": {
          this.dlgService = true;
          this.dlgTitle = additional;
          break;
        }
        case 'batch_create': {
          this.activeType = 'batch_create'
          this.dlgInfo = true
          break
        }
        case "set_service_list": {
          this.availableService.length = 0;
          this.getServiceCatalog(this.editedCase.enterprise);
          break;
        }
        case "open_enterprise": {
          this.activeType = "enterprise";
          this.activeId = item._id;
          this.dlgInfo = true;
          break;
        }
        case "close_info": {
          this.dlgInfo = false;
          break;
        }
        case "cancel_header": {
          this.getHeader("serviceHeader",2);
          this.$forceUpdate();
          break;
        }
        case "default_header": {
          break;
        }
        case "set_header": {
          this.getHeader("serviceHeader",2);
          this.$forceUpdate();
          break;
        }
        case "filter_item": {
          this.filterDrawer = !this.filterDrawer;
          break;
        }
        case "set_employee_list": {
          if (this.query.enterprise) {
            store.dispatch(
              FETCH_EMPLOYEE_LIST,
              "&enterprise=" + this.query.enterprise
            );
          }
          this.getService(this.query.enterprise);
          break;
        }
        case "set_filter": {
          this.$router.push({ query: {} });
          this.getList(this.query);
          this.filterDrawer = !this.filterDrawer;
          break;
        }
        case "clear_filter": {
          this.query = Object.assign({}, this.defaultQuery);
          this.$router.push({ query: {} });
          this.getList();
          this.filterDrawer = !this.filterDrawer;
          break;
        }
        case "search_item": {
          this.getList(this.query)
          break;
        }
        case 'download_create_template': {
          let ws = XLSX.utils.aoa_to_sheet([['企业：'+ this.hasEnterpriseList[0].name +'，服务：'+ this.assetServiceList.find(item => item._id === this.editedCase.service).name +'，批量创建模版，多个设备请用英文 , 分割序列号。']])
          XLSX.utils.sheet_add_json(ws, [
            {}
          ], {header: this.batch.create_header, origin: 'A2'})
          const merge = [
            {s: {r:0, c:0}, e: {r:0, c: this.batch.create_header.length - 1}}
          ]
          ws['!merges'] = merge
          ws.A1.s = {alignment:{ wrapText: true }};
          let wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, ws, '批量开单')
          XLSX.writeFile(wb, this.hasEnterpriseList[0].name +'_'+ this.assetServiceList.find(item => item._id === this.editedCase.service).name +'_开单模版.xlsx')
          break
        }
        case 'upload_create_file': {
          this.$refs.uploadImport.click()
          break
        }
        case 'export_item': {
          if (!this.hasEnterpriseList[0]._id) {
            store.commit(SET_ERROR, {msg: '筛选一家企业后，可以对该企业工单进行导出。'});
            return
          }
          this.exportList(this.query)
          break
        }
        case 'reload_item': {
          console.log(2)
          this.optionsItem.page = 1
          this.getList()
          break
        }
        default: {
          break;
        }
      }
    },
    importCreateFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.importCase(fileList[x]);
      })
    },
    importCase(file) {
      this.batch.import_end = false
      let param = new FormData()
      param.append('file', file)
      param.append('enterprise', this.editedCase.enterprise)
      param.append('service', this.editedCase.service)
      ApiService.post('/case/service/import', param)
        .then((data) => {
          this.batch.import_result = data.data.Success
          this.batch.import_failed = data.data.Failed
          this.$refs.uploadImport.value = ''
          this.batch.import_end = true
          this.getList()
        })
        .catch((err) => {
          this.$refs.uploadImport.value = ''
          this.batch.import_result = { updateSum: 0, insertSum: 0 }
          this.batch.import_failed = [{line: 2, error: err.response.data.message}]
          this.batch.import_end = true
        })
    },
    closeService() {
      this.dlgTitle = "";
      this.dlgService = false;
      this.$nextTick(() => {
        this.editedCase = Object.assign({}, this.defaultCase);
      });
    },
    getServiceCatalog(enterprise) {
      let arrService = [],
        tmpHash = {};
      store.dispatch(FETCH_SERVICE_LIST, enterprise).then(() => {
        if (this.serviceList.length) {
          this.serviceList.forEach((item) => {
            if (new Date(item.end_time) > new Date()) {
              // if (item.catalog[0].parentId.service_type == 'asset_service') {
              //   arrService.push(...item.catalog);
              // }
              item.catalog.forEach((c) => {
                if (c.parentId.service_type == 'asset_service') {
                  arrService.push(c);
                }
              })
            }
          });
          this.assetServiceList = arrService.reduce((total, current) => {
            if (!tmpHash[current["_id"]]) {
              tmpHash[current["_id"]] = true;
              total.push(current);
            }
            return total;
          }, []);
        }
      })
    },
    flatGroupCatalog(arrGroup) {
      let arrRoles = [];
      arrGroup.forEach((groupItem) => {
        groupItem.children.forEach((catalogItem) => {
          arrRoles.push({
            _id: catalogItem._id,
            name: catalogItem.name,
            group: groupItem.name,
            process: catalogItem.process,
          });
        });
      });
      return arrRoles;
    },
    openCase(key) {
      let returnValue = false
      if (Object.keys(this.$route.query).length > 0) {
        const objQuery = Object.assign(this.query, this.$route.query);
        if (objQuery.key === key) returnValue = true
      }
      return returnValue
    },
    closeCase() {
      this.getList(this.query);
    },
    submit(subject) {
      if (subject === "service") {
        this.$router.push({
          path:
            '/sheet?enterprise=' + this.currentUser.employer.ownerId._id +'&service='+ this.editedCase.service
        });
      }
    },
    clearTime(arrModel = []) {
      for (const model of arrModel) {
        this.query[model] = ''
      }
    },
    markCase(caseId, result) {
      let actCode = PUBLISH_CASE
      if (!result) actCode = REMOVE_CASE_ITEM
      store.dispatch(actCode, {form: 'service/bookmake/'+ caseId, data: {}})
        .then(() => {
          store.commit(SET_ERROR, {msg: '操作成功', color: 'primary'});
          this.getList()
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    }
  },
};
</script>
<style scoped>
.nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
