<template>
  <v-sheet color="#f4f4f4" class="mainSheet">
      <div class="account-con">
        <div class="con-left">
          <account-nav :index="2"></account-nav>
        </div>
        <div class="con-right">
          <div class="right-over">
            <v-container>
              <v-card>
                <v-toolbar class="secondary" dark>
                  <v-btn icon @click="$router.push('/workbench')">
                    <v-icon>arrow_back</v-icon>
                  </v-btn>
                  <v-toolbar-title>订单服务列表</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="query.key"
                    @keydown.enter="doAction('search_item')"
                    class="mx-4"
                    flat
                    dense
                    hide-details
                    label="搜索订单编号、PO号、DT号"
                    prepend-inner-icon="search"
                    solo-inverted
                  ></v-text-field>
                </v-toolbar>
                <v-card flat>
                  <v-card-text>
                    <v-data-table
                      :options.sync="optionsItem"
                      :headers="headerItem"
                      :items="orderPurchaseList"
                      :server-items-length="orderPurchaseCount"
                      :loading="orderPurchaseLoading"
                      loading-text="数据读取中... 请稍后"
                      :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
                      class="elevation-0"
                      transition
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-tabs align-with-title v-model="active_tab">
                            <v-tabs-slider color="secondary"></v-tabs-slider>
                            <v-tab @click="todoList('all')">全部订单</v-tab>
                            <v-tab @click="todoList('purchase')">采购订单</v-tab>
                            <v-tab @click="todoList('rental')">租赁订单</v-tab>
                          </v-tabs>
                          <widgets-custom-header :defaultHeader="defaultHeader" :headerList="headerItem" :version=2 tableName="orderHeader" v-on:Cancel="doAction('cancel_header')" v-on:Default="doAction('default_header')" v-on:Save="doAction('set_header')"></widgets-custom-header>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" icon @click="filterdrawer = true">
                                <v-icon small>filter_list</v-icon>
                              </v-btn>
                            </template>
                            <span>过滤数据</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" icon @click.stop="doAction('reload_item')">
                                <v-icon small>autorenew</v-icon>
                              </v-btn>
                            </template>
                            <span>刷新列表</span>
                          </v-tooltip>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.order_number="{ item }">
                        <td class="secondary--text">
                          <widgets-order-dialogs :getId="item._id" :key="item._id" v-on:Update="updateList" :isOpen="openCase(item.order_number)">
                            {{ item.order_number }}
                          </widgets-order-dialogs>
                        </td>
                      </template>
                      <template v-slot:item.status="{ item }">
                        <v-chip label small color="secondary" v-if="item.status == '0'">待执行</v-chip>
                        <v-chip label small color="primary" v-if="item.status == '1'">处理中</v-chip>
                        <v-chip label small v-if="item.status == '2'">已取消</v-chip>
                        <v-chip label small v-if="item.status == '3'">已拒绝</v-chip>
                        <v-chip label small v-if="item.status == '4'">已完成</v-chip>
                      </template>
                      <template v-slot:item.createTime="{ item }">
                        <td nowrap="true">{{ item.createTime | formatTime }}</td>
                      </template>
                      <template v-slot:item.updateTime="{ item }">
                        <td nowrap="true">{{ item.updateTime | formatTime }}</td>
                      </template>
                      <template v-slot:item.count_product="{ item }">
                          {{ item.count_product }}种
                      </template>
                      <template v-slot:item.product_part_list="{ item }">
                        <td nowrap="true">
                          <div class="my-2" v-for="(pn,i) in item.product_part_list" :key="i">
                            <v-chip label small style="width: 200px;">
                              <span
                                class="d-inline-block text-truncate"
                                style="max-width: 200px;"
                              >
                                {{ pn || '无'}}
                              </span>
                            </v-chip>
                          </div>
                        </td>
                      </template>
                      <template v-slot:item.product_name_list="{ item }">
                        <td nowrap="true">
                          <div class="my-2" v-for="(pn,i) in item.product_name_list" :key="i">
                            <v-chip label small style="width: 200px;">
                              <span
                                class="d-inline-block text-truncate"
                                style="max-width: 200px;"
                              >
                                {{ pn }}
                              </span>
                            </v-chip>
                          </div>
                        </td>
                      </template>
                      <template v-slot:item.product_quantity_list="{ item }">

                          <div class="my-2" v-for="(pn,i) in item.product_quantity_list" :key="i">
                            <v-chip label small>
                              <span style="min-width:40px;" class="text-center">{{ pn }}</span>
                            </v-chip>
                          </div>

                      </template>
                      <template v-slot:item.product_asset_list="{ item }">

                          <div class="my-2" v-for="(pn,i) in item.product_asset_list" :key="i">
                            <v-chip label small>
                              <span style="min-width:40px;" class="text-center">{{ pn }}</span>
                            </v-chip>
                          </div>

                      </template>
                      <template v-slot:item.product_await_list="{ item }">
                          <div class="my-2" v-for="(pn,i) in item.product_await_list" :key="i">
                            <v-chip label small>
                              <span style="min-width:40px;" class="text-center">{{ pn }}</span>
                            </v-chip>
                          </div>
                      </template>
                      <template v-slot:item.editor="{ item }">
                        <td nowrap="true" v-if="item.editor && item.editor.personal">{{ item.editor.personal.name }}</td>
                      </template>
                      <template v-slot:item.creator="{ item }">
                        <td nowrap="true" v-if="item.creator && item.creator.personal">{{ item.creator.personal.name }}</td>
                      </template>
                      <template v-slot:item.total_price="{ item }">
                        <td nowrap="true">¥ {{ item.total_price.$numberDecimal | formatMoney }}</td>
                      </template>
                      <template v-slot:item.total_quantity="{ item }">
                        {{ item.total_quantity }}台
                      </template>
                      <template v-slot:item.payment_day="{ item }">
                        {{ item.payment_day }}
                      </template>
                      <template v-slot:item.rent_info.rent_type="{ item }">
                        <div v-if="item.rent_info">
                          {{ item.rent_info.rent_type === 0 ? '融资租赁' : '经营性租赁' }}
                        </div>
                        <div v-else>
                          无
                        </div>
                      </template>
                      <template v-slot:item.rent_info.lease_start="{ item }">
                        <span v-if="item.rent_info">
                          {{ item.rent_info.lease_start | formatDate }}
                        </span>
                      </template>
                      <template v-slot:item.rent_info.lease_term="{ item }">
                        <span v-if="item.rent_info">
                          {{ item.rent_info.lease_term / 365 }}年
                        </span>
                      </template>
                      <template v-slot:item.rent_info.payment_cycle="{ item }">
                        <span v-if="item.rent_info">
                          <span v-if="item.rent_info.payment_cycle === 0">
                            月付
                          </span>
                          <span v-else-if="item.rent_info.payment_cycle === 1">
                            季付
                          </span>
                          <span v-else>
                            半年付
                          </span>
                        </span>
                      </template>
                      <template v-slot:item.rent_info.payment_period="{ item }">
                        <span v-if="item.rent_info">
                          {{ item.rent_info.payment_period }}天
                        </span>
                      </template>
                      <template v-slot:item.rent_info.total_period="{ item }">
                        <span v-if="item.rent_info">
                          {{ item.rent_info.total_period }}期
                        </span>
                      </template>
                      <template v-slot:item.rent_info.expiry_date="{ item }">
                        <span v-if="item.rent_info">
                          {{ item.rent_info.expiry_date | formatDate }}
                        </span>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-card>
              <v-navigation-drawer
                v-model="filterdrawer"
                app
                temporary
                right
                height="100%"
                style="z-index: 999"
              >
                <v-card class="mx-auto" flat>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="headline py-2">筛选</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-card-text>
                    <v-sheet min-height="500">
                      <div>
                        <h2 class="subtitle-2">工单状态</h2>
                        <v-chip-group v-if="orderStatus.length" v-model="query.status" column multiple>
                          <v-chip v-for="orders in orderStatus" :key="orders.code" :value="orders.code" filter label outlined>{{orders.name}}</v-chip>
                        </v-chip-group>
                        <h2 class="subtitle-2 mt-2">创建时间</h2>
                        <v-menu ref="menuStartCreateTime" v-model="menuStartCreateTime"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="query.startCreateTime"
                              label="开始时间"
                              class="mt-2 mb-n4"
                              readonly
                              outlined
                              dense
                              clearable
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="query.startCreateTime" no-title scrollable width="225" @input="menuStartCreateTime = false; clearTime(['createTime'])">
                          </v-date-picker>
                        </v-menu>
                        <v-menu ref="menuStartCreateTime" v-model="menuEndCreateTime"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="query.endCreateTime"
                              label="结束时间"
                              class="mt-2 mb-n4"
                              readonly
                              outlined
                              dense
                              clearable
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="query.endCreateTime" no-title scrollable width="225" @input="menuEndCreateTime = false; clearTime(['createTime'])"></v-date-picker>
                        </v-menu>
                        <div v-if="this.query.types.includes('rent')">
                          <h2 class="subtitle-2 my-2">租赁方式</h2>
                          <v-chip-group column multiple @change="doAction('set_filter')" v-model="query.rent_type">
                            <v-chip filter label outlined value="0">融资租赁</v-chip>
                            <v-chip filter label outlined value="1">经营性租赁</v-chip>
                          </v-chip-group>
                          <h2 class="subtitle-2 my-2">租期</h2>
                          <v-chip-group column multiple @change="doAction('set_filter')" v-model="query.lease_term">
                            <v-chip filter label outlined value="365">1年</v-chip>
                            <v-chip filter label outlined value="730">2年</v-chip>
                            <v-chip filter label outlined value="1095">3年</v-chip>
                          </v-chip-group>
                          <h2 class="subtitle-2 my-2">起租日期</h2>
                          <v-menu v-model="menuLeaseStartFrom"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="query.leaseStartFrom"
                                label="开始时间"
                                class="mt-2 mb-n4"
                                readonly
                                outlined
                                dense
                                clearable
                                v-bind="attrs"
                                v-on="on"
                                @change="doAction('set_filter')"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="query.leaseStartFrom" no-title scrollable width="225" @input="doAction('set_filter'); menuLeaseStartFrom = false">
                            </v-date-picker>
                          </v-menu>
                          <v-menu v-model="menuLeaseStartTo"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="query.leaseStartTo"
                                label="结束时间"
                                class="mt-2 mb-n4"
                                readonly
                                outlined
                                dense
                                clearable
                                v-bind="attrs"
                                v-on="on"
                                @change="doAction('set_filter')"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="query.leaseStartTo" no-title scrollable width="225" @input="doAction('set_filter'); menuLeaseStartTo = false"></v-date-picker>
                          </v-menu>
                          <h2 class="subtitle-2 my-2">支付周期</h2>
                          <v-chip-group column multiple v-model="query.payment_cycle" @change="doAction('set_filter')">
                            <v-chip filter label outlined value="0">月付</v-chip>
                            <v-chip filter label outlined value="1">季付</v-chip>
                            <v-chip filter label outlined value="2">半年付</v-chip>
                          </v-chip-group>
                        </div>
                      </div>
                    </v-sheet>
                  </v-card-text>
                </v-card>
                <template v-slot:append>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn text @click="doAction('clear_filter')">清空</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="doAction('set_filter')"
                      >确认</v-btn
                    >
                  </v-card-actions>
                </template>
              </v-navigation-drawer>
            </v-container>
          </div>
        </div>
      </div>
  </v-sheet>
</template>

<script>
import store from "@/store";
import {mapGetters} from "vuex";
import {
  // FETCH_DEALER_ENTERPRISE,
  FETCH_ORDER_PURCHASE_LIST,
} from "@/store/actions.type";
import Util from "@/common/util";
import AccountNav from "@/components/accountnav";
export default {
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
      },
      query: {
        key: "",
        status: [],
        startCreateTime: '',
        endCreateTime: '',
        types: [
          'purchase', 'rent'
        ],
        rent_type: [],
        lease_term: [],
        leaseStartFrom: '',
        leaseStartTo: '',
        payment_cycle: [],
      },
      defaultQuery: {
        key: "",
        status: [],
        startCreateTime: '',
        endCreateTime: '',
        types: [
          'purchase', 'rent'
        ],
        rent_type: [],
        lease_term: [],
        leaseStartFrom: '',
        leaseStartTo: '',
        payment_cycle: [],
      },
      optionsItem: {},
      active_tab: 0,
      defaultHeader: [
        { text: '订单编号', value: 'order_number', width: '100px'},
        { text: 'PO号', value: 'po_number', width: '100px'},
        { text: 'DT号', value: 'out_number', width: '100px'},
        { text: '产品型号', value: 'product_part_list', width: '100px', sortable: false},
        { text: '产品名称', value: 'product_name_list', width: '100px', sortable: false},
        { text: '订单数量', value: 'product_quantity_list', width: '120px', align: " d-none", sortable: false},
        { text: '已出库数量', value: 'product_asset_list', width: '120px', align: " d-none", sortable: false},
        { text: '待出库数量', value: 'product_await_list', width: '120px', align: " d-none", sortable: false},
        { text: '已开单数量', value: 'product_cases_list', width: '120px', align: " d-none", sortable: false},
        { text: '已取消数量', value: 'product_cancel_list', width: '120px', align: " d-none", sortable: false},
        { text: '订单合计金额', value: 'total_price', width: '150px'},
        { text: '订单总数量', value: 'total_quantity', width: '120px'},
        { text: '产品种类', value: 'count_product', align: " d-none", width: '120px'},
        { text: '分支机构', value: 'branch', align: " d-none", width: '150px'},
        { text: '销售', value: 'sales', width: '120px'},
        { text: '客户付款日', value: 'rent_info.payment_day', align: " d-none", width: '120px'},
        { text: '租赁方式', value: 'rent_info.rent_type', width: '120px'},
        { text: '起租日期', value: 'rent_info.lease_start', align: " d-none", width: '120px'},
        { text: '租期', value: 'rent_info.lease_term', align: " d-none", width: '120px'},
        { text: '支付周期', value: 'rent_info.payment_cycle', align: " d-none", width: '120px'},
        { text: '账期', value: 'rent_info.payment_period', align: " d-none", width: '80px'},
        { text: '期数', value: 'rent_info.total_period', align: " d-none", width: '120px'},
        { text: '到期时间', value: 'rent_info.expiry_date', align: " d-none", width: '120px'},
        { text: '最后更新人', value: 'editor', align: " d-none", width: '120px'},
        { text: '订单创建人', value: 'creator', width: '120px'},
        { text: '创建时间', value: 'createTime', align: " d-none", width: '100px'},
        { text: '更新时间', value: 'updateTime', width: '100px'},
        { text: '状态', value: 'status', width: '100px'},
      ],
      filterdrawer: false,
      menuStartCreateTime: false,
      menuEndCreateTime: false,
      headerItem: [],
      menuLeaseStartFrom: false,
      menuLeaseStartTo: false,
    }
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "orderPurchaseList",
      "orderPurchaseCount",
      "orderPurchaseLoading",
    ]),
    orderStatus () {
      return Util.categories('orderStatus')
    },
  },
  components: {
    AccountNav,
  },
  watch: {
    optionsItem: {
      handler() {
        this.getList(this.query);
      },
      deep: true,
    },
  },
  created() {
    this.getHeader('orderHeader', 2)
  },
  methods: {
    getList(query = {}) {
      // 获取分页信息
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItem;
      let sort = "";
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = "-" + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query)
        if (this.query.status && !Array.isArray(this.query.status)) this.query.status = this.query.status.split(',')
        if (this.query.types && !Array.isArray(this.query.types)) this.query.types = this.query.types.split(',')
        if (this.query.rent_type && !Array.isArray(this.query.rent_type)) this.query.rent_type = this.query.rent_type.split(',')
        if (this.query.lease_term && !Array.isArray(this.query.lease_term)) this.query.lease_term = this.query.lease_term.split(',')
        if (this.query.payment_cycle && !Array.isArray(this.query.payment_cycle)) this.query.payment_cycle = this.query.payment_cycle.split(',')
      }

      let urlQuery = "";
      if (query.key) urlQuery += "&key=" + query.key
      if (this.query.status.length) urlQuery += '&status='+ query.status
      if (this.query.startCreateTime) urlQuery += '&startTime='+ query.startCreateTime
      if (this.query.endCreateTime) urlQuery += '&endTime='+ query.endCreateTime
      if (this.query.types) urlQuery += '&types='+this.query.types

      if (this.query.types.includes('rent')) {
        if (this.query.lease_term) urlQuery += '&lease_term='+ query.lease_term
        if (this.query.rent_type) urlQuery += '&rent_type='+ query.rent_type
        if (this.query.payment_cycle) urlQuery += '&payment_cycle='+ query.payment_cycle
        if (this.query.leaseStartFrom) urlQuery += '&lease_start_begin='+ query.leaseStartFrom
        if (this.query.leaseStartTo) urlQuery += '&lease_start_finish='+ query.leaseStartTo
      }

      if (Object.keys(this.optionsItem).length > 0) {
        store.dispatch(FETCH_ORDER_PURCHASE_LIST, {
          query:
            "page=" +
            page +
            "&limit=" +
            itemsPerPage +
            "&sort=" +
            sort +
            urlQuery,
        })
      }
    },
    todoList(query = 'all') {
      this.query = Object.assign({}, this.defaultQuery)
      if (query === 'purchase') this.query.types = ['purchase']
      if (query === 'rental') this.query.types = ['rent']
      if (query === 'follow') this.query.follow = this.currentUser._id
      this.getList();
    },
    doAction(action) {
      switch (action) {
        case "search_item": {
          this.getList(this.query);
          break;
        }
        case "set_filter": {
          this.$router.push({ query: {} });
          this.getList(this.query);
          this.filterDrawer = !this.filterDrawer;
          break;
        }
        case "clear_filter": {
          this.query = Object.assign({}, this.defaultQuery);
          this.$router.push({ query: {} });
          this.getList();
          this.filterDrawer = !this.filterDrawer;
          break;
        }
        case 'cancel_header': {
          this.getHeader('orderHeader',2)
          this.$forceUpdate()
          break
        }
        case 'default_header': {

          break
        }
        case 'set_header': {
          this.getHeader('orderHeader',2)
          this.$forceUpdate()
          break
        }
        case 'reload_item': {
          this.optionsItem.page = 1
          this.getList()
          break
        }
        default: {

          break
        }
      }
    },
    getHeader(model, version) {
      let userHeader = this.currentUser[model];
      if (userHeader.content.length) {
        if (userHeader.version < version) {
          this.headerItem = JSON.parse(JSON.stringify(this.defaultHeader))
        } else {
          this.headerItem = JSON.parse(JSON.stringify(userHeader.content))
        }
      } else {
        this.headerItem = JSON.parse(JSON.stringify(this.defaultHeader))
      }
    },
    clearTime(arrModel = []) {
      for (const model of arrModel) {
        this.query[model] = ''
      }
    },
    updateList() {
      this.getList(this.query)
    },
    openCase(key) {
      let returnValue = false
      if (Object.keys(this.$route.query).length > 0) {
        const objQuery = Object.assign(this.query, this.$route.query);
        if (objQuery.key === key) returnValue = true
      }
      return returnValue
    },
  },
};
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.9;
  position: absolute;
  width: 100%;
}
.v-expansion-panel-content .v-expansion-panel-content__wrap {
  padding: 0 0 0 0 !important;
}
.addIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
</style>
