<template>
  <v-sheet class="mainSheet">
    <v-container>
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-toolbar class="secondary" dark flat>
            <v-btn icon color="white" @click="goBack()">
              <v-icon>arrow_back</v-icon>
            </v-btn>
            <v-toolbar-title>{{ enterprise.name }} - 咨询工单</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn text @click="submit('')" :disabled="!valid">创建</v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  autocomplete="off"
                  v-model="editedItem.group"
                  :items="groupList"
                  @change="setCatalogList(editedItem.group)"
                  :rules="[rules.selected]"
                  label="支持分类"
                  hint="请选择一个支持分类"
                  item-text="name"
                  item-value="_id"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  autocomplete="off"
                  v-model="editedItem.catalog"
                  :items="catalogList"
                  label="支持目录"
                  hint="请选择一个支持目录"
                  item-text="name"
                  item-value="_id"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.subject"
                  :rules="[rules.required]"
                  type="text"
                  label="标题"
                  hint="请输入咨询的主题"
                  outlined
                  dense
                ></v-text-field>
                <v-textarea
                  v-model="editedItem.context"
                  :rules="[rules.required]"
                  outlined
                  label="详细内容"
                  hint="请输入咨询的详细内容"
                ></v-textarea>
                <case-create-upload-files
                  ref="upload_files"
                  :enterprise="enterprise._id"
                ></case-create-upload-files>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="submit('')" :disabled="!valid"
              >提交</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
      <v-dialog v-model="dlgSubmit" persistent scrollable max-width="500px">
        <v-card>
          <v-card-title class="headline">
            确认提交新的咨询请求
            <v-spacer></v-spacer>
            <v-btn icon @click="dlgSubmit = false"
              ><v-icon>close</v-icon></v-btn
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12"> 咨询请求提交后将通知经销商进行受理。 </v-col>
              </v-row>
              <v-row v-if="createResult.length">
                <v-col cols="12">
                  <v-alert outlined type="success" text>
                    <v-row align="center" no-gutters>
                      <v-col cols="12">
                        成功创建：{{
                          createResult.length
                        }}
                        个咨询请求；咨询请求编号为：
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grow">
                        <p
                          class="font-weight-black"
                          v-for="code in createResult"
                          :key="code"
                        >
                          {{ code }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions v-if="!createResult.length">
            <v-btn text @click="dlgSubmit = false">取消</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="secondary"
              :loading="!submitEnd"
              @click="submit('all')"
              ><v-icon left>note_add</v-icon>提交</v-btn
            >
          </v-card-actions>
          <v-card-actions v-else>
            <v-spacer></v-spacer>
            <v-btn text @click="goBack">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-sheet>
</template>
<script>
import store from "@/store";
import {
  FETCH_ENTERPRISE,
  FETCH_KNOWLEDGE_CATALOG_LIST,
  PUBLISH_CASE,
} from "@/store/actions.type";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";

export default {
  data() {
    return {
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        phonenum: (value) => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || "请输入正确的电话号码";
          } else {
            return true;
          }
        },
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        email: (value) => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "错误的电子邮件地址";
          } else {
            return true;
          }
        },
      },
      valid: true,
      query: {
        enterprise: "",
        group: "",
        catalog: "",
      },
      defaultQuery: {
        enterprise: "",
        group: "",
        catalog: "",
      },
      groupList: [],
      catalogList: [],
      editedItem: {
        group: "",
        catalog: "",
        subject: "",
        context: "",
        files: [],
        link_case_number: "",
      },
      dlgSubmit: false,
      createResult: [],
      submitEnd: true,
    };
  },
  created() {},
  mounted() {
    if (Object.keys(this.$route.query).length > 0)
      this.query = Object.assign(this.query, this.$route.query);
    this.initiateCase();
  },
  computed: {
    ...mapGetters(["enterprise"]),
  },
  watch: {},
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    initiateCase() {
      if (!Object.keys(this.$route.query).length) {
        store.commit(SET_ERROR, { msg: "参数错误" });
        this.goBack();
      } else {
        Promise.all([
          store.dispatch(FETCH_KNOWLEDGE_CATALOG_LIST, "&list=group"),
          store.dispatch(FETCH_ENTERPRISE, this.query.enterprise),
        ])
          .then((data) => {
            this.groupList = data[0].catalog;
            if (this.query.group) this.editedItem.group = this.query.group;
            if (this.editedItem.group) {
              store
                .dispatch(
                  FETCH_KNOWLEDGE_CATALOG_LIST,
                  "&group=" + this.editedItem.group
                )
                .then((catalogs) => {
                  this.catalogList = catalogs.catalog;
                  if (this.query.catalog)
                    this.editedItem.catalog = this.query.catalog;
                });
            }
          })
          .catch((err) => {
            console.log("init case error =>" + err);
          });
      }
    },
    setCatalogList(groupId) {
      if (groupId) {
        store
          .dispatch(FETCH_KNOWLEDGE_CATALOG_LIST, "&group=" + groupId)
          .then((data) => {
            this.catalogList = data.catalog;
          });
      } else {
        this.catalogList = [];
      }
    },
    submit(byType) {
      if (!byType) {
        this.dlgSubmit = true;
      } else {
        let strInsert = {
          byType: byType,
          enterprise: this.query.enterprise,
          link_case_number: this.editedItem.link_case_number,
          group: this.editedItem.group,
          catalog: this.editedItem.catalog,
          subject: this.editedItem.subject,
          context: this.editedItem.context,
          files: this.$refs.upload_files
            ? this.$refs.upload_files.filesList
            : [],
        };
        store
          .dispatch(PUBLISH_CASE, { form: "consult", data: strInsert })
          .then(({ caseList }) => {
            store.commit(SET_ERROR, { msg: "创建成功", color: "primary" });
            this.createResult = caseList.map((item) => item.case_number);
          })
          .catch((error) => {
            store.commit(SET_ERROR, { msg: error.response.data.message });
          });
      }
    },
  },
};
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
