<template>
  <v-sheet color="#f4f4f4" class="mainSheet">
    <div class="account-con">
      <div class="con-left">
        <account-nav :index="2"></account-nav>
      </div>
      <div class="con-right">
        <v-container>
          <v-card>
            <v-toolbar class="secondary" dark>
              <v-btn icon @click="goBack()">
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-toolbar-title>问题咨询服务</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="query.key"
                @keydown.enter="doAction('search_item')"
                class="mx-4"
                flat
                dense
                hide-details
                label="搜索服务编号、工单编号、咨询主题和内容..."
                prepend-inner-icon="search"
                solo-inverted
              ></v-text-field>
            </v-toolbar>

            <v-data-table
              v-model="selectedItem"
              :headers="headerItem"
              :items="caseList"
              :options.sync="optionsItem"
              :server-items-length="caseCount"
              :loading="caseLoading"
              loading-text="数据读取中... 请稍后"
              :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
              item-key="_id"
              return-object="false"
              transition
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <!-- <v-toolbar-title v-if="dealer_enterprise.length"><v-icon class="mr-4">assignment</v-icon>{{ query.enterprise ? dealer_enterprise.find(item => item._id === query.enterprise).name : '全部企业咨询'}}</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider> -->
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" depressed @click.stop="doAction('select_catalog', query.enterprise, '选择企业和咨询类别')">
                    <v-icon>add</v-icon>
                    新建咨询
                  </v-btn>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" class="ma-2" outlined fab x-small @click.stop="doAction('select_catalog', query.enterprise, '选择企业和咨询类别')">
                        <v-icon>add</v-icon>
                      </v-btn>
                    </template>
                    <span>通过表单增加请求</span>
                  </v-tooltip> -->
                  <widgets-custom-header :defaultHeader="defaultHeader" :headerList="headerItem" :version=0 tableName="consultHeader" v-on:Cancel="doAction('cancel_header')" v-on:Default="doAction('default_header')" v-on:Save="doAction('set_header')"></widgets-custom-header>
                </v-toolbar>
              </template>
              <template v-slot:item.case_number="{ item }">
                <td nowrap="true">
                  <v-badge color="secondary" dot v-if="item.isEdit && !item.isSelf">
                    <widgets-case-consult :getId="item._id" v-on:Close="closeCase" :key="item._id">
                      {{item.case_number}}
                    </widgets-case-consult>
                  </v-badge>
                  <widgets-case-consult v-else :getId="item._id" v-on:Close="closeCase" :key="item._id">
                    {{item.case_number}}
                    <v-icon v-if="item.isSelf && item.status < 2" right small>hourglass_top</v-icon>
                  </widgets-case-consult>
                </td>
              </template>
              <template v-slot:item.link_case_number="{ item }">
                <td nowrap="true" width="175px">{{item.link_case_number}}</td>
              </template>
              <template v-slot:item.group="{ item }">
                <td nowrap="true" width="125px">{{item.group ? item.group.name : ''}}</td>
              </template>
              <template v-slot:item.catalog="{ item }">
                <td nowrap="true" width="125px">{{item.catalog ? item.catalog.name : ''}}</td>
              </template>
              <template v-slot:item.subject="{ item }">
                <td nowrap="true" class="d-inline-block text-truncate" width="175px">{{item.subject}}</td>
              </template>
              <!-- <template v-slot:item.enterprise="{ item }">
                <td nowrap="true" v-if="item.enterprise" @click="openEnterprise(item.enterprise)" class="v-list-item--link">{{item.enterprise.name}}</td>
              </template> -->
              <template v-slot:item.creator="{ item }">
                <td nowrap="true" width="80px" v-if="item.creator"><widgets-profile-dialogs :account="item.creator._id" class="v-list-item--link">{{item.creator.personal ? item.creator.personal.name : '*未设置姓名'}}</widgets-profile-dialogs></td>
                <td nowrap="true" width="80px" v-else>-</td>
              </template>
              <template v-slot:item.editor="{ item }">
                <td nowrap="true" width="80px" v-if="item.editor"><widgets-profile-dialogs :account="item.editor._id" class="v-list-item--link">{{item.editor.personal ? item.editor.personal.name : '*未设置姓名'}}</widgets-profile-dialogs></td>
                <td nowrap="true" width="80px" v-else>-</td>
              </template>
              <template v-slot:item.status="{ item }">
                <td nowrap="true" width="75px">
                  <v-chip label small v-if="item.status === 0" color="secondary">待受理</v-chip>
                  <v-chip label small v-else-if="item.status === 1" color="primary">已回复</v-chip>
                  <v-chip label small v-else>已解决</v-chip>
                </td>
              </template>
              <template v-slot:item.createTime="{ item }">
                <td nowrap="true">{{item.createTime | formatTime}}</td>
              </template>
              <template v-slot:item.updateTime="{ item }">
                <td nowrap="true" width="175px">{{item.updateTime | formatTime}}</td>
              </template>
            </v-data-table>
          </v-card>
          <v-dialog v-model="dlgCatalog" persistent max-width="600px">
            <v-form v-model="valid" @submit.prevent="submit('catalog')">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ dlgTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-autocomplete
                    autocomplete="off"
                    v-model="editedCase.group"
                    :items="groupList"
                    @change="doAction('set_catalog_list')"
                    :rules="[rules.selected]"
                    label="支持分类"
                    hint="请选择一个支持分类"
                    item-text="name"
                    item-value="_id"
                    outlined
                    dense
                  ></v-autocomplete>
                  <v-autocomplete
                    autocomplete="off"
                    v-model="editedCase.catalog"
                    :items="catalogList"
                    label="支持目录"
                    hint="请选择一个支持目录"
                    item-text="name"
                    item-value="_id"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="dlgCatalog = false">取消</v-btn>
                  <v-btn color="secondary" text @click="submit('catalog')" :disabled="!valid">确定</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
          <v-dialog v-model="dlgInfo" persistent scrollable max-width="600px">
            <widgets-enterprise-dialogs v-if="activeType === 'enterprise'" :id="activeId" :key="activeId" v-on:Close="closeEnterprise()"></widgets-enterprise-dialogs>
          </v-dialog>
        </v-container>
      </div>
    </div>
  </v-sheet>
  
</template>
<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {
  FETCH_CASE_LIST,
  FETCH_EMPLOYEE_LIST, FETCH_KNOWLEDGE_CATALOG_LIST,
} from "@/store/actions.type";
import AccountNav from "@/components/accountnav";
export default {
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      query: {
        key: '',
        link_case_number: '', //关联工单
        enterprise: '', // 企业
        group: '', // 知识分类
        catalog: [], // 知识目录
        creator: '', // 请求人
        status: [], // 工单状态
        updateTime: '', // 设备更新时间
        createTime: '', // 设备更新时间
      },
      defaultQuery: {
        key: '',
        link_case_number: '', //关联工单
        enterprise: '', // 企业
        group: '', // 知识分类
        catalog: [], // 知识目录
        creator: '', // 请求人
        status: [], // 工单状态
        updateTime: '', // 设备更新时间
        createTime: '', // 设备更新时间
      },
      selectedItem: [],
      headerItem: [],
      defaultHeader: [
        { text: '工单编号', value: 'case_number' },
        // { text: '关联工单', value: 'link_case_number', align: " d-none" },
        { text: '分类', value: 'group' },
        { text: '目录', value: 'catalog' },
        { text: '主题', value: 'subject' },
        // { text: '所属企业', value: 'enterprise' },
        { text: '请求人', value: 'creator' },
        { text: '处理人', value: 'editor', align: " d-none" },
        { text: '工单状态', value: 'status' },
        { text: '创建时间', value: 'createTime', align: " d-none" },
        { text: '更新时间', value: 'updateTime', align: " d-none" },
      ],
      optionsItem: {},
      filterDrawer: null,
      dlgHeader: false,
      dlgCatalog: false,
      dlgTitle: '',
      valid: true,
      editedCase: {
        enterprise: '',
        group: '',
        catalog: '',
      },
      defaultCase: {
        enterprise: '',
        group: '',
        catalog: '',
      },
      groupList: [],
      catalogList: [],
      dlgInfo: false,
      activeType: '',
      activeId: null,
    }
  },
//   beforeRouteEnter(to, from, next) {
//     Promise.all([
//       store.dispatch(FETCH_DEALER_ENTERPRISE),
//     ]).then(() => {
//       next();
//     });
//   },
  components: {
    AccountNav,
  },
  created() {
    this.getList()
    this.getHeader('consultHeader', 0)
  },
  mounted() {
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "dealer_enterprise",
      "caseLoading",
      "caseList",
      "caseCount",
    ]),
  },
  watch: {
    optionsItem: {
      handler() {
        this.getList(this.query)
      },
      deep: true,
    },
  },
  methods: {
    goBack() {
      this.$router.push({path: '/workbench'});
    },
    getList(query = {}) {
      // 获取分页信息
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItem;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true){
          sort = '-'+ sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0){
        this.query = Object.assign(this.query, this.$route.query);
        if (this.query.status && !Array.isArray(this.query.status)) this.query.status = this.query.status.split(',')
        if (this.query.catalog && !Array.isArray(this.query.catalog)) this.query.catalog = this.query.catalog.split(',')
        if (this.query.enterprise) {
          store.dispatch(FETCH_EMPLOYEE_LIST, '&enterprise='+ this.query.enterprise)
        }
      }

      let urlQuery = '';
      urlQuery = '&catalog_type=consulting_service'
      if (query.key) urlQuery += '&key='+ query.key
      if (query.group) urlQuery += '&group='+ query.group
      if (query.enterprise) urlQuery += '&enterprise='+ query.enterprise
      if (this.query.status.length) urlQuery += '&status='+ query.status
      if (this.query.catalog.length) urlQuery += '&catalog='+ query.catalog
      if (this.query.creator) urlQuery += '&creator='+ query.creator
      if (this.query.link_case_number) urlQuery += '&link_case_number='+ query.link_case_number
      if (this.query.updateTime) urlQuery += '&updateTime='+ query.updateTime
      if (this.query.createTime) urlQuery += '&createTime='+ query.createTime

      if (Object.keys(this.optionsItem).length > 0) {
        store.dispatch(FETCH_CASE_LIST, {form: 'consult', query: 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery})
      }
    },
    getHeader(model, version) {
      let userHeader = this.currentUser[model];
      if (userHeader && userHeader.content && userHeader.content.length) {
        if (userHeader.version < version) {
          this.headerItem = JSON.parse(JSON.stringify(this.defaultHeader))
        } else {
          this.headerItem = JSON.parse(JSON.stringify(userHeader.content))
        }
      } else {
        this.headerItem = JSON.parse(JSON.stringify(this.defaultHeader))
      }
    },
    doAction(action, item = {}, additional) {
      console.log(action +' =>'+ JSON.stringify(item))
      switch (action) {
        case 'select_catalog': {
          store.dispatch(FETCH_KNOWLEDGE_CATALOG_LIST, '&list=group').then((data) => {
            this.groupList = data.catalog
            this.dlgCatalog = true
            this.dlgTitle = additional
          })
          break
        }
        case 'set_catalog_list': {
          if (this.editedCase.group) {
            store.dispatch(FETCH_KNOWLEDGE_CATALOG_LIST, '&group='+ this.editedCase.group).then((data) => {
              this.catalogList = data.catalog
            })
          } else {
            this.catalogList = []
          }
          break
        }
        case 'cancel_header': {
          this.getHeader('consultHeader')
          this.$forceUpdate()
          break
        }
        case 'default_header': {

          break
        }
        case 'set_header': {
          this.getHeader('consultHeader')
          this.$forceUpdate()
          break
        }
        case 'search_item': {
          this.getList(this.query)
          break
        }
        default: {

          break
        }
      }
    },
    openEnterprise(item) {
      this.activeType = 'enterprise'
      this.activeId = item._id
      this.dlgInfo = true
    },
    closeEnterprise() {
      this.dlgInfo = false
    },
    closeCase() {
      this.getList(this.query);
    },
    submit(subject) {
      if (subject === 'catalog') this.$router.push({path:'/workbench/advisory/edit?enterprise='+ this.currentUser.employer.ownerId._id +'&group='+ this.editedCase.group +'&catalog='+ this.editedCase.catalog})
    }
  },
}
</script>

<style scoped>
.nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
