<template>
  <div>
    <div class="sec-bar">
      <v-app-bar
        elevation="0"
        max-width="1600px"
        color="#a1a1a1"
        dense
        style="margin: 0 auto;"
        dark
      >
        <v-btn text color="secondary">
          <v-icon>home</v-icon>
          <span class="btn-text">客户支持</span>
        </v-btn>
        <div class="flex-grow-1"></div>
        <!-- <v-btn text color="secondary" @click="$router.push('/consult')">
          <v-icon>assignment_late</v-icon>
          <span class="btn-text">提交咨询请求</span>
        </v-btn> -->
        <!-- <v-btn text color="secondary">
          <v-icon>message</v-icon>
          <span class="btn-text">联系客服</span>
        </v-btn> -->
        <v-btn text color="secondary">
          <v-icon>lock</v-icon>
          <span class="btn-text">安全中心</span>
        </v-btn>
        <v-btn text color="secondary">
          <v-icon>event</v-icon>
          <span class="btn-text">进度查询</span>
        </v-btn>
      </v-app-bar>
    </div>
  <div class="apply-con">
    
    <v-row>
        <v-col lg="8" md="8" sm="12">
          <v-carousel
            v-model="mySlide"
            hide-delimiter-background
            show-arrows-on-hover
            vertical
            vertical-delimiters
            height="700px"
            light
          >
            <v-carousel-item
              v-for="(slide, i) in slides"
              :key="i"
            >
              <v-sheet
                color="#fff"
                height="100%"
              >
                <v-row
                  class="fill-height"
                  style="position: relative;"
                >
                  <div class="citem">
                    <h4 class="it">选择您需要的设备类型</h4>
                    <v-select
                      :items="items"
                      label="设备类型"
                      outlined
                      menu-props="offset-y"
                      @change="changePage"
                      solo
                      color="secondary"
                    ></v-select>
                  </div>
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col lg="4" md="4" sm="12">
          <div>
            <v-card
              class="mx-auto"
              min-height="700"
            >
              <v-img
                class="white--text align-end"
                height="400px"
                src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
              >
                <v-card-title>预留功能区域</v-card-title>
              </v-img>

              <v-card-subtitle class="pb-0">Number 10</v-card-subtitle>

              <v-card-text class="text--primary mt-12">
                <div>Whitehaven Beach</div>

                <div>Whitsunday Island, Whitsunday Islands</div>
              </v-card-text>

              <v-card-actions class="mt-12">
                <v-btn
                  color="orange"
                  text
                >
                  Share
                </v-btn>

                <v-btn
                  color="orange"
                  text
                >
                  Explore
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
  </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    mySlide: 0,
    colors: [
      'indigo',
      'warning',
      'pink darken-2',
      'red lighten-1',
      'deep-purple accent-4',
    ],
    slides: [
      'First',
      'Second',
      'Third',
      'Fourth',
      'Fifth',
    ],
    items: ['手机', '数码', '电脑', '家电'],
  }),
  methods: {
    changePage() {
      setTimeout(() => {
        this.mySlide++
      }, 1000)
    },
  }
}
</script>

<style scoped>
.sec-bar {
  background-color: #a1a1a1;
}
.apply-con {
  min-height: 500px;;
  max-width: 1600px;
  margin: 0 auto;
  padding: 30px 0;
}
.citem {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  min-width: 500px;
}
.it {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.525rem;
  font-weight: 400;
}
.btn-text {
  color: #fff; 
  margin-left: 4px; 
  font-weight: 400;
}
</style>